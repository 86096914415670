html
  //height: 100%
#__next
  //min-height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
body
  //height: 100%
  padding: 0
  margin: 0
  font-family: $main-font
  color: $dark
  img
    max-width: 100%
    display: block
  *
    box-sizing: border-box
  a
    font-family: $main-font
    text-decoration: none
  p
    font-size: 20px
    line-height: 1.5
    color: $dark
    margin-top: 0
    font-family: $main-font
    @media (max-width: 480px)
      font-size: 16px
    &:last-child
      margin-bottom: 0
  ol,
  ul
    padding-left: 15px
    margin-top: 10px
    margin-bottom: 10px
    &:last-child
      margin-bottom: 0
    li
      margin-bottom: 10px
      font-size: 20px
      line-height: 1.5
      color: $dark
      font-family: $main-font
      @media (max-width: 480px)
        font-size: 16px
  div
    outline: none !important
    &:focus-visible,
    &:focus
      outline: none !important
  strong
    font-weight: 700
  button
    border: none
    background: none
    padding: 0
    box-shadow: none
    outline: none
    cursor: pointer
    font-family: $main-font
  .flex
    display: flex
  .ai-c
    align-items: center
  .ai-fe
    align-items: flex-end
  .jc-sb
    justify-content: space-between
  .jc-c
    justify-content: center
  .jc-fe
    justify-content: flex-end
  .fd-c
    flex-direction: column
  .fw-wrap
    flex-wrap: wrap
  input,
  textarea
    font-family: $main-font
    outline: none !important
  h1,h2,h3,h4,h5,h6
    margin-top: 0
  label
    cursor: pointer