.add-to-cart-page
  .step-item_wrapper
    @media (max-width: 767px)
      padding: 110px 23px 17px !important
  .product-id-block
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 50px
    margin-top: 20px
    border-radius: 10px
    border: 1px solid #AFAFAF
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(180deg, #3A3939 0%, #202020 100%)
    backdrop-filter: blur(12px)
    padding: 13px 17px
    @media (max-width: 767px)
      width: 100%
      margin-top: 50px
      flex-direction: column
      align-items: center
      justify-content: center
    .pib-value
      color: #383838
      font-size: 24px
      font-weight: 500
      line-height: 1
      @media (max-width: 767px)
        font-size: 20px
        text-overflow: ellipsis
        overflow: hidden
        display: block
        white-space: nowrap
        max-width: 100%
        direction: ltr
        margin-bottom: 16px
    .pib-copy
      margin-right: 20px
      .copy-btn
        min-width: 145px
        display: flex
        align-items: center
        justify-content: center
        padding: 8px 10px 7px
        border-radius: 10px
        border: 1px solid #AFAFAF
        background: linear-gradient(0deg, #E7D0B1 0%, #E7D0B1 100%), linear-gradient(180deg, #3A3939 0%, #202020 100%)
        color: $black
        font-size: 18px
        @media (max-width: 767px)
          font-size: 16px
          min-width: 110px
          padding-top: 6px
          padding-bottom: 5px
        img
          margin-left: 6px
          @media (max-width: 767px)
            max-width: 20px
  .share-block
    margin-bottom: 60px
    display: flex
    align-items: center
    .share-title
      margin-left: 38px
      color: #FFF
      font-size: 18px
    .share-items
      display: flex
      .share-item
        margin-left: 15px
        .share-item_link
          display: block
        &:last-child
          margin-left: 0

