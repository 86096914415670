body
  .print-wrapper
    display: none
  .sticker-wrapper
    .blend-wrapper
      .flavors
        .flavors-wrapper
          .flavor-value_item
            font-weight: 700
    @media (min-width: 480px) and (max-width: 767px)
      &.sticker-print
        //max-width: 1132px
        max-width: 566px
        width: 100%
        //height: 390px
        //overflow: hidden
        .steps-wrapper
          .step-item_wrapper
            &.editor-wrapper
              .blend-wrapper
                display: flex
                padding-bottom: 10px
                .blend-wrapper_left
                  .tastes
                    .notes-wrapper
                      &.small
                        padding-top: 10px
                        padding-bottom: 10px
        .page-content
          .page-header
            padding-top: 5px
            padding-bottom: 0
            img
              max-height: 32px
        .blend-wrapper
          padding-right: 10px !important
          padding-left: 10px !important
          padding-bottom: 10px
          min-height: 10px
          align-items: flex-start
          //display: block
          .blend-sidebar_title
            margin-bottom: 7px !important
            .title
              font-size: 8px !important
              max-width: 60px !important
              flex: 0 0 60px !important
              white-space: nowrap
          .blend-wrapper_left
            width: 100%
            max-width: 210px
            flex: 0 0 210px
            padding: 8px 14px 10px !important
            border-radius: 5px
            margin-bottom: 0 !important
            //float: right
          .coffee-type
            margin-bottom: 10px !important
            .coffee-type_icon
              max-width: 45px
              flex: 0 0 45px
              padding-left: 5px
              padding-right: 5px
              border-radius: 5px
              .icon
                margin-bottom: 3px
                max-width: 24px
              .name
                font-size: 7px
                line-height: 1
            .coffee-type_info
              .title
                font-size: 12px
              .subtitle
                font-size: 8px
          .flavors
            .flavors-wrapper
              .flavors-values
                max-width: 45px
                flex: 0 0 45px
                padding: 10px 8px
              .flavor-value_item
                font-size: 6px !important
                .value
                  font-size: 7px !important
              .flavors-chart
                max-width: 144px !important
                flex: 0 0 144px !important
                padding-left: 0 !important
                padding-right: 0 !important
                canvas
                  top: 50% !important
                  right: 0 !important
                  width: 144px !important
                  height: 144px !important
        .notes-wrapper
          &.small
            .notes-wrapper_inner
              width: 68px
              height: 68px
              .notes-item
                padding: 2px
                .notes-item_label
                  .label-value
                    width: 30px
                    height: 30px
                    &.bottom-left
                      .label-title
                        padding-left: 12px
                        padding-top: 8px
                    &.top-right
                      .label-icon
                        top: 4px
                      .label-title
                        padding-left: 15px
                    &.bottom-right
                      .label-title
                        padding-left: 0
                        padding-top: 9px
                    .label-icon
                      width: 6px
                      height: 6px
                      left: 4px
                      bottom: 4px
                      img
                        max-width: 4px
                    .label-title
                      padding-top: 5px
                      font-size: 4px
            .float-flavors
              &.dynamic
                position: absolute
                left: 0
                top: 0
                width: 100%
                margin-top: 0
                .label-flavors
                  min-height: 45px
                  flex: 0 0 53px
                  max-width: 53px
                  position: absolute !important
                  left: 0 !important
                  top: 0 !important
                  margin: 0
                  &.sweet_and_floral,
                  &.chocolaty_and_nuts
                    left: auto !important
                    right: 0 !important
                  &.chocolaty_and_nuts,
                  &.fruity_and_citrus
                    top: 50px !important
                  .label-flavor_item
                    .icon
                      max-width: 8px
                      flex: 0 0 8px
                      img
                        max-width: 8px
                        max-height: 8px
              .label-flavors
                border-radius: 4px
                min-width: 53px
                padding-bottom: 3px
                display: block
                &.empty
                  display: flex
                  img
                    max-width: 20px
                .flavours-title
                  font-size: 6px
                  line-height: 12px
                  margin-bottom: 0
                .label-flavor_item
                  padding-left: 4px
                  padding-right: 4px
                  .icon
                    max-width: 8px
                    flex: 0 0 8px
                  .title
                    padding-right: 4px
                    font-size: 6px
                    line-height: 12px
        .sticker-title
          margin-bottom: 5px
          font-size: 18px
        .editor-wrapper
          .blend-wrapper_right
            padding-right: 10px
            padding-left: 0 !important
            width: 100%
            max-width: 336px
            flex: 0 0 336px
            flex-grow: unset
            //float: left
          .editor-top
            margin-bottom: 7px
            padding: 5px 10px 5px 25px !important
            border-radius: 4px
            .percentage
              flex-direction: row
              align-items: center
              .title
                margin-bottom: 0
                font-size: 8px
                max-width: 80px
                flex: 0 0 80px
              strong
                margin-left: -20px
                font-size: 6px
                letter-spacing: 0
              .percentage-wrapper
                height: 10px
                border-radius: 0 3px 3px 0
          //.editor-blocks_wrapper
            display: none
        .editor-blocks
          margin-left: -5px
          margin-right: -5px
          margin-bottom: 7px
          &:last-child
            margin-bottom: 0
          .editor-block_item
            padding-left: 5px
            padding-right: 5px
            max-width: 94px
            flex: 0 0 94px
            min-height: 125px
            margin-bottom: 0
            &:nth-child(2n)
              max-width: 242px
              flex: 0 0 242px
              flex-grow: unset
            .editor-item_inner
              padding: 5px 10px
              border-radius: 4px
              .editor-title
                margin-bottom: 4px
                font-size: 10px
                white-space: nowrap
          .taste-profile
            max-width: 144px
            position: absolute
            top: -10px
            left: 50%
            transform: translateX(-50%)
            canvas
              width: 144px !important
              height: 144px !important
          .notes-wrapper
            &.small
              padding-top: 15px
        .coffee-items
          .coffee-item
            margin-bottom: 6px
            font-size: 8px
            white-space: nowrap
            .value
              margin-right: 2px
        .roast-item
          .roasting-level
            padding-bottom: 0
            justify-content: flex-start
            .slider-wrapper
              .range-slider
                height: 80px
                width: 26px
                border-radius: 4px
                .range-slider__range
                  border-radius: 4px 4px 0 0
                .range-slider__thumb
                  width: 32px
                  height: 3px
                  &:after
                    font-size: 8px
                    left: 38px
                    top: -5px
    @media (max-width: 480px)
      &.sticker-print
        .steps-wrapper
          .step-item_wrapper
            &.editor-wrapper
              .blend-wrapper
                display: block
        .blend-wrapper
          .blend-wrapper_left
            margin-bottom: 10px !important
            max-width: 100%
            .coffee-type
              margin-bottom: 10px !important
          .flavors
            .flavors-wrapper
              .flavors-chart
                padding-left: 0 !important
                padding-right: 0 !important
                max-width: 230px !important
                canvas
                  transform: translateY(-3%)
                  right: 0 !important
        .editor-wrapper
          .blend-wrapper_right
            max-width: 100%
            padding-right: 0 !important
            padding-left: 0 !important
        .editor-blocks
          .editor-block_item
            min-height: 130px
            &:nth-child(2n)
              //max-width: initial
              //flex-grow: 1
              max-width: 100%