body
  .moveUp
    animation: moveUp 1s infinite ease-out
  .delay-1
    animation-delay: .2s
  .delay-2
    animation-delay: .4s
  .delay-3
    animation-delay: .6s
  .delay-4
    animation-delay: .8s
  @keyframes moveUp
    0%
      transform: translateY(0)
    20%
      transform: translateY(-5px)
    40%
      transform: translateY(0)
    100%
      transform: translateY(0)
  &.preloader
    .ajax-preloader
      opacity: 1
      visibility: visible
  .ajax-preloader
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: 99999999
    background-color: rgba(0,0,0,.9)
    display: flex
    justify-content: center
    align-items: center
    opacity: 0
    visibility: hidden
    transition: all .2s ease-out
    .loader
      display: flex
      align-items: center
      .loader__ball
        width: 16px
        height: 16px
        border-radius: 50%
        margin-left: 5px
        margin-right: 5px
        background-color: #fff