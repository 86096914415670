body
  .print-btn
    position: fixed
    right: 40px
    top: 50%
    z-index: 9
    display: flex
    flex-direction: row-reverse
    align-items: center
    border-radius: 12px
    background: #A26128
    padding: 8px 24px
    color: $white
    text-align: center
    font-size: 20px
    font-weight: 600
    @media (max-width: 480px)
      display: none
    img
      display: block
      margin-right: 12px
  .sticker-page
    padding: 40px
    background: #121010
    @media (max-width: 1700px)
      padding: 0
  .sticker-wrapper
    width: 100%
    max-width: 1295px
    margin-left: auto
    margin-right: auto
    background-color: #000
    .page-content
      .page-header
        position: relative
        left: auto
        top: auto
        background: none
        box-shadow: none
        backdrop-filter: none
        padding-bottom: 15px
    .steps-wrapper
      .step-item_wrapper
        &.editor-wrapper
          padding-bottom: 0
          min-height: 10vh
          .blend-wrapper
            @media (max-width: 1199px)
              display: block
              padding-top: 0
              padding-left: 24px
              padding-right: 24px
            .blend-wrapper_left
              @media (max-width: 1199px)
                position: relative
                top: auto
                left: auto
                opacity: 1
                visibility: visible
                background: rgba(36,35,35,.8)
                margin-bottom: 56px
                padding-top: 18px
              @media (max-width: 767px)
                min-height: 10vh
                padding-bottom: 30px
    .blend-wrapper
      padding: 0 60px 30px
      .blend-wrapper_left
        border-radius: 12px
    .sticker-title
      margin-bottom: 20px
      color: #FFF
      font-size: 42px
      font-weight: 700
      text-transform: capitalize
      @media (max-width: 1700px)
        margin-bottom: 10px
        font-size: 28px
    .editor-wrapper
      .editor-top
        margin-bottom: 25px
        padding: 14px 16px 14px 66px !important
        border-radius: 10px
        border: 1px solid #E6D0B1
        @media (max-width: 1700px)
          margin-bottom: 16px
          padding-top: 10px !important
          padding-bottom: 10px !important
        @media (max-width: 1199px)
          margin-left: 0
          margin-right: 0
          background: none
        @media (max-width: 767px)
          margin-bottom: 24px
        .percentage
          padding-right: 0
          @media (max-width: 767px)
            flex-direction: column
            align-items: flex-start
          .percentage-wrapper
            justify-content: flex-end
            @media (max-width: 767px)
              width: 100%
          .title
            @media (max-width: 767px)
              flex: auto
              margin-bottom: 10px
          strong
            margin-left: -50px
      .editor-blocks_wrapper
        background: none
        padding: 0
    .editor-blocks
      margin-bottom: 20px
      @media (max-width: 1700px)
        margin-bottom: 16px
        margin-left: -8px
        margin-right: -8px
      @media (max-width: 767px)
        margin-bottom: 24px
      &:last-child
        margin-bottom: 0
      .taste-profile
        position: absolute
        left: 50%
        top: 54%
        transform: translate(-50%, -50%)
        max-width: 280px
        width: 100%
        @media (max-width: 1700px)
          max-width: 260px
          top: 50%
        @media (max-width: 767px)
          position: relative
          top: auto
          left: auto
          transform: none
          max-width: 100%
      .editor-block_item
        max-width: 220px
        flex: 0 0 220px
        min-height: 270px
        @media (max-width: 1700px)
          padding-left: 8px
          padding-right: 8px
          min-height: 230px
        @media (max-width: 767px)
          max-width: 100%
          flex: 0 0 100%
          min-height: 10px
          margin-bottom: 24px
        &:last-child
          @media (max-width: 767px)
            margin-bottom: 0
        &:nth-child(2n)
          max-width: initial
          flex: auto
          flex-grow: 1
          @media (max-width: 991px)
            flex: 1 0
        .editor-item_inner
          .editor-title
            font-size: 16px
      .notes-wrapper
        &.small
          @media (max-width: 1700px)
            padding-top: 0
            padding-bottom: 0
          .float-flavors
            &.dynamic
              .label-flavors
                top: 0
                &.chocolaty_and_nuts,
                &.fruity_and_citrus
                  //@media (max-width: 1700px)
                    top: 90px
                  top: 90px
    .coffee-items
      .coffee-item
        margin-bottom: 10px
        display: flex
        align-items: center
        color: #E7D0B1
        font-size: 18px
        font-weight: 700
        @media (max-width: 1700px)
          margin-bottom: 8px
          font-size: 17px
        .value
          margin-right: 5px
          color: #fff
          font-weight: 400
        &:last-child
          margin-bottom: 0
    .roast-item
      .roasting-level
        margin-top: 0
        @media (max-width: 767px)
          display: flex
          justify-content: center
        .slider-wrapper
          //&.vertical
            min-height: 200px
          .range-slider
            width: 62px
            height: 200px
            //background: linear-gradient(90deg,#720d0f 0,#a36129 91.35%)
            transform: rotate(180deg)
            @media (max-width: 1700px)
              height: 170px
            &.level-1
              .range-slider__thumb
                &:after
                  //content: 'קלה'
                  content: 'בהירה'
            &.level-3
              .range-slider__thumb
                &:after
                  //content: 'חזקה'
                  content: 'כהה'
            .range-slider__thumb
              width: 74px
              height: 6px
              //background-color: #120A12
              &:before
                display: none
              &:after
                content: 'בינונית'
                top: -10px
                width: auto
                height: auto
                border-top: none
                color: #FFF
                font-size: 20px
                font-weight: 700
                left: 86px
                transform: rotate(180deg)
                @media (max-width: 1700px)
                  font-size: 18px
            .range-slider__range
              transform: none
              border-radius: 8px 8px 0 0
              top: 0 !important
              //background: #3b3b3b