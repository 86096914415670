.loader-block
  width: 100%
  max-width: 1270px
  @media (max-width: 1199px)
    padding-left: 28px
    padding-right: 28px
.loader-wrapper
  width: 100%
  //max-width: 1270px
  border-radius: 12px
  background: linear-gradient(103deg, #373737 0.56%, #2D2D2D 0.57%, #262626 42.33%, #202020 100%)
  backdrop-filter: blur(12px)
  display: flex
  flex-direction: column
  align-items: center
  padding: 150px 120px 120px
  @media (max-width: 1700px)
    padding-top: 120px
    padding-bottom: 90px
  @media (max-width: 1199px)
    padding-left: 54px
    padding-right: 54px
  @media (max-width: 480px)
    padding-top: 90px
    padding-left: 10px
    padding-right: 10px
  .loader-icon
    margin-bottom: 48px
    text-align: center
    //@media (max-width: 480px)
      margin-bottom: 48px
    .loader-icon_wrapper
      position: relative
      padding: 10px
      margin-bottom: 8px
      .circle-progress_wrapper
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
      .loader-icon_inner
        position: relative
        display: flex
        justify-content: center
        align-items: center
        width: 192px
        height: 192px
        background-color: #191919
        border-radius: 50%
        @media (max-width: 480px)
          width: 162px
          height: 162px
        img
          align-self: center
          @media (max-width: 480px)
            max-width: 80px
    .loader-count
      color: $white
      text-align: center
      font-size: 20px
      letter-spacing: -1px
  .loader-text
    color: $milk
    text-align: center
    font-size: 32px
    letter-spacing: -1px
    @media (max-width: 991px)
      font-size: 18px
