body
  background-color: $dark-bg
  .page-content
    position: relative
    //min-height: 100vh
    background-repeat: no-repeat
    background-size: cover
    background-position: center top
    background-attachment: fixed
    .lang-switcher
      position: fixed
      right: 0
      top: 50%
      z-index: 4
      transform: translateY(-50%)
      border: 3px solid $orange
      border-right: none
      display: none
      //@media (max-width: 1199px)
        display: none
      .lang-btn
        display: block
        padding: 10px 20px
        color: $white
        text-transform: uppercase
        font-weight: 700
        font-size: 18px
        background-color: $dark
        transition: all .2s ease-out
        &.active
          box-shadow: inset 2px 2px 20px rgba(0,0,0,.8)
    .page-header
      position: absolute
      left: 0
      top: 0
      width: 100%
      background: rgba(0, 0, 0, 0.50)
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)
      backdrop-filter: blur(15px)
      display: flex
      justify-content: center
      padding-top: 25px
      padding-bottom: 30px
      z-index: 2
      @media (max-width: 1700px)
        padding-top: 16px
        padding-bottom: 16px
      @media (max-width: 1199px)
        background: #191919
        backdrop-filter: none
        z-index: 4
        //padding-top: 16px
        //padding-bottom: 16px
      .logo
        img
          @media (max-width: 1700px)
            max-height: 48px
          //@media (max-width: 1400px)
            max-height: 50px
          @media (max-width: 991px)
            max-height: 44px
    .steps-progress
      position: absolute
      left: 0
      top: 116px
      width: 100%
      height: 8px
      background-color: #585858
      overflow: hidden
      z-index: 2
      @media (max-width: 1700px)
        top: 80px
      //@media (max-width: 1400px)
        top: 82px
      @media (max-width: 991px)
        top: 76px
      .steps-progress_body
        text-indent: -9999px
        position: absolute
        left: 0
        top: 0
        height: 100%
        transition: all .2s ease-out
        background-color: $orange
  .steps-wrapper
    .step-item
      position: relative
      display: none
      &.active
        display: block
      &.assistant-open
        .step-item_wrapper
          z-index: 3
          //.before
            opacity: 1
            visibility: visible
            z-index: 6
    .step-item_wrapper
      position: relative
      min-height: 100vh
      padding-top: 160px
      padding-bottom: 160px
      display: flex
      flex-direction: column
      align-items: center
      @media (max-width: 1700px)
        padding-top: 110px
        padding-bottom: 80px
      //@media (max-width: 1400px)
        padding-top: 150px
        padding-bottom: 150px
      @media (max-width: 991px)
        //padding-top: 114px
        padding-bottom: 132px
      &.open-coffee-id
        .step-footer
          z-index: 1
      &.editor-wrapper
        &:before
          display: none
      .before
        position: absolute
        left: 0
        top: 0
        bottom: 0
        right: 0
        display: block
        content: ''
        opacity: 0
        visibility: hidden
        background: rgba(0, 0, 0, 0.72)
        transition: all .2s ease-out
        z-index: 0
      &.blend-wrapper
        padding-top: 0
        padding-bottom: 0
        display: block
      &.pb-100
        padding-bottom: 100px
        @media (max-width: 1700px)
          padding-bottom: 0
      .step-title
        margin-bottom: 20px
        color: $white
        font-size: 64px
        font-weight: 800
        text-align: center
        @media (max-width: 1700px)
          margin-bottom: 0
          font-size: 56px
        @media (max-width: 1440px)
          margin-bottom: 16px
          font-size: 48px
        @media (max-width: 1400px)
          font-size: 40px
        @media (max-width: 1199px)
          font-size: 36px
        @media (max-width: 991px)
          margin-bottom: 0
          font-size: 32px
          padding-left: 5px
          padding-right: 5px
      .step-subtitle
        margin-bottom: 50px
        color: $white
        font-size: 28px
        text-align: center
        @media (max-width: 1700px)
          margin-bottom: 10px
          font-size: 24px
        //@media (max-width: 1440px)
          margin-bottom: 48px
        @media (max-width: 1400px)
          //margin-bottom: 49px
          font-size: 20px
      .step-footer
        position: fixed
        left: 0
        bottom: 0
        width: 100%
        z-index: 5
        display: flex
        padding-top: 16px
        padding-bottom: 16px
        justify-content: center
        background: linear-gradient(180deg, rgba(69, 69, 69, 0.60) 0%, rgba(32, 32, 32, 0.60) 100%)
        backdrop-filter: blur(12px)
        @media (max-width: 1700px)
          padding-top: 10px
          padding-bottom: 10px
        .buttons
          position: relative
          z-index: 1
  .coffee-types_wrapper
    width: 100%
    max-width: 940px
    @media (max-width: 1700px)
      margin-top: 20px
    @media (max-width: 991px)
      padding-left: 27px
      padding-right: 27px
  .coffee-types
    display: flex
    justify-content: center
    flex-wrap: wrap
    margin-left: -10px
    margin-right: -10px
    @media (max-width: 1400px)
      margin-left: -8px
      margin-right: -8px
    @media (max-width: 991px)
      margin-left: -7px
      margin-right: -7px
    .coffee-type_item
      max-width: 320px
      flex: 0 0 320px
      padding-left: 10px
      padding-right: 10px
      margin-bottom: 20px
      @media (max-width: 1400px)
        max-width: 252px
        flex: 0 0 252px
        padding-left: 8px
        padding-right: 8px
        margin-bottom: 16px
      @media (max-width: 991px)
        padding-left: 7px
        padding-right: 7px
        margin-bottom: 14px
      @media (max-width: 767px)
        max-width: 50%
        flex: 0 0 50%
      .coffee-type_inner
        display: block
        input
          display: none
          &:checked + .value
            &:before
              border: 1px solid $white
              background: $orange
            .title
              color: $white
              font-weight: 700
        .value
          position: relative
          padding: 23px 20px 20px
          display: flex
          flex-direction: column
          align-items: center
          border-radius: 10px
          overflow: hidden
          @media (max-width: 1400px)
            padding-top: 19px
          @media (max-width: 767px)
            padding: 15px 10px 5px
          &:before
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            display: block
            content: ''
            border-radius: 10px
            opacity: 0.8
            background: linear-gradient(180deg, #454545 0%, #202020 100%)
            backdrop-filter: blur(12px)
            box-sizing: border-box
            transition: all .2s ease-out
          .icon
            position: relative
            display: block
            margin-bottom: 11px
            max-width: 87px
            @media (max-width: 1700px)
              max-width: 82px
            @media (max-width: 1440px)
              max-width: 80px
            @media (max-width: 1400px)
              max-width: 68px
            @media (max-width: 767px)
              margin-bottom: 5px
              max-width: 66px
          .title
            display: block
            position: relative
            text-transform: uppercase
            color: $white
            text-align: center
            font-size: 28px
            font-weight: 300
            letter-spacing: -1px
            transition: all .2s ease-out
            @media (max-width: 1680px)
              font-size: 24px
            @media (max-width: 1400px)
              font-size: 16px
            @media (max-width: 767px)
              font-size: 22px
              line-height: 30px
  .range-wrapper
    width: 100%
    max-width: 1120px
    @media (max-width: 1700px)
      max-width: 1020px
      margin-left: 220px
    @media (max-width: 1199px)
      margin-left: 0
      padding-left: 28px
      padding-right: 28px
    .range-slider_wrapper
      flex-grow: 1
      @media (max-width: 1199px)
        width: 100%
        border-radius: 12px
        background-color: #1b1b1b
        padding: 22px 7px 19px
      &.thumb-labels
        .acidity-1
          .range-slider__thumb
            &:after
              content: 'ללא'
        .acidity-2
          .range-slider__thumb
            &:after
              content: 'תפוח ירוק'
        .acidity-3
          .range-slider__thumb
            &:after
              content: 'תפוז'
        .acidity-4
          .range-slider__thumb
            &:after
              content: 'אשכולית אדומה'
        .acidity-5
          .range-slider__thumb
            &:after
              content: 'לימונית'
        .sweetness-1
          .range-slider__thumb
            &:after
              content: 'עדינה'
        .sweetness-2
          .range-slider__thumb
            &:after
              content: 'קנה סוכר'
        .sweetness-3
          .range-slider__thumb
            &:after
              content: 'שוקולד חלב'
        .sweetness-4
          .range-slider__thumb
            &:after
              content: 'קרמל'
        .sweetness-5
          .range-slider__thumb
            &:after
              content: 'דבש'
        .bitterness-1
          .range-slider__thumb
            &:after
              content: 'עדינה'
        .bitterness-2
          .range-slider__thumb
            &:after
              content: 'שקדים'
        .bitterness-3
          .range-slider__thumb
            &:after
              content: 'שוקולד מריר'
        .bitterness-4
          .range-slider__thumb
            &:after
              content: 'קקאו'
        .bitterness-5
          .range-slider__thumb
            &:after
              content: 'תה שחור'
        .body-1
          .range-slider__thumb
            &:after
              content: 'קליל'
        .body-2
          .range-slider__thumb
            &:after
              content: 'עדין'
        .body-3
          .range-slider__thumb
            &:after
              content: 'בינוני'
        .body-4
          .range-slider__thumb
            &:after
              content: 'מלא ועשיר'
        .body-5
          .range-slider__thumb
            &:after
              content: 'סמיך'
        .range-slider__thumb
          &:after
            position: absolute
            top: 110%
            left: 50%
            transform: translateX(-50%)
            display: block
            content: 'Taste profile'
            padding: 7px 20px 8px
            border-radius: 8px
            font-size: 14px
            font-weight: 600
            white-space: nowrap
            background-color: rgba(141,141,141,.46)
            opacity: 1
            //transition: all .2s ease-out
            @media (max-width: 1199px)
              top: 30px
              font-size: 13px
              padding: 4px 10px
    .range-slider
      flex-grow: 1
      background: rgba(255, 255, 255, 0.32)
      @media (max-width: 1199px)
        height: 6px
        border-radius: 0
      .range-slider__thumb[data-disabled]
        display: none
      .range-slider__range
        left: 0 !important
        border-radius: 4px
        background: $orange
        width: auto
      .range-slider__thumb
        width: 96px
        height: 56px
        border-radius: 20px
        border: 2px solid $white
        background: $orange
        display: flex
        align-items: center
        justify-content: center
        color: $white
        font-size: 32px
        font-weight: 700
        @media (max-width: 1199px)
          width: 40px
          height: 26px
          font-size: 16px
          border-radius: 10px
    .range-item
      position: relative
      margin-bottom: 65px
      display: flex
      align-items: center
      @media (max-width: 1700px)
        margin-bottom: 40px
      @media (max-width: 1199px)
        flex-direction: column
        align-items: center
        margin-bottom: 32px
      .tooltip
        position: absolute
        top: 0
        left: -264px
        display: flex
        align-items: flex-start
        @media (max-width: 1199px)
          position: fixed
          left: 0
          width: 100%
          height: 100%
          padding-top: 0
          padding-bottom: 0
          display: block
          opacity: 0
          visibility: hidden
        &.hover
          z-index: 2
          @media (max-width: 1199px)
            z-index: 6
            opacity: 1
            visibility: visible
          .tooltip-icon
            //filter: unset
            opacity: 1
          .tooltip-wrapper
            opacity: 1
            visibility: visible
            //height: auto
        .tooltip-icon
          position: relative
          margin-top: 20px
          max-width: 18px
          flex: 0 0 18px
          margin-left: 50px
          cursor: pointer
          opacity: .3
          //filter: grayscale(1)
          transition: all .2s ease-out
          z-index: 2
        .tooltip-wrapper
          position: relative
          max-width: 235px
          flex: 0 0 235px
          box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15)
          background: rgba(0, 0, 0, 0.59)
          display: flex
          flex-direction: column
          align-items: center
          border-radius: 10px
          padding: 28px 11px
          opacity: 0
          visibility: hidden
          //height: 0
          transition: all .2s ease-out
          @media (max-width: 1199px)
            max-width: 100%
            width: 100%
            height: 100%
            padding-top: 48px
            background: rgba(0,0,0,.9)
            border-radius: 0
          &.sweetness
            margin-top: -70px
            @media (max-width: 1199px)
              margin-top: 0
          &.bitterness
            margin-top: -140px
            @media (max-width: 1199px)
              margin-top: 0
          &.body
            margin-top: -210px
            @media (max-width: 1199px)
              margin-top: 0
          .icon
            position: absolute
            left: 50%
            top: -18px
            width: 36px
            transform: translateX(-50%)
            @media (max-width: 1199px)
              position: relative
              top: 0
              left: auto
              transform: none
              margin-bottom: 11px
          .title
            margin-bottom: 18px
            color: $white
            text-align: center
            font-size: 32px
            line-height: 1.3
            font-weight: 700
          .desc
            //margin-bottom: 22px
            @media (max-width: 1199px)
              margin-bottom: 44px
            @media (max-width: 480px)
              max-width: 270px
            p
              color: $white
              text-align: center
              font-size: 16px
              line-height: 1.375
              @media (max-width: 480px)
                font-size: 18px
          .close-tooltip
            display: none
            @media (max-width: 1199px)
              display: block
              filter: invert(1)
            img
              width: 47px
          .buttons
            padding-left: 8px
            padding-right: 8px
      .range-label
        position: relative
        max-width: 195px
        flex: 0 0 195px
        border-radius: 10px
        background: linear-gradient(180deg, rgba(69,69,69,.8) 0%, rgba(32,32,32,.8) 100%)
        backdrop-filter: blur(12px)
        padding: 15px
        color: $white
        font-size: 20px
        font-weight: 600
        text-align: center
        @media (max-width: 1199px)
          flex: auto
          min-width: 120px
          margin-bottom: 10px
          padding: 8px
        &:before
          @media (max-width: 1199px)
            position: absolute
            bottom: -10px
            left: 50%
            transform: translateX(-50%)
            display: block
            content: ''
            border-top: 12px solid #222
            border-left: 10px solid transparent
            border-right: 10px solid transparent
        .tooltip-icon
          position: absolute
          left: 20px
          top: 20px
          opacity: .3
          cursor: pointer
          transition: all .2s ease-out
          @media (max-width: 1199px)
            display: none
            opacity: 1
            top: 12px
            left: -27px
          &.white
            display: none
            @media (max-width: 1199px)
              display: block
          &:hover
            opacity: 1
      &:last-child
        margin-bottom: 0
  .notes-wrapper
    padding-top: 20px
    padding-bottom: 40px
    display: flex
    justify-content: center
    direction: ltr
    @media (max-width: 1700px)
      padding-top: 5px
    @media (max-width: 991px)
      padding-top: 0
    &.small
      position: relative
      padding-top: 20px
      padding-bottom: 35px
      @media (max-width: 1700px)
        padding-bottom: 15px
        padding-top: 15px
      @media (max-width: 1199px)
        padding-top: 10px
      .float-flavors
        position: absolute
        left: 0
        top: 0
        width: 100%
        &.dynamic
          @media (max-width: 991px)
            position: relative
            left: auto
            right: auto
            margin-top: 20px
            display: flex
            flex-wrap: wrap
            justify-content: center
            max-width: 240px
          .label-flavors
            top: 10px
            padding-bottom: 0
            min-width: 110px
            min-height: 84px
            @media (max-width: 991px)
              position: relative
              left: auto !important
              top: auto !important
              right: auto !important
              //display: flex
              //align-items: flex-start
              display: block
              margin-left: 5px
              margin-right: 5px
              margin-bottom: 10px
              flex: 0 0 110px
              &.sweet_and_floral
                order: 1
              &.spices_and_roasted
                order: 0
              &.chocolaty_and_nuts
                order: 3
              &.fruity_and_citrus
                order: 2
            .flavours-title
              margin-bottom: 0
              display: none
            .label-flavor_item
              margin-bottom: 0
              padding-top: 2px
              padding-bottom: 0
              @media (max-width: 991px)
                width: 100%
              .title
                padding-top: 0
                white-space: nowrap
              .icon
                max-width: 18px
                flex: 0 0 18px
                img
                  max-width: 18px
                  max-height: 18px
        .label-flavors
          position: absolute
          left: 0
          top: 0
          border-radius: 8px
          box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08)
          background: #3B3B3B
          padding: 0 0 8px
          min-width: 130px
          opacity: 1
          visibility: visible
          transition: all .2s ease-out
          overflow: hidden
          @media (max-width: 1700px)
            min-width: 115px
          &.empty
            display: flex
            justify-content: center
            align-items: center
            img
              filter: grayscale(1)
              max-width: 24px
          &:last-child
            left: auto
            right: 0
          //&.spices_and_roasted
            min-width: 115px
          &.fruity_and_citrus
            top: 110px
            @media (max-width: 1199px)
              top: 140px
          &.sweet_and_floral
            left: auto
            right: 0
          &.chocolaty_and_nuts
            top: 110px
            left: auto
            right: 0
            @media (max-width: 1199px)
              top: 140px
          @media (max-width: 991px)
            display: none
          .flavours-title
            margin-bottom: 5px
            background-color: $orange
            text-align: center
            color: $white
            font-size: 13px
            font-weight: 700
            line-height: 26px
          .label-flavor_item
            display: flex
            flex-direction: row-reverse
            align-items: center
            margin-bottom: 8px
            padding-left: 8px
            padding-right: 8px
            @media (max-width: 1700px)
              margin-bottom: 0
            &:last-child
              margin-bottom: 0
            .icon
              display: block
              max-width: 16px
              flex: 0 0 16px
              img
                max-height: 16px
            .title
              display: block
              padding-left: 0
              padding-right: 8px
              color: $white
              padding-top: 2px
              line-height: 24px
              font-size: 12px
              font-weight: 600
      .notes-wrapper_inner
        width: 160px
        height: 160px
        @media (max-width: 1199px)
          width: 240px
          height: 240px
        .notes-item
          .notes-item_label
            //pointer-events: none
            input
              &:checked + .label-value
                .label-title
                  font-weight: 600
            .label-value
              width: 64px
              height: 64px
              @media (max-width: 1199px)
                width: 104px
                height: 104px
              &.top-right
                .label-bg
                  img
                    left: auto
                    right: 0
                .label-icon
                  bottom: auto
                  top: 8px
                .label-title
                  padding-right: 0
                  padding-left: 26px
                  transform: rotate(46.444deg)
                  @media (max-width: 1199px)
                    padding-left: 40px
                .label-flavors
                  left: auto
                  right: -140px
              &.bottom-left
                .label-bg
                  img
                    top: auto
                    bottom: 0
                .label-icon
                  bottom: auto
                  top: 4px
                .label-title
                  padding-right: 0
                  padding-left: 22px
                  padding-top: 18px
                  max-width: 60px
                  transform: rotate(46.444deg)
                  @media (max-width: 1199px)
                    padding-left: 40px
                    padding-top: 27px
                    max-width: 94px
                .label-flavors
                  bottom: -80px
              &.bottom-right
                .label-bg
                  img
                    left: auto
                    right: 0
                    top: auto
                    bottom: 0
                .label-title
                  padding-top: 26px
                  padding-right: 0
                  padding-left: 5px
                  max-width: 50px
                  @media (max-width: 1199px)
                    padding-top: 56px
                    padding-left: 21px
                .label-flavors
                  left: auto
                  right: -140px
                  top: auto
                  bottom: -80px
              .label-icon
                width: 14px
                height: 14px
                left: 7px
                bottom: 7px
                @media (max-width: 1199px)
                  width: 24px
                  height: 24px
                img
                  max-width: 10px
                  @media (max-width: 1199px)
                    max-width: 14px
              .label-title
                padding-top: 12px
                padding-right: 14px
                padding-left: 5px
                font-size: 8px
                font-weight: 600
                letter-spacing: 0.611px
                @media (max-width: 1199px)
                  font-size: 13px
                  padding-top: 21px
                  padding-right: 30px
                //&.chocolate
                  max-width: 60px
                  @media (max-width: 1199px)
                    max-width: 90px
              .label-flavors
                background: #3B3B3B
                padding: 16px 8px
                top: auto
                bottom: -150px
                left: -140px
                min-width: 130px
                .label-flavor_item
                  margin-bottom: 8px
                  &:last-child
                    margin-bottom: 0
                  .title
                    font-size: 12px
                    font-weight: 600
    &.active
      .notes-wrapper_inner
        .notes-item
          .notes-item_label
            pointer-events: auto
    .notes-wrapper_inner
      position: relative
      width: 345px
      height: 345px
      border-radius: 50%
      background: #232223
      box-shadow: 0 4px 53px 0 rgba(0, 0, 0, 0.08)
      display: flex
      justify-content: center
      align-items: center
      @media (max-width: 1199px)
        box-shadow: 0 3px 37px rgba(0, 0, 0, 0.08)
      .notes-items
        display: flex
        flex-wrap: wrap
        justify-content: center
        align-items: center
      .notes-item
        padding: 5px
        .notes-item_label
          display: block
          position: relative
          input
            display: none
            &:checked + .label-value
              .label-bg
                img
                  opacity: 0
                  &.active
                    opacity: 1
              .label-icon
                background-color: $milk
                img
                  opacity: 0
                  &.active
                    opacity: 1
              .label-title
                color: $white
                font-weight: 700
              .label-flavors
                opacity: 1
                visibility: visible
                &.op-0
                  opacity: 0
                  visibility: hidden
          .label-value
            position: relative
            display: block
            height: 155px
            width: 155px
            //&:hover
              .label-flavors
                opacity: 1
                visibility: visible
            &.top-right
              .label-bg
                img
                  left: auto
                  right: 0
              .label-icon
                bottom: auto
                top: 15px
              .label-title
                padding-right: 0
                padding-left: 65px
                transform: rotate(46.444deg)
              .label-flavors
                left: auto
                right: -250px
            &.bottom-left
              .label-bg
                img
                  top: auto
                  bottom: 0
              .label-icon
                bottom: auto
                top: 15px
              .label-title
                padding-right: 0
                padding-left: 50px
                padding-top: 50px
                transform: rotate(46.444deg)
                max-width: 150px
              .label-flavors
                top: auto
                bottom: -85px
            &.bottom-right
              .label-bg
                img
                  left: auto
                  right: 0
                  top: auto
                  bottom: 0
              .label-title
                padding-top: 60px
                padding-right: 6px
                max-width: 130px
              .label-flavors
                left: auto
                right: -250px
                top: auto
                bottom: -85px
            .label-bg
              img
                position: absolute
                left: 0
                top: 0
                opacity: 1
                transition: all .2s ease-out
                &.active
                  opacity: 0
            .label-icon
              position: absolute
              left: 15px
              bottom: 13px
              border-radius: 50%
              width: 32px
              height: 32px
              background-color: $orange
              transition: all .2s ease-out
              img
                position: absolute
                left: 50%
                top: 50%
                transform: translate(-50%, -50%)
                opacity: 1
                transition: all .2s ease-out
                &.active
                  opacity: 0
            .label-title
              padding-top: 33px
              padding-right: 50px
              display: block
              position: relative
              transform: rotate(-45.327deg)
              color: $orange
              text-align: center
              font-size: 18px
              font-weight: 700
              letter-spacing: 1.361px
              text-transform: uppercase
              transition: all .2s ease-out
              //&.chocolate
                max-width: 145px
          .label-flavors
            min-width: 216px
            position: absolute
            left: -250px
            top: -85px
            border-radius: 8px
            background: #1D1D1D
            box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08)
            padding: 24px
            opacity: 0
            visibility: hidden
            transition: all .2s ease-out
            @media (max-width: 991px)
              display: none
            .label-flavor_item
              display: flex
              align-items: center
              margin-bottom: 16px
              &:last-child
                margin-bottom: 0
              .icon
                display: block
                max-width: 18px
                flex: 0 0 18px
              .title
                display: block
                padding-left: 8px
                color: $white
                font-size: 18px
                font-weight: 600
                line-height: 26px
      .notes-amount
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        color: $white
        text-align: center
        font-size: 42px
        font-weight: 700
        line-height: 1
        @media (max-width: 480px)
          top: 42%
  .error-note
    position: absolute
    bottom: 140px
    display: flex
    align-items: center
    border-radius: 12px
    background: rgba(255, 133, 133, 0.52)
    padding: 7px 12px 6px
    color: $white
    text-align: center
    font-size: 16px
    opacity: 0
    visibility: hidden
    transition: all .2s ease-out
    @media (max-width: 1700px)
      bottom: 90px
    @media (max-width: 480px)
      bottom: auto
      top: 410px
      padding: 0
      background: none
      max-width: 130px
    &.show
      opacity: 1
      visibility: visible
    .icon
      display: block
      margin-right: 14px
      @media (max-width: 480px)
        display: none
  .consuming-wrapper
    width: 100%
    max-width: 630px
    @media (max-width: 1700px)
      margin-top: 50px
    @media (max-width: 767px)
      padding-left: 28px
      padding-right: 28px
    .consuming-item
      margin-bottom: 50px
      @media (max-width: 991px)
        margin-bottom: 24px
      &:last-child
        margin-bottom: 0
    .consuming-label
      display: block
      input
        display: none
        &:checked + .name
          background: $orange
          color: $white
      .name
        display: block
        border-radius: 10px
        background: rgba(255, 255, 255, 0.13)
        backdrop-filter: blur(12px)
        padding: 16px
        color: $milk
        text-align: center
        font-size: 22px
        font-weight: 600
        transition: all .2s ease-out
        &:hover
          color: $white
          background: $orange



