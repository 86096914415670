body
  .steps-wrapper
    .step-item_wrapper
      &.editor-wrapper
        display: block
        padding-top: 0
        padding-bottom: 79px
        @media (max-width: 1700px)
          padding-bottom: 58px
        &.open-coffee-id
          .blend-wrapper
            z-index: 2
            .blend-wrapper_left
              opacity: 1
              visibility: visible
          .blend-assistant
            z-index: 1
        .blend-wrapper
          @media (max-width: 1199px)
            padding-bottom: 20px
            padding-top: 101px
          @media (max-width: 991px)
            padding-top: 84px
          .blend-wrapper_left
            padding-bottom: 0
            @media (max-width: 1199px)
              position: fixed
              left: 0
              top: 0
              width: 100%
              min-height: 100vh
              overflow-y: auto
              transition: all .2s ease-out
              z-index: 5
              opacity: 0
              visibility: hidden
              background: rgba(0,0,0,.9)
              padding-top: 60px
            .coffee-type
              @media (max-width: 767px)
                //margin-bottom: 10px
                margin-bottom: 0
            .blend-sidebar_title
              @media (max-width: 767px)
                margin-bottom: 10px
              .title
                @media (max-width: 767px)
                  font-size: 14px
                  flex: auto
                  max-width: fit-content
                  padding-right: 10px
            .flavors
              .flavors-wrapper
                .flavor-value_item
                  @media (max-width: 767px)
                    font-size: 10px
                  .value
                    @media (max-width: 767px)
                      font-size: 11px
                .flavors-chart
                  @media (max-width: 767px)
                    padding-left: 30px
                    padding-right: 30px
                    max-width: 274px
                  canvas
                    @media (max-width: 767px)
                      left: 35px
                      top: -105px
            .tastes
              .notes-wrapper
                &.small
                  @media (max-width: 767px)
                    padding-top: 0
                    padding-bottom: 0
            .close-coffee-id
              display: none
              @media (max-width: 1199px)
                position: fixed
                left: 0
                top: 0
                width: 100%
                display: flex
                justify-content: center
                padding: 5px
                background: #E7D0B1
              .close-coffee-id-btn
                display: flex
                align-items: center
                color: #151515
                font-size: 24px
                font-weight: 700
                line-height: 33px
                img
                  margin-left: 5px
                  margin-top: 3px
          .blend-wrapper_right
            @media (max-width: 1199px)
              display: block
              padding: 0 10px
              background: none
        .blend-assistant
          @media (max-width: 1199px)
            bottom: 90px
  .editor-wrapper
    padding-top: 20px
    @media (max-width: 1700px)
      padding-top: 10px
    @media (max-width: 1199px)
      padding-top: 0
    .blend-wrapper
      min-height: 10vh
    .blend-wrapper_right
      max-width: 910px
      @media (max-width: 1199px)
        max-width: 100%
    .editor-top
      display: flex
      align-items: center
      position: relative
      margin-bottom: 16px
      justify-content: space-between
      //padding-right: 180px
      @media (max-width: 1199px)
        //position: absolute
        //left: 0
        //top: 101px
        //width: 100%
        margin-left: -10px
        margin-right: -10px
        background-color: #2e2e2e
        padding: 8px 14px 11px
        margin-bottom: 20px
        display: block
      //.tooltip
        max-width: 30px
        flex: 0 0 30px
        cursor: pointer
        @media (max-width: 1199px)
          display: none
        &:hover
          img
            filter: grayscale(0)
        img
          filter: grayscale(1)
          transition: all .2s ease-out
      .percentage
        //padding-left: 12px
        color: $white
        font-size: 28px
        display: flex
        align-items: center
        flex-grow: 1
        padding-right: 36px
        @media (max-width: 1199px)
          padding-left: 0
          //padding-bottom: 25px
        @media (max-width: 767px)
          font-size: 20px
        .title
          max-width: 170px
          flex: 0 0 170px
          font-size: 18px
        .percentage-wrapper
          position: relative
          //background-color: $black
          background-color: $white
          flex-grow: 1
          height: 25px
          display: flex
          justify-content: center
          align-items: center
          border-radius: 0 6px 6px 0
          //@media (max-width: 1199px)
            position: relative
            top: 13px
          .percentage-progress
            position: absolute
            left: 0
            top: 0
            height: 100%
            background-color: #00944d
            transition: all .2s ease-out
          &.medium
            .percentage-progress
              background-color: #A26128
            //strong
              color: $orange
          &.low
            .percentage-progress
              background-color: #f00
            //strong
              color: $red
        strong
          position: relative
          color: $white
          text-align: center
          font-size: 16px
          letter-spacing: -1px
          z-index: 2
          //font-weight: 400
          @media (max-width: 1199px)
            font-size: 14px
        svg
          position: absolute
          left: -4px
          top: -4px
          width: 54px
          height: 54px
          //@media (max-width: 1199px)
            left: -4px
            top: -4px
            width: 48px
            height: 48px
      .buttons
        //position: absolute
        //right: 0
        //top: 14px
        //@media (max-width: 1199px)
          right: auto
          top: 50px
          left: 14px
        max-width: 216px
        flex: 0 0 216px
        justify-content: flex-end
        @media (max-width: 1199px)
          margin-bottom: 16px
          max-width: 100%
          flex: auto
        &.multiple
          .thm-btn
            margin-right: 0
            margin-left: 10px
            min-width: 82px
            height: 46px
            justify-content: center
            border-radius: 10px
            background-color: $black
            border: 1px solid $orange
            color: $white
            font-size: 14px
            @media (max-width: 1700px)
              height: 36px
            @media (max-width: 1199px)
              margin-right: 8px
              margin-left: 0
            &.surprise,
            &.profile
              min-width: 124px
            &.profile
              display: none
              @media (max-width: 1199px)
                display: flex
            img
              margin-left: 8px
              max-width: 20px
      .coffee-type_icon
        display: none
        @media (max-width: 1199px)
          margin-left: 20px
          display: flex
          width: 50px
          height: 50px
          border-radius: 5px
          background: $orange
          align-items: center
          justify-content: center
          flex-direction: column
        img
          max-width: 24px
        .name
          display: block
          color: $white
          text-align: center
          font-size: 10px
          line-height: 16px
          text-transform: capitalize
    .editor-blocks_wrapper
      @media (max-width: 1199px)
        padding: 25px 16px 0
        border-radius: 12px
        background: rgba(36, 35, 35, 0.80)
      @media (max-width: 767px)
        padding-top: 14px
        padding-left: 14px
        padding-right: 14px
  .editor-blocks
    display: flex
    flex-wrap: wrap
    margin-left: -10px
    margin-right: -10px
    .editor-block_item
      padding-left: 10px
      padding-right: 10px
      margin-top: 20px
      max-width: 50%
      flex: 0 0 50%
      @media (max-width: 1700px)
        margin-top: 16px
      @media (max-width: 1199px)
        margin-top: 0
      @media (max-width: 767px)
        order: 1
        margin-bottom: 14px
        max-width: 100%
        flex: 0 0 100%
      &:nth-child(1),
      &:nth-child(2)
        margin-top: 0
      &.show-tooltip-mobile
        .tooltip-cover
          &.mobile
            opacity: 1
            visibility: visible
      .notes-wrapper
        @media (max-width: 991px)
          flex-direction: column
          align-items: center
          padding-bottom: 0
      &.select-item
        position: relative
        z-index: 2
        //max-width: 455px
        //flex: 0 0 56%
        //@media (max-width: 1199px)
          max-width: 56%
        //@media (max-width: 1199px)
          position: static
        @media (max-width: 767px)
          //max-width: 100%
          //flex: 0 0 100%
          order: 0
      //&.chart-item
        max-width: 360px
        flex: 0 0 44%
        @media (max-width: 1199px)
          max-width: 44%
        @media (max-width: 767px)
          max-width: 100%
          flex: 0 0 100%
      &.roast-item
        //max-width: 455px
        //flex: 0 0 56%
        //@media (max-width: 1199px)
          max-width: 56%
        @media (max-width: 767px)
          //max-width: 100%
          //flex: 0 0 100%
          order: 0
      //&.notes-item
        max-width: 360px
        flex: 0 0 44%
        @media (max-width: 1199px)
          max-width: 44%
        @media (max-width: 767px)
          max-width: 100%
          flex: 0 0 100%
      //.notes-wrapper
        &.small
          padding-bottom: 10px
          padding-top: 25px
      .editor-item_inner
        position: relative
        border-radius: 10px
        background: #000
        backdrop-filter: blur(12px)
        border: 1px solid $black
        padding: 15px
        height: 100%
        @media (max-width: 1700px)
          padding-top: 10px
          padding-bottom: 10px
        //@media (max-width: 1199px)
          position: static
        &.editable
          border: 1px solid $milk
      .tooltip
        position: absolute
        right: 8px
        top: 8px
        z-index: 5
        cursor: pointer
        &:hover
          + .tooltip-cover
            opacity: 1
            visibility: visible
      .tooltip-cover
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        z-index: 4
        border-radius: 10px
        background: rgba(0, 0, 0, 0.90)
        backdrop-filter: blur(2.5px)
        transition: all .2s ease-out
        padding: 15px
        display: flex
        align-items: center
        justify-content: center
        opacity: 0
        visibility: hidden
        &.mobile
          display: none
          @media (max-width: 1199px)
            display: flex
            height: auto
            position: fixed
            top: 0
            border-radius: 0
            min-height: 100vh
            padding-top: 100px
        @media (max-width: 1199px)
          display: none
          align-items: flex-start
          //height: auto
          //min-height: 100vh
          //border-radius: 0
          //top: -130px
          //left: -24px
          //right: -24px
          //width: auto
          z-index: 9
        .tooltip-cover_wrapper
          display: flex
          flex-direction: column
          align-items: center
          .tooltip-icon
            margin-bottom: 11px
            display: none
            @media (max-width: 1199px)
              display: block
          .tooltip-title
            margin-bottom: 11px
            color: $white
            text-align: center
            font-size: 28px
            font-weight: 600
            @media (max-width: 1700px)
              font-size: 24px
          .tooltip-text
            p
              color: $white
              text-align: center
              font-size: 14px
              @media (max-width: 1700px)
                font-size: 13px
          .close-tooltip
            margin-top: 30px
            display: none
            @media (max-width: 1199px)
              display: block
            img
              width: 47px
              height: 47px
              filter: invert(1)
      .editor-title
        margin-bottom: 16px
        color: $white
        font-size: 16px
        font-weight: 700
        text-transform: capitalize
        @media (max-width: 1700px)
          margin-bottom: 10px
    .taste-profile
      margin-left: auto
      margin-right: auto
      max-width: 260px
      @media (max-width: 767px)
        max-width: 100%
      canvas
        @media (max-width: 1700px)
          position: absolute
          left: 50%
          top: 50%
          transform: translate(-50%, -50%)
        @media (max-width: 1199px)
          position: relative
          left: auto
          top: auto
          transform: none
  .blend-dropdowns
    display: flex
    flex-wrap: wrap
    @media (max-width: 767px)
      margin-left: -10px
      margin-right: -10px
    .blend-dropdown_item
      margin-bottom: 27px
      max-width: 33.33%
      flex: 0 0 33.33%
      padding-left: 6px
      padding-right: 6px
      @media (max-width: 1700px)
        margin-bottom: 10px
      @media (max-width: 767px)
        padding-left: 10px
        padding-right: 10px
      &:last-child
        margin-bottom: 0
      .default-dropdowns
        display: none
      .custom-dropdowns
        display: flex
        align-items: center
        flex-direction: column
        //justify-content: space-between
        .label
          margin-bottom: 8px
          color: $milk
          font-size: 16px
          font-weight: 700
          text-transform: capitalize
          @media (max-width: 1700px)
            margin-bottom: 5px
            font-size: 14px
        .custom-dropdown
          padding-top: 0
          //max-width: 110px
          max-width: 100%
          .custom-dropdown_wrapper
            &.removed
              .current-item
                color: transparent
                img
                  opacity: 1
                  visibility: visible
            .current-item
              position: relative
              padding-top: 16px
              padding-bottom: 16px
              padding-left: 28px
              border-radius: 10px
              background: rgba(255, 255, 255, 0.15)
              border: none
              text-align: center
              @media (max-width: 767px)
                padding-top: 10px
                padding-bottom: 10px
                text-align: right
                font-size: 14px
                line-height: 20px
              img
                position: absolute
                left: 56%
                top: 50%
                max-height: 34px
                opacity: 0
                visibility: visible
                transform: translate(-50%, -50%)
                transition: all .2s ease-out
              &:before,
              &:after
                left: 12px
                top: 27px
                @media (max-width: 767px)
                  top: 21px
              &:after
                left: 19px
  .roast-item
    .roasting-level
      position: relative
      margin-top: 55px
      @media (max-width: 767px)
        margin-top: 25px
        padding-bottom: 30px
      select
        display: none
      .slider-wrapper
        //width: 120px
        //display: flex
        //justify-content: center
        .labels
          display: flex
          justify-content: space-between
          margin-bottom: 12px
          @media (max-width: 767px)
            margin-bottom: 22px
          .label-item
            color: $white
            font-size: 16px
            font-weight: 700
            @media (max-width: 767px)
              font-size: 14px
              font-weight: 500
        .range-slider
          //height: 225px !important
          //width: 60px
          position: relative
          border-radius: 8px
          height: 50px
          background: #3b3b3b
          @media (max-width: 767px)
            height: 34px
          .range-slider__range
            //top: 0 !important
            left: 0 !important
            border-radius: 8px 0 0 8px
            background: linear-gradient(90deg, #720D0F 0%, #A36129 91.35%)
          .range-slider__thumb
            width: 24px
            height: 60px
            background: #9F9F9F
            border-radius: 6px
            @media (max-width: 767px)
              width: 18px
              height: 44px
            &:before,
            &:after
              position: absolute
              left: 6px
              top: 15px
              display: block
              content: ''
              width: 12px
              height: 30px
              border-top: 2px solid $white
              border-bottom: 2px solid $white
              box-sizing: border-box
              @media (max-width: 767px)
                width: 8px
                height: 24px
                left: 5px
                top: 10px
            &:after
              top: 30px
              border-bottom: none
              @media (max-width: 767px)
                top: 22px
            &[data-disabled]
              display: none
      .roasting-level_label
        position: absolute
        left: 130px
        top: 50%
        transform: translateY(-50%)
        color: $milk
        font-size: 14px
        font-weight: 700
        text-transform: uppercase
        text-align: center
        display: none
        span
          display: block
          font-size: 10px
  .percentage-left
    display: flex
    justify-content: center
    color: $white
    font-size: 12px
    letter-spacing: 1px
    transition: all .2s ease-out
    &.op-0
      opacity: 0
    span
      color: $orange
      font-weight: 700
  //.editor-block_item
    .notes-wrapper
      &.small
        padding-top: 0
        padding-bottom: 0
        .notes-wrapper_inner
          width: 190px
          height: 190px
          .notes-item
            .notes-item_label
              .label-value
                width: 79px
                height: 79px
                &.top-right
                  .label-icon
                    bottom: auto
                    top: 9px
                  .label-title
                    padding-left: 35px
                    padding-right: 0
                &.bottom-left
                  .label-icon
                    bottom: auto
                    top: 9px
                  .label-title
                    padding: 23px 0 0 36px
                    max-width: 100px
                &.bottom-right
                  .label-title
                    padding: 36px 0 0 16px
                .label-flavors
                  opacity: 0
                  visibility: hidden
                .label-icon
                  width: 18px
                  height: 18px
                  left: 9px
                  bottom: 9px
                  img
                    max-width: 12px
                .label-title
                  font-size: 10px
                  padding-right: 18px
                  padding-top: 15px



