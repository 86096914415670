body
  .page-content
    &.lang-he
      direction: rtl
      text-align: right
      .steps-progress
        .steps-progress_body
          left: auto
          right: 0
      .range-wrapper
        @media (max-width: 1700px)
          margin-left: 0
          margin-right: 220px
        @media (max-width: 1199px)
          margin-right: 0
        .range-item
          .tooltip
            left: auto
            right: -264px
            @media (max-width: 1199px)
              right: 0
          .range-label
            .tooltip-icon
              left: auto
              right: 20px
              @media (max-width: 1199px)
                left: auto
                right: -27px
      .notes-wrapper
        .notes-wrapper_inner
          .notes-item
            .notes-item_label
              .label-flavors
                .label-flavor_item
                  flex-direction: row-reverse
                  .title
                    padding-left: 0
                    padding-right: 8px
      .error-note
        .icon
          margin-right: 0
          margin-left: 14px
      .blend-wrapper
        .blend-wrapper_right
          padding-left: 0
          padding-right: 20px
          @media (max-width: 1440px)
            padding-left: 20px
          @media (max-width: 1199px)
            padding-right: 18px
            padding-left: 18px
        .coffee-type
          .coffee-type_info
            padding-left: 0
            padding-right: 16px
        .flavors
          .flavors-wrapper
            .flavors-chart
              canvas
                @media (max-width: 480px)
                  left: auto
                  right: 18px
        .change-preferences
          .change-btn
            img
              margin-right: 0
              margin-left: 5px
      .blend-assistant
        left: auto
        right: 7%
        @media (max-width: 1700px)
          left: auto
          right: 27px
        .assistant-quote
          left: auto
          right: 0
          transform-origin: right 0
          .quote-text
            .close-quote
              right: auto
              left: 13px
          .icon
            left: auto
            right: 30px
            transform: scale(-1,1)
      .best-blends
        .blend-item
          .blend-info
            .blend-desc
              padding-left: 0
              padding-right: 20px
              @media (max-width: 767px)
                padding-left: 0
                padding-right: 16px
              .flavours
                .label-flavor_item
                  .title
                    padding-left: 0
                    padding-right: 8px
                    @media (max-width: 767px)
                      padding-left: 0
                      padding-right: 6px
              .flavors-values
                .flavor-value_item
                  .value
                    margin-right: 0
                    margin-left: 5px
          //.blend-buy
            padding-left: 70px
      .create-blend
        padding-right: 24px
        @media (max-width: 1700px)
          padding-right: 20px
        @media (max-width: 767px)
          padding-right: 14px
          padding-left: 14px
        .create-blend_inner
          padding-right: 0
          //.buttons
            @media (max-width: 1199px)
              padding-left: 0
              padding-right: 70px
            @media (max-width: 767px)
              padding-right: 0
      .modal
        .modal-dialog
          .modal-content
            .close-modal
              right: auto
              left: -60px
              @media (max-width: 1400px)
                right: auto
                left: 0
      .product-popup
        &.roast-me-popup
          .product-info_top
            .product-info_left
              .product-info
                padding-left: 10px
                padding-right: 10px
            .product-info_wrapper
              padding-right: 0
              padding-left: 30px
              .edit-icon
                right: auto
                left: 0
              .product-info_desc
                padding-left: 0
                padding-right: 20px
                @media (max-width: 767px)
                  padding-right: 0
          .product-info_bottom
            .product-bottom_wrapper
              .product-bottom_item
                .product-item_title
                  span
                    padding-right: 0
                    padding-left: 20px
                    min-width: 130px
              //.range-slider
                transform: rotate(180deg)
              .roasting-level
                .roasting-level_label
                  left: auto
                  right: 338px
                  @media (max-width: 1199px)
                    left: auto
                    right: 308px
                  @media (max-width: 767px)
                    left: auto
                    right: 0
            .coffee-items
              .coffee-item
                margin-right: 0
                margin-left: 16px
                @media (max-width: 1700px)
                  margin-right: 0
                  margin-left: 10px
                .value
                  margin-left: 0
                  margin-right: 5px
        .product-info_top
          .props
            .props-item
              .props-title
                display: flex
                strong
                  display: block
                  margin-right: 5px
                  //direction: ltr
          .product-info_right
            .buy-block
              .buy-info
                .delivery
                  padding-left: 0
                  padding-right: 12px
            .share-block
              .share-title
                margin-right: 0
                margin-left: 38px
              .share-items
                .share-item
                  margin-right: 0
                  margin-left: 15px
                  &:last-child
                    margin-left: 0
          .product-info_left
            padding-right: 0
            padding-left: 32px
            @media (max-width: 991px)
              padding-left: 0
            .product-info
              padding-left: 0
              padding-right: 32px
              @media (max-width: 767px)
                padding-right: 16px
              .price
                strong
                  margin-right: 0
                  margin-left: 5px
        .product-info_bottom
          .product-bottom_wrapper
            .product-props
              .product-props_item
                padding-right: 0
                padding-left: 6px
            .product-tastes
              //min-width: 330px
              //justify-content: space-between
              .flavours
                padding-right: 0
                padding-left: 24px
      //.props-choices
        .props-choice
          .props-choice_label
            .name
              direction: ltr
      .custom-dropdown
        .custom-dropdown_wrapper
          .current-item
            text-align: right
            &:before,
            &:after
              right: auto
              left: 24px
            &:before
              right: auto
              left: 17px
      .flavours
        .label-flavor_item
          .title
            padding-left: 0
            padding-right: 8px
      .flavors-values
        .flavor-value_item
          .value
            margin-right: 0
            margin-left: 5px
      .buttons
        .thm-btn
          &.icon
            img
              margin-right: 0
              margin-left: 5px
      .editor-wrapper
        .editor-top
          padding-right: 0
          //padding-left: 180px
          @media (max-width: 1199px)
            padding-right: 14px
            padding-left: 14px
          .percentage
            //padding-left: 0
            //padding-right: 12px
            @media (max-width: 1199px)
              padding-right: 0
            //.percentage-wrapper
              margin-left: 0
              margin-right: 20px
          .buttons
            //right: auto
            //left: 0
            flex-direction: row-reverse
            //@media (max-width: 1199px)
              left: auto
              right: 14px
            &.multiple
              .thm-btn
                margin-left: 10px
                margin-right: 0
                //@media (max-width: 1199px)
                  margin-right: 0
                  margin-left: 8px
                &:first-child
                  margin-left: 0
          .coffee-type_icon
            @media (max-width: 1199px)
              margin-left: 0
              margin-right: 20px
      .editor-blocks
        .editor-block_item
          .editor-item_inner
            .tooltip
              right: auto
              left: 8px
      .roast-item
        .roasting-level
          .roasting-level_label
            left: auto
            right: 130px
      .mobile-buttons_wrapper
        //flex-direction: row-reverse
        //.nav-button
          .label
            max-width: 80px
      .steps-wrapper
        .step-item_wrapper
          &.editor-wrapper
            .blend-wrapper
              .blend-wrapper_left
                .blend-sidebar_title
                  .title
                    @media (max-width: 767px)
                      padding-right: 0
                      padding-left: 10px
                .flavors
                  .flavors-wrapper
                    .flavors-chart
                      canvas
                        @media (max-width: 767px)
                          left: auto
                          right: 35px