.blend-wrapper
  position: relative
  display: flex
  justify-content: center
  min-height: 100vh
  padding-top: 124px
  z-index: 1
  @media (max-width: 1700px)
    padding-top: 88px
  .blend-wrapper
    min-height: 10vh
    @media (max-width: 1199px)
      display: block
      padding: 203px 10px 10px
    &.identify
      .blend-wrapper_left
        display: block
      .blend-wrapper_right
        display: none
    &.matches
      .blend-wrapper_left
        display: none
      .blend-wrapper_right
        display: block
        .best-blends
          display: flex
        .create-blend
          display: none
    &.editor
      .blend-wrapper_left
        display: none
      .blend-wrapper_right
        display: block
        .best-blends
          display: none
        .create-blend
          display: flex
  .blend-wrapper_left
    max-width: 510px
    flex: 0 0 510px
    padding: 17px 22px 31px
    background: rgba(37, 37, 37, 0.95)
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.10)
    @media (max-width: 1700px)
      padding-top: 10px
    //@media (max-width: 1700px)
      max-width: 495px
      flex: 0 0 495px
    //@media (max-width: 1700px)
      max-width: 475px
      flex: 0 0 475px
      padding-bottom: 20px
    @media (max-width: 1700px)
      max-width: 430px
      flex: 0 0 430px
      padding-bottom: 0
    @media (max-width: 1199px)
      max-width: 100%
      width: 100%
      border-radius: 0 0 15px 15px
      background: rgba(36,35,35,.8)
      padding: 18px 18px 130px
  .blend-wrapper_right
    flex-grow: 1
    max-width: 890px
    padding-left: 20px
    //@media (max-width: 1700px)
      max-width: 865px
    //@media (max-width: 1700px)
      max-width: 830px
    @media (max-width: 1700px)
      max-width: 755px
    @media (max-width: 1199px)
      max-width: 100%
      border-radius: 0 0 15px 15px
      background: rgba(36,35,35,.8)
      padding: 18px 18px 130px
      display: none
  .coffee-type
    display: flex
    align-items: center
    margin-bottom: 16px
    @media (max-width: 1700px)
      margin-bottom: 10px
    .coffee-type_icon
      max-width: 110px
      flex: 0 0 110px
      padding: 5px 10px
      border-radius: 12px
      background: $orange
      //@media (max-width: 1700px)
        max-width: 106px
        flex: 0 0 106px
      //@media (max-width: 1700px)
        max-width: 102px
        flex: 0 0 102px
      @media (max-width: 1700px)
        max-width: 92px
        flex: 0 0 92px
      @media (max-width: 767px)
        max-width: 80px
        flex: 0 0 80px
        padding-top: 7px
        padding-bottom: 7px
      .icon
        max-width: 78px
        margin-left: auto
        margin-right: auto
        //margin-bottom: 10px
        //@media (max-width: 1700px)
          max-width: 74px
        //@media (max-width: 1700px)
          max-width: 72px
        @media (max-width: 1700px)
          max-width: 56px
          margin-bottom: 2px
        @media (max-width: 767px)
          max-width: 46px
          margin-bottom: 5px
      .name
        color: $white
        text-align: center
        font-size: 19px
        font-weight: 700
        text-transform: uppercase
        //@media (max-width: 1700px)
          font-size: 18px
        @media (max-width: 1700px)
          font-size: 14px
        @media (max-width: 767px)
          font-size: 10px
          line-height: 15px
    .coffee-type_info
      flex-grow: 1
      padding-left: 16px
      .title
        margin-bottom: 8px
        color: $white
        font-size: 31px
        font-weight: 700
        text-transform: capitalize
        //@media (max-width: 1700px)
          font-size: 30px
        //@media (max-width: 1700px)
          font-size: 29px
        @media (max-width: 1700px)
          margin-bottom: 3px
          font-size: 24px
        @media (max-width: 767px)
          margin-bottom: 0
          font-size: 24px
      .subtitle
        color: $white
        font-size: 20px
        text-transform: capitalize
        //@media (max-width: 1700px)
          font-size: 19px
        @media (max-width: 1700px)
          font-size: 16px
        @media (max-width: 767px)
          font-size: 16px
  .blend-sidebar_title
    display: flex
    align-items: center
    margin-bottom: 18px
    @media (max-width: 1700px)
      margin-bottom: 10px
    .title
      max-width: 120px
      flex: 0 0 120px
      color: $white
      font-size: 16px
      font-weight: 700
    hr
      flex-grow: 1
      margin: 0
      border: none
      height: 1px
      background-color: #818181
  .flavors
    margin-bottom: 22px
    @media (max-width: 1700px)
      margin-bottom: 10px
    .flavors-wrapper
      display: flex
      align-items: center
      justify-content: space-between
      .flavors-values
        max-width: 120px
        flex: 0 0 120px
        padding: 25px 22px
        border-radius: 6px
        background: #3B3B3B
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04)
        //@media (max-width: 1700px)
          max-width: 116px
          flex: 0 0 116px
        @media (max-width: 1700px)
          max-width: 112px
          flex: 0 0 112px
          padding: 6px 12px
        @media (max-width: 1199px)
          padding: 17px 14px
          max-width: 98px
          flex: 0 0 98px
      .flavor-value_item
        margin-bottom: 8px
        color: $white
        font-size: 16px
        @media (max-width: 1700px)
          margin-bottom: 5px
          font-size: 14px
        .value
          color: $milk
          font-size: 18px
          font-weight: 700
          text-transform: uppercase
          @media (max-width: 1700px)
            font-size: 15px
        &:last-child
          margin-bottom: 0
      .flavors-chart
        position: relative
        max-width: 300px
        flex: 0 0 300px
        @media (max-width: 1700px)
          max-width: 240px
          flex: 0 0 240px
        @media (max-width: 480px)
          flex: auto
          flex-grow: 1
          padding-left: 10px
          padding-right: 10px
        canvas
          position: absolute
          left: 0
          top: 50%
          transform: translateY(-50%)
          @media (max-width: 480px)
            left: 18px
            top: -120px
            transform: none
    .notes-wrapper
      padding-top: 30px
      padding-bottom: 30px
  .change-preferences
    display: flex
    margin-top: 8px
    @media (max-width: 1700px)
      margin-top: 5px
    //@media (max-width: 1199px)
      justify-content: center
    .change-btn
      display: flex
      align-items: center
      padding: 8px
      border-radius: 8px
      background: #1D1D1D
      color: $white
      font-size: 14px
      @media (max-width: 1700px)
        padding: 5px
      @media (max-width: 1199px)
        background: $black
      img
        max-width: 19px
        flex: 0 0 19px
        margin-right: 5px
.blend-wrapper
  &.matches
    .create-blend_mobile
      display: flex
.create-blend_mobile
  display: none
  @media (max-width: 767px)
    position: relative
    overflow: hidden
    border: 1px solid $white
    border-radius: 10px
    padding-bottom: 290px
    background-color: #e6d0b1
  .bg
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 100%
    display: flex
    align-items: flex-end
    img
      width: 100%
      @media (max-width: 400px)
        height: 101%
  .info
    position: relative
    z-index: 1
    padding: 40px 20px 30px
    color: #333
    font-size: 16px
    font-weight: 700
    letter-spacing: -0.72px
    //@media (max-width: 400px)
      padding-top: 20px
      padding-bottom: 20px
    .title
      margin-bottom: 5px
      color: $green
    .subtitle
      //max-width: 200px
      font-size: 24px
      color: #333
      //margin-bottom: 15px
      margin-bottom: 5px
    .text
      margin-bottom: 15px
    .buttons
      .thm-btn
        border-radius: 50px
        min-width: 124px
        font-size: 14px
        line-height: 26px
.best-blends
  margin-bottom: 27px
  margin-left: -8px
  margin-right: -8px
  padding-top: 16px
  display: flex
  flex-wrap: wrap
  @media (max-width: 1700px)
    margin-bottom: 20px
  @media (max-width: 1199px)
    margin-bottom: 0
    padding-top: 0
  .blend-item
    max-width: 50%
    flex: 0 0 50%
    padding-left: 8px
    padding-right: 8px
    @media (max-width: 767px)
      max-width: 100%
      flex: 0 0 100%
      margin-bottom: 8px
    .blend-item_inner
      border-radius: 10px
      border: 1px solid #3D3D3D
      background: linear-gradient(180deg, #000 0%, #171717 100%)
      backdrop-filter: blur(12px)
      padding: 25px 27px
      height: 100%
      display: flex
      flex-direction: column
      justify-content: space-between
      @media (max-width: 1700px)
        padding: 16px 20px
      @media (max-width: 767px)
        padding: 20px 16px
    .blend-match
      color: #0CAF61
      font-size: 20px
      font-weight: 600
      letter-spacing: -1px
      //@media (max-width: 1700px)
        font-size: 19px
      @media (max-width: 1700px)
        font-size: 17px
      @media (max-width: 767px)
        font-size: 16px
    .blend-title
      margin-bottom: 18px
      color: $white
      font-size: 28px
      font-weight: 700
      //@media (max-width: 1700px)
        font-size: 27px
      @media (max-width: 1700px)
        font-size: 24px
        margin-bottom: 10px
      @media (max-width: 767px)
        margin-bottom: 15px
        font-size: 22px
    .blend-info
      margin-bottom: 17px
      display: flex
      @media (max-width: 1700px)
        margin-bottom: 10px
      @media (max-width: 767px)
        margin-bottom: 7px
      .blend-image
        max-width: 182px
        flex: 0 0 182px
        //@media (max-width: 1700px)
          max-width: 175px
          flex: 0 0 175px
        @media (max-width: 1700px)
          max-width: 150px
          flex: 0 0 150px
        @media (max-width: 991px)
          max-width: 140px
          flex: 0 0 140px
      .blend-desc
        padding-top: 10px
        flex-grow: 1
        padding-left: 26px
        @media (max-width: 1700px)
          padding-top: 0
        @media (max-width: 767px)
          padding-left: 16px
        .flavours
          margin-bottom: 30px
          @media (max-width: 1700px)
            margin-bottom: 15px
          //@media (max-width: 767px)
            margin-bottom: 23px
    .blend-buy
      display: flex
      align-items: center
      justify-content: space-between
      .blend-price
        color: $white
        font-size: 20px
        @media (max-width: 1700px)
          font-size: 18px
        @media (max-width: 767px)
          font-size: 16px
.flavours
  .label-flavor_item
    display: flex
    align-items: center
    margin-bottom: 16px
    @media (max-width: 1700px)
      margin-bottom: 10px
    &:last-child
      margin-bottom: 0
    .icon
      max-width: 22px
      flex: 0 0 22px
      height: 22px
      background-color: $orange
      border-radius: 50%
      padding: 2px
      display: flex
      justify-content: center
      align-items: center
      @media (max-width: 767px)
        max-width: 18px
        flex: 0 0 18px
        height: 18px
      img
        max-width: 12px
        max-height: 12px
        align-self: center
        @media (max-width: 767px)
          max-height: 10px
          max-width: 10px
    .title
      padding-left: 8px
      color: $white
      font-size: 14px
      font-weight: 600
      text-transform: capitalize
      @media (max-width: 767px)
        padding-left: 6px
        font-size: 12px
.flavors-values
  padding: 16px
  border-radius: 8px
  background: #333
  @media (max-width: 1700px)
    padding: 12px
    border-radius: 6px
  @media (max-width: 767px)
    padding: 14px
  &.block
    .flavor-value_item
      display: block
  .flavor-value_item
    margin-bottom: 10px
    display: flex
    align-items: center
    color: $white
    font-size: 15px
    text-transform: capitalize
    @media (max-width: 1700px)
      font-size: 14px
    @media (max-width: 767px)
      margin-bottom: 6px
      font-size: 12px
    &:last-child
      margin-bottom: 0
    .value
      font-weight: 700
      margin-right: 5px
.create-blend
  border-radius: 10px
  border: 1px solid $white
  background: $milk
  backdrop-filter: blur(12px)
  display: flex
  align-items: flex-end
  justify-content: space-between
  padding-right: 38px
  padding-left: 28px
  overflow: hidden
  @media (max-width: 1700px)
    padding-top: 10px
    padding-right: 20px
    padding-left: 20px
  @media (max-width: 767px)
    flex-direction: column
    padding-right: 14px
    padding-left: 14px
    padding-bottom: 210px
  .create-blend_image
    position: absolute
    left: 0
    top: 0
    height: 100%
    @media (max-width: 480px)
      top: auto
      bottom: 0
      height: auto
      width: 100%
    img
      max-width: initial
      height: 100%
      @media (max-width: 480px)
        display: none
        height: auto
        width: 100%
      &.mobile
        display: none
        @media (max-width: 480px)
          display: block

    //max-width: 200px
    //flex: 0 0 200px
    //@media (max-width: 1700px)
      max-width: 170px
      flex: 0 0 170px
    //@media (max-width: 767px)
      flex: auto
  .create-blend_inner
    position: relative
    flex-grow: 1
    padding-right: 23px
    padding-top: 27px
    padding-bottom: 27px
    color: #333
    font-family: $alt-font
    z-index: 2
    @media (max-width: 1700px)
      padding-top: 0
      padding-bottom: 16px
    @media (max-width: 767px)
      padding-right: 0
      padding-top: 14px
      padding-bottom: 0
    @media (max-width: 480px)
      padding-top: 0
      padding-bottom: 20px
    .match
      margin-bottom: 5px
      color: #219653
      font-size: 20px
      font-weight: 700
      //@media (max-width: 1700px)
        font-size: 19px
      @media (max-width: 1700px)
        margin-bottom: 0
        font-size: 17px
    .title
      margin-bottom: 5px
      font-size: 32px
      font-weight: 700
      line-height: 36px
      text-transform: capitalize
      @media (max-width: 1700px)
        margin-bottom: 0
        font-size: 28px
      @media (max-width: 767px)
        font-size: 24px
    .subtitle
      margin-bottom: 26px
      font-size: 20px
      line-height: 32px
      text-transform: capitalize
      //@media (max-width: 1700px)
        font-size: 19px
      @media (max-width: 1700px)
        margin-bottom: 16px
        font-size: 17px
      strong
        font-size: 22px
        color: #219653
        //@media (max-width: 1700px)
          font-size: 21px
        @media (max-width: 1700px)
          font-size: 19px
    //.buttons
      @media (max-width: 1199px)
        padding-left: 70px
      @media (max-width: 767px)
        padding-left: 0
.assistant-open
  .mobile-buttons_wrapper
    border-radius: 15px
    border: 1px solid #FFAE2C
    box-shadow: 0 4px 25px 10px rgba(255,174,44,.37)
.mobile-buttons_wrapper
  display: none
  @media (max-width: 1199px)
    display: flex
    position: absolute
    left: 10px
    right: 10px
    top: 101px
    z-index: 7
    overflow: hidden
    transition: all .2s ease-out
    border-radius: 15px 15px 0 0
    //background: rgba(11,10,10,0.74)
    //backdrop-filter: blur(12px)
    //overflow: hidden
  //&:before
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    display: block
    content: ''
    border: 1px solid #FFAE2C
    border-radius: 15px
    box-shadow: 0 4px 25px 10px rgba(255,174,44,.37)
    transition: all .2s ease-out
    opacity: 0
    visibility: hidden
  .nav-button
    //max-width: 33.33%
    //flex: 0 0 33.33%
    max-width: 50%
    flex: 0 0 50%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 102px
    background-color: rgba(11,10,10,0.74)
    border-left: 1px solid #201F1F
    transition: all .2s ease-out
    &:nth-child(2)
      border-left: none
    &:last-child
      border-left: none
      display: none
    &.active
      background-color: rgba(36,35,35,.8)
      border-left-color: rgba(36,35,35,.8)
      .label
        font-weight: 700
    .label
      margin-top: 10px
      color: $white
      text-align: center
      font-size: 14px
      line-height: 19px
      max-width: 100px
      text-transform: capitalize
.float-buttons
  position: fixed
  bottom: 0
  z-index: 9999
  left: 0
  width: 100%
  padding: 16px
  background: linear-gradient(180deg, rgba(69, 69, 69, 0.80) 0%, rgba(32, 32, 32, 0.80) 100%)
  backdrop-filter: blur(12px)
  display: none
  @media (max-width: 480px)
    display: block
  &.matches
    .buttons
      .thm-btn
        &.editor
          display: block
        &.matches
          display: none
  .buttons
    justify-content: space-around
    .thm-btn
      &.editor
        display: none
      &.simple
        color: $white
.show-notes
  position: absolute
  width: 100%
  bottom: 100px
  display: none
  justify-content: center
  @media (max-width: 480px)
    display: flex
  .show-notes_btn
    display: flex
    align-items: center
    padding: 6px 12px
    border-radius: 12px
    background: #232223
    color: $white
    text-align: center
    font-size: 16px
    .icon
      display: block
      margin-left: 7px




