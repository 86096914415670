.buttons
  &.multiple
    display: flex
    justify-content: center
    align-items: center
    .thm-btn
      margin-left: 12px
      margin-right: 12px
      @media (max-width: 1199px)
        margin-left: 6px
        margin-right: 6px
      @media (max-width: 480px)
        max-width: 162px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
      &.bordered
        border-radius: 8px
        font-size: 20px
        font-weight: 700
        padding-top: 9px
        padding-bottom: 9px
        @media (max-width: 1700px)
          padding-top: 6px
          padding-bottom: 6px
          font-size: 18px
  &.jc-c
    display: flex
    justify-content: center
    align-items: center
  .thm-btn
    display: block
    min-width: 200px
    text-align: center
    padding: 10px 25px
    background-color: $orange
    border-radius: 8px
    color: $white
    font-size: 20px
    font-weight: 700
    transition: all .2s ease-out
    @media (max-width: 1700px)
      padding-top: 7px
      padding-bottom: 7px
      font-size: 18px
    @media (max-width: 767px)
      min-width: 162px
    &.dark
      background-color: rgba(25,255,255,.2)
    &.bordered
      border-radius: 10px
      border: 1px solid $white
      background-color: transparent
      padding-top: 6px
      padding-bottom: 6px
      color: $white
      font-size: 16px
      font-weight: 400
    &.small
      min-width: 80px
      padding: 8px 16px
      font-size: 15px
      line-height: 22px
      border-radius: 20px
      @media (max-width: 1700px)
        padding-top: 5px
        padding-bottom: 5px
        font-size: 14px
      @media (max-width: 767px)
        font-size: 12px
        line-height: 16px
    &.disabled
      background-color: grey
      cursor: not-allowed
      pointer-events: none
    &.grey
      background-color: #3B3B3B
    &.green
      background-color: $green
    &.icon
      min-width: 60px
      display: flex
      align-items: center
      border-radius: 5px
      padding: 0 10px
      height: 22px
      font-size: 12px
      font-weight: 400
      img
        margin-right: 5px
    &.simple
      padding: 0
      background: none
      border: none
      min-width: 10px
      color: $black
