@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@400;600;700&display=swap);
#__next{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
body{padding:0;margin:0;font-family:"Open Sans",sans-serif;color:#27272e}
body img{max-width:100%;display:block}
body *{-webkit-box-sizing:border-box;box-sizing:border-box}
body a{font-family:"Open Sans",sans-serif;text-decoration:none}
body p{font-size:20px;line-height:1.5;color:#27272e;margin-top:0;font-family:"Open Sans",sans-serif}
@media (max-width:480px){
body p{font-size:16px}
}
body p:last-child{margin-bottom:0}
body ol,body ul{padding-left:15px;margin-top:10px;margin-bottom:10px}
body ol:last-child,body ul:last-child{margin-bottom:0}
body ol li,body ul li{margin-bottom:10px;font-size:20px;line-height:1.5;color:#27272e;font-family:"Open Sans",sans-serif}
@media (max-width:480px){
body ol li,body ul li{font-size:16px}
}
body div{outline:0!important}
body div:focus,body div:focus-visible{outline:0!important}
body strong{font-weight:700}
body button{border:none;background:0 0;padding:0;-webkit-box-shadow:none;box-shadow:none;outline:0;cursor:pointer;font-family:"Open Sans",sans-serif}
body .flex{display:-webkit-box;display:-ms-flexbox;display:flex}
body .ai-c{-webkit-box-align:center;-ms-flex-align:center;align-items:center}
body .ai-fe{-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end}
body .jc-sb{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
body .jc-c{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}
body .jc-fe{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}
body .fd-c{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
body .fw-wrap{-ms-flex-wrap:wrap;flex-wrap:wrap}
body input,body textarea{font-family:"Open Sans",sans-serif;outline:0!important}
body h1,body h2,body h3,body h4,body h5,body h6{margin-top:0}
body label{cursor:pointer}
body{background-color:#121010}
body .page-content{position:relative;background-repeat:no-repeat;background-size:cover;background-position:center top;background-attachment:fixed}
body .page-content .lang-switcher{position:fixed;right:0;top:50%;z-index:4;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);border:3px solid #a26128;border-right:none;display:none}
body .page-content .lang-switcher .lang-btn{display:block;padding:10px 20px;color:#fff;text-transform:uppercase;font-weight:700;font-size:18px;background-color:#27272e;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .page-content .lang-switcher .lang-btn.active{-webkit-box-shadow:inset 2px 2px 20px rgba(0,0,0,.8);box-shadow:inset 2px 2px 20px rgba(0,0,0,.8)}
body .page-content .page-header{position:absolute;left:0;top:0;width:100%;background:rgba(0,0,0,.5);-webkit-box-shadow:0 4px 4px 0 rgba(0,0,0,.25);box-shadow:0 4px 4px 0 rgba(0,0,0,.25);-webkit-backdrop-filter:blur(15px);backdrop-filter:blur(15px);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding-top:25px;padding-bottom:30px;z-index:2}
@media (max-width:1700px){
body .page-content .page-header{padding-top:16px;padding-bottom:16px}
}
@media (max-width:1199px){
body .page-content .page-header{background:#191919;-webkit-backdrop-filter:none;backdrop-filter:none;z-index:4}
}
@media (max-width:1700px){
body .page-content .page-header .logo img{max-height:48px}
}
@media (max-width:991px){
body .page-content .page-header .logo img{max-height:44px}
}
body .page-content .steps-progress{position:absolute;left:0;top:116px;width:100%;height:8px;background-color:#585858;overflow:hidden;z-index:2}
@media (max-width:1700px){
body .page-content .steps-progress{top:80px}
}
@media (max-width:991px){
body .page-content .steps-progress{top:76px}
}
body .page-content .steps-progress .steps-progress_body{text-indent:-9999px;position:absolute;left:0;top:0;height:100%;-webkit-transition:all .2s ease-out;transition:all .2s ease-out;background-color:#a26128}
body .steps-wrapper .step-item{position:relative;display:none}
body .steps-wrapper .step-item.active{display:block}
body .steps-wrapper .step-item.assistant-open .step-item_wrapper{z-index:3}
body .steps-wrapper .step-item_wrapper{position:relative;min-height:100vh;padding-top:160px;padding-bottom:160px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
@media (max-width:1700px){
body .steps-wrapper .step-item_wrapper{padding-top:110px;padding-bottom:80px}
}
@media (max-width:991px){
body .steps-wrapper .step-item_wrapper{padding-bottom:132px}
}
body .steps-wrapper .step-item_wrapper.open-coffee-id .step-footer{z-index:1}
body .steps-wrapper .step-item_wrapper.editor-wrapper:before{display:none}
body .steps-wrapper .step-item_wrapper .before{position:absolute;left:0;top:0;bottom:0;right:0;display:block;content:"";opacity:0;visibility:hidden;background:rgba(0,0,0,.72);-webkit-transition:all .2s ease-out;transition:all .2s ease-out;z-index:0}
body .steps-wrapper .step-item_wrapper.blend-wrapper{padding-top:0;padding-bottom:0;display:block}
body .steps-wrapper .step-item_wrapper.pb-100{padding-bottom:100px}
@media (max-width:1700px){
body .steps-wrapper .step-item_wrapper.pb-100{padding-bottom:0}
}
body .steps-wrapper .step-item_wrapper .step-title{margin-bottom:20px;color:#fff;font-size:64px;font-weight:800;text-align:center}
@media (max-width:1700px){
body .steps-wrapper .step-item_wrapper .step-title{margin-bottom:0;font-size:56px}
}
@media (max-width:1440px){
body .steps-wrapper .step-item_wrapper .step-title{margin-bottom:16px;font-size:48px}
}
@media (max-width:1400px){
body .steps-wrapper .step-item_wrapper .step-title{font-size:40px}
}
@media (max-width:1199px){
body .steps-wrapper .step-item_wrapper .step-title{font-size:36px}
}
@media (max-width:991px){
body .steps-wrapper .step-item_wrapper .step-title{margin-bottom:0;font-size:32px;padding-left:5px;padding-right:5px}
}
body .steps-wrapper .step-item_wrapper .step-subtitle{margin-bottom:50px;color:#fff;font-size:28px;text-align:center}
@media (max-width:1700px){
body .steps-wrapper .step-item_wrapper .step-subtitle{margin-bottom:10px;font-size:24px}
}
@media (max-width:1400px){
body .steps-wrapper .step-item_wrapper .step-subtitle{font-size:20px}
}
body .steps-wrapper .step-item_wrapper .step-footer{position:fixed;left:0;bottom:0;width:100%;z-index:5;display:-webkit-box;display:-ms-flexbox;display:flex;padding-top:16px;padding-bottom:16px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;background:-webkit-gradient(linear,left top,left bottom,from(rgba(69,69,69,.6)),to(rgba(32,32,32,.6)));background:linear-gradient(180deg,rgba(69,69,69,.6) 0,rgba(32,32,32,.6) 100%);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px)}
@media (max-width:1700px){
body .steps-wrapper .step-item_wrapper .step-footer{padding-top:10px;padding-bottom:10px}
}
body .steps-wrapper .step-item_wrapper .step-footer .buttons{position:relative;z-index:1}
body .coffee-types_wrapper{width:100%;max-width:940px}
@media (max-width:1700px){
body .coffee-types_wrapper{margin-top:20px}
}
@media (max-width:991px){
body .coffee-types_wrapper{padding-left:27px;padding-right:27px}
}
body .coffee-types{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-left:-10px;margin-right:-10px}
@media (max-width:1400px){
body .coffee-types{margin-left:-8px;margin-right:-8px}
}
@media (max-width:991px){
body .coffee-types{margin-left:-7px;margin-right:-7px}
}
body .coffee-types .coffee-type_item{max-width:320px;-webkit-box-flex:0;-ms-flex:0 0 320px;flex:0 0 320px;padding-left:10px;padding-right:10px;margin-bottom:20px}
@media (max-width:1400px){
body .coffee-types .coffee-type_item{max-width:252px;-webkit-box-flex:0;-ms-flex:0 0 252px;flex:0 0 252px;padding-left:8px;padding-right:8px;margin-bottom:16px}
}
@media (max-width:991px){
body .coffee-types .coffee-type_item{padding-left:7px;padding-right:7px;margin-bottom:14px}
}
@media (max-width:767px){
body .coffee-types .coffee-type_item{max-width:50%;-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%}
}
body .coffee-types .coffee-type_item .coffee-type_inner{display:block}
body .coffee-types .coffee-type_item .coffee-type_inner input{display:none}
body .coffee-types .coffee-type_item .coffee-type_inner input:checked+.value:before{border:1px solid #fff;background:#a26128}
body .coffee-types .coffee-type_item .coffee-type_inner input:checked+.value .title{color:#fff;font-weight:700}
body .coffee-types .coffee-type_item .coffee-type_inner .value{position:relative;padding:23px 20px 20px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:10px;overflow:hidden}
@media (max-width:1400px){
body .coffee-types .coffee-type_item .coffee-type_inner .value{padding-top:19px}
}
@media (max-width:767px){
body .coffee-types .coffee-type_item .coffee-type_inner .value{padding:15px 10px 5px}
}
body .coffee-types .coffee-type_item .coffee-type_inner .value:before{position:absolute;left:0;top:0;width:100%;height:100%;display:block;content:"";border-radius:10px;opacity:.8;background:-webkit-gradient(linear,left top,left bottom,from(#454545),to(#202020));background:linear-gradient(180deg,#454545 0,#202020 100%);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);-webkit-box-sizing:border-box;box-sizing:border-box;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .coffee-types .coffee-type_item .coffee-type_inner .value .icon{position:relative;display:block;margin-bottom:11px;max-width:87px}
@media (max-width:1700px){
body .coffee-types .coffee-type_item .coffee-type_inner .value .icon{max-width:82px}
}
@media (max-width:1440px){
body .coffee-types .coffee-type_item .coffee-type_inner .value .icon{max-width:80px}
}
@media (max-width:1400px){
body .coffee-types .coffee-type_item .coffee-type_inner .value .icon{max-width:68px}
}
@media (max-width:767px){
body .coffee-types .coffee-type_item .coffee-type_inner .value .icon{margin-bottom:5px;max-width:66px}
}
body .coffee-types .coffee-type_item .coffee-type_inner .value .title{display:block;position:relative;text-transform:uppercase;color:#fff;text-align:center;font-size:28px;font-weight:300;letter-spacing:-1px;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
@media (max-width:1680px){
body .coffee-types .coffee-type_item .coffee-type_inner .value .title{font-size:24px}
}
@media (max-width:1400px){
body .coffee-types .coffee-type_item .coffee-type_inner .value .title{font-size:16px}
}
@media (max-width:767px){
body .coffee-types .coffee-type_item .coffee-type_inner .value .title{font-size:22px;line-height:30px}
}
body .range-wrapper{width:100%;max-width:1120px}
@media (max-width:1700px){
body .range-wrapper{max-width:1020px;margin-left:220px}
}
@media (max-width:1199px){
body .range-wrapper{margin-left:0;padding-left:28px;padding-right:28px}
}
body .range-wrapper .range-slider_wrapper{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}
@media (max-width:1199px){
body .range-wrapper .range-slider_wrapper{width:100%;border-radius:12px;background-color:#1b1b1b;padding:22px 7px 19px}
}
body .range-wrapper .range-slider_wrapper.thumb-labels .acidity-1 .range-slider__thumb:after{content:"ללא"}
body .range-wrapper .range-slider_wrapper.thumb-labels .acidity-2 .range-slider__thumb:after{content:"תפוח ירוק"}
body .range-wrapper .range-slider_wrapper.thumb-labels .acidity-3 .range-slider__thumb:after{content:"תפוז"}
body .range-wrapper .range-slider_wrapper.thumb-labels .acidity-4 .range-slider__thumb:after{content:"אשכולית אדומה"}
body .range-wrapper .range-slider_wrapper.thumb-labels .acidity-5 .range-slider__thumb:after{content:"לימונית"}
body .range-wrapper .range-slider_wrapper.thumb-labels .sweetness-1 .range-slider__thumb:after{content:"עדינה"}
body .range-wrapper .range-slider_wrapper.thumb-labels .sweetness-2 .range-slider__thumb:after{content:"קנה סוכר"}
body .range-wrapper .range-slider_wrapper.thumb-labels .sweetness-3 .range-slider__thumb:after{content:"שוקולד חלב"}
body .range-wrapper .range-slider_wrapper.thumb-labels .sweetness-4 .range-slider__thumb:after{content:"קרמל"}
body .range-wrapper .range-slider_wrapper.thumb-labels .sweetness-5 .range-slider__thumb:after{content:"דבש"}
body .range-wrapper .range-slider_wrapper.thumb-labels .bitterness-1 .range-slider__thumb:after{content:"עדינה"}
body .range-wrapper .range-slider_wrapper.thumb-labels .bitterness-2 .range-slider__thumb:after{content:"שקדים"}
body .range-wrapper .range-slider_wrapper.thumb-labels .bitterness-3 .range-slider__thumb:after{content:"שוקולד מריר"}
body .range-wrapper .range-slider_wrapper.thumb-labels .bitterness-4 .range-slider__thumb:after{content:"קקאו"}
body .range-wrapper .range-slider_wrapper.thumb-labels .bitterness-5 .range-slider__thumb:after{content:"תה שחור"}
body .range-wrapper .range-slider_wrapper.thumb-labels .body-1 .range-slider__thumb:after{content:"קליל"}
body .range-wrapper .range-slider_wrapper.thumb-labels .body-2 .range-slider__thumb:after{content:"עדין"}
body .range-wrapper .range-slider_wrapper.thumb-labels .body-3 .range-slider__thumb:after{content:"בינוני"}
body .range-wrapper .range-slider_wrapper.thumb-labels .body-4 .range-slider__thumb:after{content:"מלא ועשיר"}
body .range-wrapper .range-slider_wrapper.thumb-labels .body-5 .range-slider__thumb:after{content:"סמיך"}
body .range-wrapper .range-slider_wrapper.thumb-labels .range-slider__thumb:after{position:absolute;top:110%;left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);display:block;content:"Taste profile";padding:7px 20px 8px;border-radius:8px;font-size:14px;font-weight:600;white-space:nowrap;background-color:rgba(141,141,141,.46);opacity:1}
@media (max-width:1199px){
body .range-wrapper .range-slider_wrapper.thumb-labels .range-slider__thumb:after{top:30px;font-size:13px;padding:4px 10px}
}
body .range-wrapper .range-slider{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;background:rgba(255,255,255,.32)}
@media (max-width:1199px){
body .range-wrapper .range-slider{height:6px;border-radius:0}
}
body .range-wrapper .range-slider .range-slider__thumb[data-disabled]{display:none}
body .range-wrapper .range-slider .range-slider__range{left:0!important;border-radius:4px;background:#a26128;width:auto}
body .range-wrapper .range-slider .range-slider__thumb{width:96px;height:56px;border-radius:20px;border:2px solid #fff;background:#a26128;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;color:#fff;font-size:32px;font-weight:700}
@media (max-width:1199px){
body .range-wrapper .range-slider .range-slider__thumb{width:40px;height:26px;font-size:16px;border-radius:10px}
}
body .range-wrapper .range-item{position:relative;margin-bottom:65px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
@media (max-width:1700px){
body .range-wrapper .range-item{margin-bottom:40px}
}
@media (max-width:1199px){
body .range-wrapper .range-item{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:32px}
}
body .range-wrapper .range-item .tooltip{position:absolute;top:0;left:-264px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip{position:fixed;left:0;width:100%;height:100%;padding-top:0;padding-bottom:0;display:block;opacity:0;visibility:hidden}
}
body .range-wrapper .range-item .tooltip.hover{z-index:2}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip.hover{z-index:6;opacity:1;visibility:visible}
}
body .range-wrapper .range-item .tooltip.hover .tooltip-icon{opacity:1}
body .range-wrapper .range-item .tooltip.hover .tooltip-wrapper{opacity:1;visibility:visible}
body .range-wrapper .range-item .tooltip .tooltip-icon{position:relative;margin-top:20px;max-width:18px;-webkit-box-flex:0;-ms-flex:0 0 18px;flex:0 0 18px;margin-left:50px;cursor:pointer;opacity:.3;-webkit-transition:all .2s ease-out;transition:all .2s ease-out;z-index:2}
body .range-wrapper .range-item .tooltip .tooltip-wrapper{position:relative;max-width:235px;-webkit-box-flex:0;-ms-flex:0 0 235px;flex:0 0 235px;-webkit-box-shadow:0 4px 25px rgba(0,0,0,.15);box-shadow:0 4px 25px rgba(0,0,0,.15);background:rgba(0,0,0,.59);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:10px;padding:28px 11px;opacity:0;visibility:hidden;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper{max-width:100%;width:100%;height:100%;padding-top:48px;background:rgba(0,0,0,.9);border-radius:0}
}
body .range-wrapper .range-item .tooltip .tooltip-wrapper.sweetness{margin-top:-70px}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper.sweetness{margin-top:0}
}
body .range-wrapper .range-item .tooltip .tooltip-wrapper.bitterness{margin-top:-140px}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper.bitterness{margin-top:0}
}
body .range-wrapper .range-item .tooltip .tooltip-wrapper.body{margin-top:-210px}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper.body{margin-top:0}
}
body .range-wrapper .range-item .tooltip .tooltip-wrapper .icon{position:absolute;left:50%;top:-18px;width:36px;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%)}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper .icon{position:relative;top:0;left:auto;-webkit-transform:none;-ms-transform:none;transform:none;margin-bottom:11px}
}
body .range-wrapper .range-item .tooltip .tooltip-wrapper .title{margin-bottom:18px;color:#fff;text-align:center;font-size:32px;line-height:1.3;font-weight:700}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper .desc{margin-bottom:44px}
}
@media (max-width:480px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper .desc{max-width:270px}
}
body .range-wrapper .range-item .tooltip .tooltip-wrapper .desc p{color:#fff;text-align:center;font-size:16px;line-height:1.375}
@media (max-width:480px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper .desc p{font-size:18px}
}
body .range-wrapper .range-item .tooltip .tooltip-wrapper .close-tooltip{display:none}
@media (max-width:1199px){
body .range-wrapper .range-item .tooltip .tooltip-wrapper .close-tooltip{display:block;-webkit-filter:invert(1);filter:invert(1)}
}
body .range-wrapper .range-item .tooltip .tooltip-wrapper .close-tooltip img{width:47px}
body .range-wrapper .range-item .tooltip .tooltip-wrapper .buttons{padding-left:8px;padding-right:8px}
body .range-wrapper .range-item .range-label{position:relative;max-width:195px;-webkit-box-flex:0;-ms-flex:0 0 195px;flex:0 0 195px;border-radius:10px;background:-webkit-gradient(linear,left top,left bottom,from(rgba(69,69,69,.8)),to(rgba(32,32,32,.8)));background:linear-gradient(180deg,rgba(69,69,69,.8) 0,rgba(32,32,32,.8) 100%);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);padding:15px;color:#fff;font-size:20px;font-weight:600;text-align:center}
@media (max-width:1199px){
body .range-wrapper .range-item .range-label{-webkit-box-flex:1;-ms-flex:auto;flex:auto;min-width:120px;margin-bottom:10px;padding:8px}
}
@media (max-width:1199px){
body .range-wrapper .range-item .range-label:before{position:absolute;bottom:-10px;left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);display:block;content:"";border-top:12px solid #222;border-left:10px solid transparent;border-right:10px solid transparent}
}
body .range-wrapper .range-item .range-label .tooltip-icon{position:absolute;left:20px;top:20px;opacity:.3;cursor:pointer;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
@media (max-width:1199px){
body .range-wrapper .range-item .range-label .tooltip-icon{display:none;opacity:1;top:12px;left:-27px}
}
body .range-wrapper .range-item .range-label .tooltip-icon.white{display:none}
@media (max-width:1199px){
body .range-wrapper .range-item .range-label .tooltip-icon.white{display:block}
}
body .range-wrapper .range-item .range-label .tooltip-icon:hover{opacity:1}
body .range-wrapper .range-item:last-child{margin-bottom:0}
body .notes-wrapper{padding-top:20px;padding-bottom:40px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;direction:ltr}
@media (max-width:1700px){
body .notes-wrapper{padding-top:5px}
}
@media (max-width:991px){
body .notes-wrapper{padding-top:0}
}
body .notes-wrapper.small{position:relative;padding-top:20px;padding-bottom:35px}
@media (max-width:1700px){
body .notes-wrapper.small{padding-bottom:15px;padding-top:15px}
}
@media (max-width:1199px){
body .notes-wrapper.small{padding-top:10px}
}
body .notes-wrapper.small .float-flavors{position:absolute;left:0;top:0;width:100%}
@media (max-width:991px){
body .notes-wrapper.small .float-flavors.dynamic{position:relative;left:auto;right:auto;margin-top:20px;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;max-width:240px}
}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors{top:10px;padding-bottom:0;min-width:110px;min-height:84px}
@media (max-width:991px){
body .notes-wrapper.small .float-flavors.dynamic .label-flavors{position:relative;left:auto!important;top:auto!important;right:auto!important;display:block;margin-left:5px;margin-right:5px;margin-bottom:10px;-webkit-box-flex:0;-ms-flex:0 0 110px;flex:0 0 110px}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors.sweet_and_floral{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors.spices_and_roasted{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors.chocolaty_and_nuts{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors.fruity_and_citrus{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors .flavours-title{margin-bottom:0;display:none}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors .label-flavor_item{margin-bottom:0;padding-top:2px;padding-bottom:0}
@media (max-width:991px){
body .notes-wrapper.small .float-flavors.dynamic .label-flavors .label-flavor_item{width:100%}
}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors .label-flavor_item .title{padding-top:0;white-space:nowrap}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors .label-flavor_item .icon{max-width:18px;-webkit-box-flex:0;-ms-flex:0 0 18px;flex:0 0 18px}
body .notes-wrapper.small .float-flavors.dynamic .label-flavors .label-flavor_item .icon img{max-width:18px;max-height:18px}
body .notes-wrapper.small .float-flavors .label-flavors{position:absolute;left:0;top:0;border-radius:8px;-webkit-box-shadow:0 16px 24px 0 rgba(0,0,0,.08);box-shadow:0 16px 24px 0 rgba(0,0,0,.08);background:#3b3b3b;padding:0 0 8px;min-width:130px;opacity:1;visibility:visible;-webkit-transition:all .2s ease-out;transition:all .2s ease-out;overflow:hidden}
@media (max-width:1700px){
body .notes-wrapper.small .float-flavors .label-flavors{min-width:115px}
}
body .notes-wrapper.small .float-flavors .label-flavors.empty{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
body .notes-wrapper.small .float-flavors .label-flavors.empty img{-webkit-filter:grayscale(1);filter:grayscale(1);max-width:24px}
body .notes-wrapper.small .float-flavors .label-flavors:last-child{left:auto;right:0}
body .notes-wrapper.small .float-flavors .label-flavors.fruity_and_citrus{top:110px}
@media (max-width:1199px){
body .notes-wrapper.small .float-flavors .label-flavors.fruity_and_citrus{top:140px}
}
body .notes-wrapper.small .float-flavors .label-flavors.sweet_and_floral{left:auto;right:0}
body .notes-wrapper.small .float-flavors .label-flavors.chocolaty_and_nuts{top:110px;left:auto;right:0}
@media (max-width:1199px){
body .notes-wrapper.small .float-flavors .label-flavors.chocolaty_and_nuts{top:140px}
}
@media (max-width:991px){
body .notes-wrapper.small .float-flavors .label-flavors{display:none}
}
body .notes-wrapper.small .float-flavors .label-flavors .flavours-title{margin-bottom:5px;background-color:#a26128;text-align:center;color:#fff;font-size:13px;font-weight:700;line-height:26px}
body .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:8px;padding-left:8px;padding-right:8px}
@media (max-width:1700px){
body .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item{margin-bottom:0}
}
body .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item:last-child{margin-bottom:0}
body .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item .icon{display:block;max-width:16px;-webkit-box-flex:0;-ms-flex:0 0 16px;flex:0 0 16px}
body .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item .icon img{max-height:16px}
body .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item .title{display:block;padding-left:0;padding-right:8px;color:#fff;padding-top:2px;line-height:24px;font-size:12px;font-weight:600}
body .notes-wrapper.small .notes-wrapper_inner{width:160px;height:160px}
@media (max-width:1199px){
body .notes-wrapper.small .notes-wrapper_inner{width:240px;height:240px}
}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-title{font-weight:600}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value{width:64px;height:64px}
@media (max-width:1199px){
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value{width:104px;height:104px}
}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-bg img{left:auto;right:0}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-icon{bottom:auto;top:8px}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-title{padding-right:0;padding-left:26px;-webkit-transform:rotate(46.444deg);-ms-transform:rotate(46.444deg);transform:rotate(46.444deg)}
@media (max-width:1199px){
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-title{padding-left:40px}
}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-flavors{left:auto;right:-140px}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-bg img{top:auto;bottom:0}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-icon{bottom:auto;top:4px}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-title{padding-right:0;padding-left:22px;padding-top:18px;max-width:60px;-webkit-transform:rotate(46.444deg);-ms-transform:rotate(46.444deg);transform:rotate(46.444deg)}
@media (max-width:1199px){
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-title{padding-left:40px;padding-top:27px;max-width:94px}
}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-flavors{bottom:-80px}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-right .label-bg img{left:auto;right:0;top:auto;bottom:0}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-right .label-title{padding-top:26px;padding-right:0;padding-left:5px;max-width:50px}
@media (max-width:1199px){
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-right .label-title{padding-top:56px;padding-left:21px}
}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-right .label-flavors{left:auto;right:-140px;top:auto;bottom:-80px}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon{width:14px;height:14px;left:7px;bottom:7px}
@media (max-width:1199px){
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon{width:24px;height:24px}
}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon img{max-width:10px}
@media (max-width:1199px){
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon img{max-width:14px}
}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-title{padding-top:12px;padding-right:14px;padding-left:5px;font-size:8px;font-weight:600;letter-spacing:.611px}
@media (max-width:1199px){
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-title{font-size:13px;padding-top:21px;padding-right:30px}
}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-flavors{background:#3b3b3b;padding:16px 8px;top:auto;bottom:-150px;left:-140px;min-width:130px}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-flavors .label-flavor_item{margin-bottom:8px}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-flavors .label-flavor_item:last-child{margin-bottom:0}
body .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-flavors .label-flavor_item .title{font-size:12px;font-weight:600}
body .notes-wrapper.active .notes-wrapper_inner .notes-item .notes-item_label{pointer-events:auto}
body .notes-wrapper .notes-wrapper_inner{position:relative;width:345px;height:345px;border-radius:50%;background:#232223;-webkit-box-shadow:0 4px 53px 0 rgba(0,0,0,.08);box-shadow:0 4px 53px 0 rgba(0,0,0,.08);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
@media (max-width:1199px){
body .notes-wrapper .notes-wrapper_inner{-webkit-box-shadow:0 3px 37px rgba(0,0,0,.08);box-shadow:0 3px 37px rgba(0,0,0,.08)}
}
body .notes-wrapper .notes-wrapper_inner .notes-items{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
body .notes-wrapper .notes-wrapper_inner .notes-item{padding:5px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label{display:block;position:relative}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input{display:none}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-bg img{opacity:0}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-bg img.active{opacity:1}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-icon{background-color:#e6d0b1}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-icon img{opacity:0}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-icon img.active{opacity:1}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-title{color:#fff;font-weight:700}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-flavors{opacity:1;visibility:visible}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label input:checked+.label-value .label-flavors.op-0{opacity:0;visibility:hidden}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value{position:relative;display:block;height:155px;width:155px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-bg img{left:auto;right:0}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-icon{bottom:auto;top:15px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-title{padding-right:0;padding-left:65px;-webkit-transform:rotate(46.444deg);-ms-transform:rotate(46.444deg);transform:rotate(46.444deg)}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-flavors{left:auto;right:-250px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-bg img{top:auto;bottom:0}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-icon{bottom:auto;top:15px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-title{padding-right:0;padding-left:50px;padding-top:50px;-webkit-transform:rotate(46.444deg);-ms-transform:rotate(46.444deg);transform:rotate(46.444deg);max-width:150px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-flavors{top:auto;bottom:-85px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-right .label-bg img{left:auto;right:0;top:auto;bottom:0}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-right .label-title{padding-top:60px;padding-right:6px;max-width:130px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-right .label-flavors{left:auto;right:-250px;top:auto;bottom:-85px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-bg img{position:absolute;left:0;top:0;opacity:1;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-bg img.active{opacity:0}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon{position:absolute;left:15px;bottom:13px;border-radius:50%;width:32px;height:32px;background-color:#a26128;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon img{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);opacity:1;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon img.active{opacity:0}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-title{padding-top:33px;padding-right:50px;display:block;position:relative;-webkit-transform:rotate(-45.327deg);-ms-transform:rotate(-45.327deg);transform:rotate(-45.327deg);color:#a26128;text-align:center;font-size:18px;font-weight:700;letter-spacing:1.361px;text-transform:uppercase;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-flavors{min-width:216px;position:absolute;left:-250px;top:-85px;border-radius:8px;background:#1d1d1d;-webkit-box-shadow:0 16px 24px 0 rgba(0,0,0,.08);box-shadow:0 16px 24px 0 rgba(0,0,0,.08);padding:24px;opacity:0;visibility:hidden;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
@media (max-width:991px){
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-flavors{display:none}
}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-flavors .label-flavor_item{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:16px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-flavors .label-flavor_item:last-child{margin-bottom:0}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-flavors .label-flavor_item .icon{display:block;max-width:18px;-webkit-box-flex:0;-ms-flex:0 0 18px;flex:0 0 18px}
body .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-flavors .label-flavor_item .title{display:block;padding-left:8px;color:#fff;font-size:18px;font-weight:600;line-height:26px}
body .notes-wrapper .notes-wrapper_inner .notes-amount{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);color:#fff;text-align:center;font-size:42px;font-weight:700;line-height:1}
@media (max-width:480px){
body .notes-wrapper .notes-wrapper_inner .notes-amount{top:42%}
}
body .error-note{position:absolute;bottom:140px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:12px;background:rgba(255,133,133,.52);padding:7px 12px 6px;color:#fff;text-align:center;font-size:16px;opacity:0;visibility:hidden;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
@media (max-width:1700px){
body .error-note{bottom:90px}
}
@media (max-width:480px){
body .error-note{bottom:auto;top:410px;padding:0;background:0 0;max-width:130px}
}
body .error-note.show{opacity:1;visibility:visible}
body .error-note .icon{display:block;margin-right:14px}
@media (max-width:480px){
body .error-note .icon{display:none}
}
body .consuming-wrapper{width:100%;max-width:630px}
@media (max-width:1700px){
body .consuming-wrapper{margin-top:50px}
}
@media (max-width:767px){
body .consuming-wrapper{padding-left:28px;padding-right:28px}
}
body .consuming-wrapper .consuming-item{margin-bottom:50px}
@media (max-width:991px){
body .consuming-wrapper .consuming-item{margin-bottom:24px}
}
body .consuming-wrapper .consuming-item:last-child{margin-bottom:0}
body .consuming-wrapper .consuming-label{display:block}
body .consuming-wrapper .consuming-label input{display:none}
body .consuming-wrapper .consuming-label input:checked+.name{background:#a26128;color:#fff}
body .consuming-wrapper .consuming-label .name{display:block;border-radius:10px;background:rgba(255,255,255,.13);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);padding:16px;color:#e6d0b1;text-align:center;font-size:22px;font-weight:600;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .consuming-wrapper .consuming-label .name:hover{color:#fff;background:#a26128}
.buttons.multiple{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.buttons.multiple .thm-btn{margin-left:12px;margin-right:12px}
@media (max-width:1199px){
.buttons.multiple .thm-btn{margin-left:6px;margin-right:6px}
}
@media (max-width:480px){
.buttons.multiple .thm-btn{max-width:162px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}
}
.buttons.multiple .thm-btn.bordered{border-radius:8px;font-size:20px;font-weight:700;padding-top:9px;padding-bottom:9px}
@media (max-width:1700px){
.buttons.multiple .thm-btn.bordered{padding-top:6px;padding-bottom:6px;font-size:18px}
}
.buttons.jc-c{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.buttons .thm-btn{display:block;min-width:200px;text-align:center;padding:10px 25px;background-color:#a26128;border-radius:8px;color:#fff;font-size:20px;font-weight:700;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
@media (max-width:1700px){
.buttons .thm-btn{padding-top:7px;padding-bottom:7px;font-size:18px}
}
@media (max-width:767px){
.buttons .thm-btn{min-width:162px}
}
.buttons .thm-btn.dark{background-color:rgba(25,255,255,.2)}
.buttons .thm-btn.bordered{border-radius:10px;border:1px solid #fff;background-color:transparent;padding-top:6px;padding-bottom:6px;color:#fff;font-size:16px;font-weight:400}
.buttons .thm-btn.small{min-width:80px;padding:8px 16px;font-size:15px;line-height:22px;border-radius:20px}
@media (max-width:1700px){
.buttons .thm-btn.small{padding-top:5px;padding-bottom:5px;font-size:14px}
}
@media (max-width:767px){
.buttons .thm-btn.small{font-size:12px;line-height:16px}
}
.buttons .thm-btn.disabled{background-color:grey;cursor:not-allowed;pointer-events:none}
.buttons .thm-btn.grey{background-color:#3b3b3b}
.buttons .thm-btn.green{background-color:#00944d}
.buttons .thm-btn.icon{min-width:60px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:5px;padding:0 10px;height:22px;font-size:12px;font-weight:400}
.buttons .thm-btn.icon img{margin-right:5px}
.buttons .thm-btn.simple{padding:0;background:0 0;border:none;min-width:10px;color:#09080d}
.loader-block{width:100%;max-width:1270px}
@media (max-width:1199px){
.loader-block{padding-left:28px;padding-right:28px}
}
.loader-wrapper{width:100%;border-radius:12px;background:linear-gradient(103deg,#373737 .56%,#2d2d2d .57%,#262626 42.33%,#202020 100%);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:150px 120px 120px}
@media (max-width:1700px){
.loader-wrapper{padding-top:120px;padding-bottom:90px}
}
@media (max-width:1199px){
.loader-wrapper{padding-left:54px;padding-right:54px}
}
@media (max-width:480px){
.loader-wrapper{padding-top:90px;padding-left:10px;padding-right:10px}
}
.loader-wrapper .loader-icon{margin-bottom:48px;text-align:center}
.loader-wrapper .loader-icon .loader-icon_wrapper{position:relative;padding:10px;margin-bottom:8px}
.loader-wrapper .loader-icon .loader-icon_wrapper .circle-progress_wrapper{position:absolute;left:0;top:0;width:100%;height:100%}
.loader-wrapper .loader-icon .loader-icon_wrapper .loader-icon_inner{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:192px;height:192px;background-color:#191919;border-radius:50%}
@media (max-width:480px){
.loader-wrapper .loader-icon .loader-icon_wrapper .loader-icon_inner{width:162px;height:162px}
}
.loader-wrapper .loader-icon .loader-icon_wrapper .loader-icon_inner img{-ms-flex-item-align:center;align-self:center}
@media (max-width:480px){
.loader-wrapper .loader-icon .loader-icon_wrapper .loader-icon_inner img{max-width:80px}
}
.loader-wrapper .loader-icon .loader-count{color:#fff;text-align:center;font-size:20px;letter-spacing:-1px}
.loader-wrapper .loader-text{color:#e6d0b1;text-align:center;font-size:32px;letter-spacing:-1px}
@media (max-width:991px){
.loader-wrapper .loader-text{font-size:18px}
}
.blend-wrapper{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;min-height:100vh;padding-top:124px;z-index:1}
@media (max-width:1700px){
.blend-wrapper{padding-top:88px}
}
.blend-wrapper .blend-wrapper{min-height:10vh}
@media (max-width:1199px){
.blend-wrapper .blend-wrapper{display:block;padding:203px 10px 10px}
}
.blend-wrapper .blend-wrapper.identify .blend-wrapper_left{display:block}
.blend-wrapper .blend-wrapper.identify .blend-wrapper_right{display:none}
.blend-wrapper .blend-wrapper.matches .blend-wrapper_left{display:none}
.blend-wrapper .blend-wrapper.matches .blend-wrapper_right{display:block}
.blend-wrapper .blend-wrapper.matches .blend-wrapper_right .best-blends{display:-webkit-box;display:-ms-flexbox;display:flex}
.blend-wrapper .blend-wrapper.matches .blend-wrapper_right .create-blend{display:none}
.blend-wrapper .blend-wrapper.editor .blend-wrapper_left{display:none}
.blend-wrapper .blend-wrapper.editor .blend-wrapper_right{display:block}
.blend-wrapper .blend-wrapper.editor .blend-wrapper_right .best-blends{display:none}
.blend-wrapper .blend-wrapper.editor .blend-wrapper_right .create-blend{display:-webkit-box;display:-ms-flexbox;display:flex}
.blend-wrapper .blend-wrapper_left{max-width:510px;-webkit-box-flex:0;-ms-flex:0 0 510px;flex:0 0 510px;padding:17px 22px 31px;background:rgba(37,37,37,.95);-webkit-box-shadow:0 4px 25px 0 rgba(0,0,0,.1);box-shadow:0 4px 25px 0 rgba(0,0,0,.1)}
@media (max-width:1700px){
.blend-wrapper .blend-wrapper_left{padding-top:10px}
}
@media (max-width:1700px){
.blend-wrapper .blend-wrapper_left{max-width:430px;-webkit-box-flex:0;-ms-flex:0 0 430px;flex:0 0 430px;padding-bottom:0}
}
@media (max-width:1199px){
.blend-wrapper .blend-wrapper_left{max-width:100%;width:100%;border-radius:0 0 15px 15px;background:rgba(36,35,35,.8);padding:18px 18px 130px}
}
.blend-wrapper .blend-wrapper_right{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:890px;padding-left:20px}
@media (max-width:1700px){
.blend-wrapper .blend-wrapper_right{max-width:755px}
}
@media (max-width:1199px){
.blend-wrapper .blend-wrapper_right{max-width:100%;border-radius:0 0 15px 15px;background:rgba(36,35,35,.8);padding:18px 18px 130px;display:none}
}
.blend-wrapper .coffee-type{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:16px}
@media (max-width:1700px){
.blend-wrapper .coffee-type{margin-bottom:10px}
}
.blend-wrapper .coffee-type .coffee-type_icon{max-width:110px;-webkit-box-flex:0;-ms-flex:0 0 110px;flex:0 0 110px;padding:5px 10px;border-radius:12px;background:#a26128}
@media (max-width:1700px){
.blend-wrapper .coffee-type .coffee-type_icon{max-width:92px;-webkit-box-flex:0;-ms-flex:0 0 92px;flex:0 0 92px}
}
@media (max-width:767px){
.blend-wrapper .coffee-type .coffee-type_icon{max-width:80px;-webkit-box-flex:0;-ms-flex:0 0 80px;flex:0 0 80px;padding-top:7px;padding-bottom:7px}
}
.blend-wrapper .coffee-type .coffee-type_icon .icon{max-width:78px;margin-left:auto;margin-right:auto}
@media (max-width:1700px){
.blend-wrapper .coffee-type .coffee-type_icon .icon{max-width:56px;margin-bottom:2px}
}
@media (max-width:767px){
.blend-wrapper .coffee-type .coffee-type_icon .icon{max-width:46px;margin-bottom:5px}
}
.blend-wrapper .coffee-type .coffee-type_icon .name{color:#fff;text-align:center;font-size:19px;font-weight:700;text-transform:uppercase}
@media (max-width:1700px){
.blend-wrapper .coffee-type .coffee-type_icon .name{font-size:14px}
}
@media (max-width:767px){
.blend-wrapper .coffee-type .coffee-type_icon .name{font-size:10px;line-height:15px}
}
.blend-wrapper .coffee-type .coffee-type_info{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-left:16px}
.blend-wrapper .coffee-type .coffee-type_info .title{margin-bottom:8px;color:#fff;font-size:31px;font-weight:700;text-transform:capitalize}
@media (max-width:1700px){
.blend-wrapper .coffee-type .coffee-type_info .title{margin-bottom:3px;font-size:24px}
}
@media (max-width:767px){
.blend-wrapper .coffee-type .coffee-type_info .title{margin-bottom:0;font-size:24px}
}
.blend-wrapper .coffee-type .coffee-type_info .subtitle{color:#fff;font-size:20px;text-transform:capitalize}
@media (max-width:1700px){
.blend-wrapper .coffee-type .coffee-type_info .subtitle{font-size:16px}
}
@media (max-width:767px){
.blend-wrapper .coffee-type .coffee-type_info .subtitle{font-size:16px}
}
.blend-wrapper .blend-sidebar_title{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:18px}
@media (max-width:1700px){
.blend-wrapper .blend-sidebar_title{margin-bottom:10px}
}
.blend-wrapper .blend-sidebar_title .title{max-width:120px;-webkit-box-flex:0;-ms-flex:0 0 120px;flex:0 0 120px;color:#fff;font-size:16px;font-weight:700}
.blend-wrapper .blend-sidebar_title hr{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;margin:0;border:none;height:1px;background-color:#818181}
.blend-wrapper .flavors{margin-bottom:22px}
@media (max-width:1700px){
.blend-wrapper .flavors{margin-bottom:10px}
}
.blend-wrapper .flavors .flavors-wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
.blend-wrapper .flavors .flavors-wrapper .flavors-values{max-width:120px;-webkit-box-flex:0;-ms-flex:0 0 120px;flex:0 0 120px;padding:25px 22px;border-radius:6px;background:#3b3b3b;-webkit-box-shadow:0 4px 8px 0 rgba(0,0,0,.04);box-shadow:0 4px 8px 0 rgba(0,0,0,.04)}
@media (max-width:1700px){
.blend-wrapper .flavors .flavors-wrapper .flavors-values{max-width:112px;-webkit-box-flex:0;-ms-flex:0 0 112px;flex:0 0 112px;padding:6px 12px}
}
@media (max-width:1199px){
.blend-wrapper .flavors .flavors-wrapper .flavors-values{padding:17px 14px;max-width:98px;-webkit-box-flex:0;-ms-flex:0 0 98px;flex:0 0 98px}
}
.blend-wrapper .flavors .flavors-wrapper .flavor-value_item{margin-bottom:8px;color:#fff;font-size:16px}
@media (max-width:1700px){
.blend-wrapper .flavors .flavors-wrapper .flavor-value_item{margin-bottom:5px;font-size:14px}
}
.blend-wrapper .flavors .flavors-wrapper .flavor-value_item .value{color:#e6d0b1;font-size:18px;font-weight:700;text-transform:uppercase}
@media (max-width:1700px){
.blend-wrapper .flavors .flavors-wrapper .flavor-value_item .value{font-size:15px}
}
.blend-wrapper .flavors .flavors-wrapper .flavor-value_item:last-child{margin-bottom:0}
.blend-wrapper .flavors .flavors-wrapper .flavors-chart{position:relative;max-width:300px;-webkit-box-flex:0;-ms-flex:0 0 300px;flex:0 0 300px}
@media (max-width:1700px){
.blend-wrapper .flavors .flavors-wrapper .flavors-chart{max-width:240px;-webkit-box-flex:0;-ms-flex:0 0 240px;flex:0 0 240px}
}
@media (max-width:480px){
.blend-wrapper .flavors .flavors-wrapper .flavors-chart{-webkit-box-flex:1;-ms-flex:auto;flex:auto;-ms-flex-positive:1;flex-grow:1;padding-left:10px;padding-right:10px}
}
.blend-wrapper .flavors .flavors-wrapper .flavors-chart canvas{position:absolute;left:0;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%)}
@media (max-width:480px){
.blend-wrapper .flavors .flavors-wrapper .flavors-chart canvas{left:18px;top:-120px;-webkit-transform:none;-ms-transform:none;transform:none}
}
.blend-wrapper .flavors .notes-wrapper{padding-top:30px;padding-bottom:30px}
.blend-wrapper .change-preferences{display:-webkit-box;display:-ms-flexbox;display:flex;margin-top:8px}
@media (max-width:1700px){
.blend-wrapper .change-preferences{margin-top:5px}
}
.blend-wrapper .change-preferences .change-btn{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:8px;border-radius:8px;background:#1d1d1d;color:#fff;font-size:14px}
@media (max-width:1700px){
.blend-wrapper .change-preferences .change-btn{padding:5px}
}
@media (max-width:1199px){
.blend-wrapper .change-preferences .change-btn{background:#09080d}
}
.blend-wrapper .change-preferences .change-btn img{max-width:19px;-webkit-box-flex:0;-ms-flex:0 0 19px;flex:0 0 19px;margin-right:5px}
.blend-wrapper.matches .create-blend_mobile{display:-webkit-box;display:-ms-flexbox;display:flex}
.create-blend_mobile{display:none}
@media (max-width:767px){
.create-blend_mobile{position:relative;overflow:hidden;border:1px solid #fff;border-radius:10px;padding-bottom:290px;background-color:#e6d0b1}
}
.create-blend_mobile .bg{position:absolute;left:0;bottom:0;width:100%;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end}
.create-blend_mobile .bg img{width:100%}
@media (max-width:400px){
.create-blend_mobile .bg img{height:101%}
}
.create-blend_mobile .info{position:relative;z-index:1;padding:40px 20px 30px;color:#333;font-size:16px;font-weight:700;letter-spacing:-.72px}
.create-blend_mobile .info .title{margin-bottom:5px;color:#00944d}
.create-blend_mobile .info .subtitle{font-size:24px;color:#333;margin-bottom:5px}
.create-blend_mobile .info .text{margin-bottom:15px}
.create-blend_mobile .info .buttons .thm-btn{border-radius:50px;min-width:124px;font-size:14px;line-height:26px}
.best-blends{margin-bottom:27px;margin-left:-8px;margin-right:-8px;padding-top:16px;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}
@media (max-width:1700px){
.best-blends{margin-bottom:20px}
}
@media (max-width:1199px){
.best-blends{margin-bottom:0;padding-top:0}
}
.best-blends .blend-item{max-width:50%;-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;padding-left:8px;padding-right:8px}
@media (max-width:767px){
.best-blends .blend-item{max-width:100%;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;margin-bottom:8px}
}
.best-blends .blend-item .blend-item_inner{border-radius:10px;border:1px solid #3d3d3d;background:-webkit-gradient(linear,left top,left bottom,from(#000),to(#171717));background:linear-gradient(180deg,#000 0,#171717 100%);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);padding:25px 27px;height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
@media (max-width:1700px){
.best-blends .blend-item .blend-item_inner{padding:16px 20px}
}
@media (max-width:767px){
.best-blends .blend-item .blend-item_inner{padding:20px 16px}
}
.best-blends .blend-item .blend-match{color:#0caf61;font-size:20px;font-weight:600;letter-spacing:-1px}
@media (max-width:1700px){
.best-blends .blend-item .blend-match{font-size:17px}
}
@media (max-width:767px){
.best-blends .blend-item .blend-match{font-size:16px}
}
.best-blends .blend-item .blend-title{margin-bottom:18px;color:#fff;font-size:28px;font-weight:700}
@media (max-width:1700px){
.best-blends .blend-item .blend-title{font-size:24px;margin-bottom:10px}
}
@media (max-width:767px){
.best-blends .blend-item .blend-title{margin-bottom:15px;font-size:22px}
}
.best-blends .blend-item .blend-info{margin-bottom:17px;display:-webkit-box;display:-ms-flexbox;display:flex}
@media (max-width:1700px){
.best-blends .blend-item .blend-info{margin-bottom:10px}
}
@media (max-width:767px){
.best-blends .blend-item .blend-info{margin-bottom:7px}
}
.best-blends .blend-item .blend-info .blend-image{max-width:182px;-webkit-box-flex:0;-ms-flex:0 0 182px;flex:0 0 182px}
@media (max-width:1700px){
.best-blends .blend-item .blend-info .blend-image{max-width:150px;-webkit-box-flex:0;-ms-flex:0 0 150px;flex:0 0 150px}
}
@media (max-width:991px){
.best-blends .blend-item .blend-info .blend-image{max-width:140px;-webkit-box-flex:0;-ms-flex:0 0 140px;flex:0 0 140px}
}
.best-blends .blend-item .blend-info .blend-desc{padding-top:10px;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-left:26px}
@media (max-width:1700px){
.best-blends .blend-item .blend-info .blend-desc{padding-top:0}
}
@media (max-width:767px){
.best-blends .blend-item .blend-info .blend-desc{padding-left:16px}
}
.best-blends .blend-item .blend-info .blend-desc .flavours{margin-bottom:30px}
@media (max-width:1700px){
.best-blends .blend-item .blend-info .blend-desc .flavours{margin-bottom:15px}
}
.best-blends .blend-item .blend-buy{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
.best-blends .blend-item .blend-buy .blend-price{color:#fff;font-size:20px}
@media (max-width:1700px){
.best-blends .blend-item .blend-buy .blend-price{font-size:18px}
}
@media (max-width:767px){
.best-blends .blend-item .blend-buy .blend-price{font-size:16px}
}
.flavours .label-flavor_item{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:16px}
@media (max-width:1700px){
.flavours .label-flavor_item{margin-bottom:10px}
}
.flavours .label-flavor_item:last-child{margin-bottom:0}
.flavours .label-flavor_item .icon{max-width:22px;-webkit-box-flex:0;-ms-flex:0 0 22px;flex:0 0 22px;height:22px;background-color:#a26128;border-radius:50%;padding:2px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
@media (max-width:767px){
.flavours .label-flavor_item .icon{max-width:18px;-webkit-box-flex:0;-ms-flex:0 0 18px;flex:0 0 18px;height:18px}
}
.flavours .label-flavor_item .icon img{max-width:12px;max-height:12px;-ms-flex-item-align:center;align-self:center}
@media (max-width:767px){
.flavours .label-flavor_item .icon img{max-height:10px;max-width:10px}
}
.flavours .label-flavor_item .title{padding-left:8px;color:#fff;font-size:14px;font-weight:600;text-transform:capitalize}
@media (max-width:767px){
.flavours .label-flavor_item .title{padding-left:6px;font-size:12px}
}
.flavors-values{padding:16px;border-radius:8px;background:#333}
@media (max-width:1700px){
.flavors-values{padding:12px;border-radius:6px}
}
@media (max-width:767px){
.flavors-values{padding:14px}
}
.flavors-values.block .flavor-value_item{display:block}
.flavors-values .flavor-value_item{margin-bottom:10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#fff;font-size:15px;text-transform:capitalize}
@media (max-width:1700px){
.flavors-values .flavor-value_item{font-size:14px}
}
@media (max-width:767px){
.flavors-values .flavor-value_item{margin-bottom:6px;font-size:12px}
}
.flavors-values .flavor-value_item:last-child{margin-bottom:0}
.flavors-values .flavor-value_item .value{font-weight:700;margin-right:5px}
.create-blend{border-radius:10px;border:1px solid #fff;background:#e6d0b1;-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding-right:38px;padding-left:28px;overflow:hidden}
@media (max-width:1700px){
.create-blend{padding-top:10px;padding-right:20px;padding-left:20px}
}
@media (max-width:767px){
.create-blend{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding-right:14px;padding-left:14px;padding-bottom:210px}
}
.create-blend .create-blend_image{position:absolute;left:0;top:0;height:100%}
@media (max-width:480px){
.create-blend .create-blend_image{top:auto;bottom:0;height:auto;width:100%}
}
.create-blend .create-blend_image img{max-width:initial;height:100%}
@media (max-width:480px){
.create-blend .create-blend_image img{display:none;height:auto;width:100%}
}
.create-blend .create-blend_image img.mobile{display:none}
@media (max-width:480px){
.create-blend .create-blend_image img.mobile{display:block}
}
.create-blend .create-blend_inner{position:relative;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-right:23px;padding-top:27px;padding-bottom:27px;color:#333;font-family:"Open Sans",sans-serif;z-index:2}
@media (max-width:1700px){
.create-blend .create-blend_inner{padding-top:0;padding-bottom:16px}
}
@media (max-width:767px){
.create-blend .create-blend_inner{padding-right:0;padding-top:14px;padding-bottom:0}
}
@media (max-width:480px){
.create-blend .create-blend_inner{padding-top:0;padding-bottom:20px}
}
.create-blend .create-blend_inner .match{margin-bottom:5px;color:#219653;font-size:20px;font-weight:700}
@media (max-width:1700px){
.create-blend .create-blend_inner .match{margin-bottom:0;font-size:17px}
}
.create-blend .create-blend_inner .title{margin-bottom:5px;font-size:32px;font-weight:700;line-height:36px;text-transform:capitalize}
@media (max-width:1700px){
.create-blend .create-blend_inner .title{margin-bottom:0;font-size:28px}
}
@media (max-width:767px){
.create-blend .create-blend_inner .title{font-size:24px}
}
.create-blend .create-blend_inner .subtitle{margin-bottom:26px;font-size:20px;line-height:32px;text-transform:capitalize}
@media (max-width:1700px){
.create-blend .create-blend_inner .subtitle{margin-bottom:16px;font-size:17px}
}
.create-blend .create-blend_inner .subtitle strong{font-size:22px;color:#219653}
@media (max-width:1700px){
.create-blend .create-blend_inner .subtitle strong{font-size:19px}
}
.assistant-open .mobile-buttons_wrapper{border-radius:15px;border:1px solid #ffae2c;-webkit-box-shadow:0 4px 25px 10px rgba(255,174,44,.37);box-shadow:0 4px 25px 10px rgba(255,174,44,.37)}
.mobile-buttons_wrapper{display:none}
@media (max-width:1199px){
.mobile-buttons_wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;position:absolute;left:10px;right:10px;top:101px;z-index:7;overflow:hidden;-webkit-transition:all .2s ease-out;transition:all .2s ease-out;border-radius:15px 15px 0 0}
}
.mobile-buttons_wrapper .nav-button{max-width:50%;-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;height:102px;background-color:rgba(11,10,10,.74);border-left:1px solid #201f1f;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
.mobile-buttons_wrapper .nav-button:nth-child(2){border-left:none}
.mobile-buttons_wrapper .nav-button:last-child{border-left:none;display:none}
.mobile-buttons_wrapper .nav-button.active{background-color:rgba(36,35,35,.8);border-left-color:rgba(36,35,35,.8)}
.mobile-buttons_wrapper .nav-button.active .label{font-weight:700}
.mobile-buttons_wrapper .nav-button .label{margin-top:10px;color:#fff;text-align:center;font-size:14px;line-height:19px;max-width:100px;text-transform:capitalize}
.float-buttons{position:fixed;bottom:0;z-index:9999;left:0;width:100%;padding:16px;background:-webkit-gradient(linear,left top,left bottom,from(rgba(69,69,69,.8)),to(rgba(32,32,32,.8)));background:linear-gradient(180deg,rgba(69,69,69,.8) 0,rgba(32,32,32,.8) 100%);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);display:none}
@media (max-width:480px){
.float-buttons{display:block}
}
.float-buttons.matches .buttons .thm-btn.editor{display:block}
.float-buttons.matches .buttons .thm-btn.matches{display:none}
.float-buttons .buttons{-ms-flex-pack:distribute;justify-content:space-around}
.float-buttons .buttons .thm-btn.editor{display:none}
.float-buttons .buttons .thm-btn.simple{color:#fff}
.show-notes{position:absolute;width:100%;bottom:100px;display:none;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}
@media (max-width:480px){
.show-notes{display:-webkit-box;display:-ms-flexbox;display:flex}
}
.show-notes .show-notes_btn{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:6px 12px;border-radius:12px;background:#232223;color:#fff;text-align:center;font-size:16px}
.show-notes .show-notes_btn .icon{display:block;margin-left:7px}
.step-item.assistant-open .blend-assistant .assistant-quote{-webkit-transform:scale(1) translateY(-100%);-ms-transform:scale(1) translateY(-100%);transform:scale(1) translateY(-100%)}
.step-item.assistant-muted .blend-assistant .assistant-quote .mute-assistant:before{opacity:.7}
.step-item.assistant-muted .blend-assistant .assistant-quote .mute-assistant img{opacity:.7}
.step-item.assistant-muted .blend-assistant .assistant-quote .mute-assistant .name{color:#00954d}
.step-item.assistant-has-suggestions .blend-assistant .assistant-photo .image:after{opacity:1}
.blend-assistant{position:fixed;left:7%;bottom:27px;z-index:6}
@media (max-width:480px){
.blend-assistant.b-80{bottom:80px}
}
@media (max-width:1700px){
.blend-assistant{left:27px}
}
@media (max-width:1199px){
.blend-assistant{bottom:27px}
}
.blend-assistant .assistant-photo .image{position:relative;display:block;max-width:90px;height:90px;overflow:hidden;border-radius:50%;-webkit-box-shadow:0 4px 30px 0 rgba(0,0,0,.5);box-shadow:0 4px 30px 0 rgba(0,0,0,.5)}
.blend-assistant .assistant-photo .image:after{position:absolute;left:0;top:0;display:block;content:"";width:100%;height:100%;border-radius:50%;opacity:0;border:5px solid #00954d;-webkit-transition:all .2s ease-out;transition:all .2s ease-out;-webkit-box-sizing:border-box;box-sizing:border-box}
.blend-assistant .assistant-photo .image img{width:100%}
.blend-assistant .assistant-photo .name{display:block;color:#fff;font-size:14px;font-weight:700;text-align:center;text-transform:uppercase}
.blend-assistant .assistant-quote{width:334px;position:absolute;left:0;top:-4px;-webkit-transform:scale(0) translateY(-100%);-ms-transform:scale(0) translateY(-100%);transform:scale(0) translateY(-100%);-webkit-transform-origin:0 0;-ms-transform-origin:0 0;transform-origin:0 0;padding-bottom:17px;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
.blend-assistant .assistant-quote .icon{position:absolute;bottom:1px;left:30px}
.blend-assistant .assistant-quote .quote-text{position:relative;padding:10px 22px 22px 30px;border-radius:20px;background:#fff}
.blend-assistant .assistant-quote .quote-text p{color:rgba(11,11,11,.7);font-size:16px;font-style:normal;font-weight:500;line-height:25px;margin-bottom:20px}
.blend-assistant .assistant-quote .quote-text p span strong{display:inline;margin-bottom:0;font-size:16px}
.blend-assistant .assistant-quote .quote-text p strong{display:block;margin-bottom:8px;font-size:22px}
.blend-assistant .assistant-quote .quote-text p strong.green{display:inline;margin-bottom:0;font-size:16px;color:#00944d}
.blend-assistant .assistant-quote .quote-text p strong.brown{display:inline;margin-bottom:0;font-size:16px;color:#a26128}
.blend-assistant .assistant-quote .quote-text .buttons{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}
.blend-assistant .assistant-quote .quote-text .buttons.hidden{display:none!important}
.blend-assistant .assistant-quote .quote-text .buttons .thm-btn{margin-right:0;margin-left:16px;min-width:10px;font-size:12px;font-weight:400;padding-left:18px;padding-right:18px}
.blend-assistant .assistant-quote .quote-text .buttons .thm-btn.simple{padding-left:0;padding-right:0}
.blend-assistant .assistant-quote .quote-text .close-quote{position:absolute;top:10px;right:13px;z-index:1}
.blend-assistant .assistant-quote .mute-assistant{position:absolute;left:12px;bottom:25px;width:32px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.blend-assistant .assistant-quote .mute-assistant:before{position:absolute;left:0;top:10px;display:block;content:"";width:31px;height:3px;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);background-color:#3f3f3f;opacity:0;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
.blend-assistant .assistant-quote .mute-assistant img{-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
.blend-assistant .assistant-quote .mute-assistant .name{color:#720d0f;font-size:12px;line-height:1;text-transform:uppercase;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .page-content.lang-he{direction:rtl;text-align:right}
body .page-content.lang-he .steps-progress .steps-progress_body{left:auto;right:0}
@media (max-width:1700px){
body .page-content.lang-he .range-wrapper{margin-left:0;margin-right:220px}
}
@media (max-width:1199px){
body .page-content.lang-he .range-wrapper{margin-right:0}
}
body .page-content.lang-he .range-wrapper .range-item .tooltip{left:auto;right:-264px}
@media (max-width:1199px){
body .page-content.lang-he .range-wrapper .range-item .tooltip{right:0}
}
body .page-content.lang-he .range-wrapper .range-item .range-label .tooltip-icon{left:auto;right:20px}
@media (max-width:1199px){
body .page-content.lang-he .range-wrapper .range-item .range-label .tooltip-icon{left:auto;right:-27px}
}
body .page-content.lang-he .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-flavors .label-flavor_item{-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse}
body .page-content.lang-he .notes-wrapper .notes-wrapper_inner .notes-item .notes-item_label .label-flavors .label-flavor_item .title{padding-left:0;padding-right:8px}
body .page-content.lang-he .error-note .icon{margin-right:0;margin-left:14px}
body .page-content.lang-he .blend-wrapper .blend-wrapper_right{padding-left:0;padding-right:20px}
@media (max-width:1440px){
body .page-content.lang-he .blend-wrapper .blend-wrapper_right{padding-left:20px}
}
@media (max-width:1199px){
body .page-content.lang-he .blend-wrapper .blend-wrapper_right{padding-right:18px;padding-left:18px}
}
body .page-content.lang-he .blend-wrapper .coffee-type .coffee-type_info{padding-left:0;padding-right:16px}
@media (max-width:480px){
body .page-content.lang-he .blend-wrapper .flavors .flavors-wrapper .flavors-chart canvas{left:auto;right:18px}
}
body .page-content.lang-he .blend-wrapper .change-preferences .change-btn img{margin-right:0;margin-left:5px}
body .page-content.lang-he .blend-assistant{left:auto;right:7%}
@media (max-width:1700px){
body .page-content.lang-he .blend-assistant{left:auto;right:27px}
}
body .page-content.lang-he .blend-assistant .assistant-quote{left:auto;right:0;-webkit-transform-origin:right 0;-ms-transform-origin:right 0;transform-origin:right 0}
body .page-content.lang-he .blend-assistant .assistant-quote .quote-text .close-quote{right:auto;left:13px}
body .page-content.lang-he .blend-assistant .assistant-quote .icon{left:auto;right:30px;-webkit-transform:scale(-1,1);-ms-transform:scale(-1,1);transform:scale(-1,1)}
body .page-content.lang-he .best-blends .blend-item .blend-info .blend-desc{padding-left:0;padding-right:20px}
@media (max-width:767px){
body .page-content.lang-he .best-blends .blend-item .blend-info .blend-desc{padding-left:0;padding-right:16px}
}
body .page-content.lang-he .best-blends .blend-item .blend-info .blend-desc .flavours .label-flavor_item .title{padding-left:0;padding-right:8px}
@media (max-width:767px){
body .page-content.lang-he .best-blends .blend-item .blend-info .blend-desc .flavours .label-flavor_item .title{padding-left:0;padding-right:6px}
}
body .page-content.lang-he .best-blends .blend-item .blend-info .blend-desc .flavors-values .flavor-value_item .value{margin-right:0;margin-left:5px}
body .page-content.lang-he .create-blend{padding-right:24px}
@media (max-width:1700px){
body .page-content.lang-he .create-blend{padding-right:20px}
}
@media (max-width:767px){
body .page-content.lang-he .create-blend{padding-right:14px;padding-left:14px}
}
body .page-content.lang-he .create-blend .create-blend_inner{padding-right:0}
body .page-content.lang-he .modal .modal-dialog .modal-content .close-modal{right:auto;left:-60px}
@media (max-width:1400px){
body .page-content.lang-he .modal .modal-dialog .modal-content .close-modal{right:auto;left:0}
}
body .page-content.lang-he .product-popup.roast-me-popup .product-info_top .product-info_left .product-info{padding-left:10px;padding-right:10px}
body .page-content.lang-he .product-popup.roast-me-popup .product-info_top .product-info_wrapper{padding-right:0;padding-left:30px}
body .page-content.lang-he .product-popup.roast-me-popup .product-info_top .product-info_wrapper .edit-icon{right:auto;left:0}
body .page-content.lang-he .product-popup.roast-me-popup .product-info_top .product-info_wrapper .product-info_desc{padding-left:0;padding-right:20px}
@media (max-width:767px){
body .page-content.lang-he .product-popup.roast-me-popup .product-info_top .product-info_wrapper .product-info_desc{padding-right:0}
}
body .page-content.lang-he .product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .product-item_title span{padding-right:0;padding-left:20px;min-width:130px}
body .page-content.lang-he .product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label{left:auto;right:338px}
@media (max-width:1199px){
body .page-content.lang-he .product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label{left:auto;right:308px}
}
@media (max-width:767px){
body .page-content.lang-he .product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label{left:auto;right:0}
}
body .page-content.lang-he .product-popup.roast-me-popup .product-info_bottom .coffee-items .coffee-item{margin-right:0;margin-left:16px}
@media (max-width:1700px){
body .page-content.lang-he .product-popup.roast-me-popup .product-info_bottom .coffee-items .coffee-item{margin-right:0;margin-left:10px}
}
body .page-content.lang-he .product-popup.roast-me-popup .product-info_bottom .coffee-items .coffee-item .value{margin-left:0;margin-right:5px}
body .page-content.lang-he .product-popup .product-info_top .props .props-item .props-title{display:-webkit-box;display:-ms-flexbox;display:flex}
body .page-content.lang-he .product-popup .product-info_top .props .props-item .props-title strong{display:block;margin-right:5px}
body .page-content.lang-he .product-popup .product-info_top .product-info_right .buy-block .buy-info .delivery{padding-left:0;padding-right:12px}
body .page-content.lang-he .product-popup .product-info_top .product-info_right .share-block .share-title{margin-right:0;margin-left:38px}
body .page-content.lang-he .product-popup .product-info_top .product-info_right .share-block .share-items .share-item{margin-right:0;margin-left:15px}
body .page-content.lang-he .product-popup .product-info_top .product-info_right .share-block .share-items .share-item:last-child{margin-left:0}
body .page-content.lang-he .product-popup .product-info_top .product-info_left{padding-right:0;padding-left:32px}
@media (max-width:991px){
body .page-content.lang-he .product-popup .product-info_top .product-info_left{padding-left:0}
}
body .page-content.lang-he .product-popup .product-info_top .product-info_left .product-info{padding-left:0;padding-right:32px}
@media (max-width:767px){
body .page-content.lang-he .product-popup .product-info_top .product-info_left .product-info{padding-right:16px}
}
body .page-content.lang-he .product-popup .product-info_top .product-info_left .product-info .price strong{margin-right:0;margin-left:5px}
body .page-content.lang-he .product-popup .product-info_bottom .product-bottom_wrapper .product-props .product-props_item{padding-right:0;padding-left:6px}
body .page-content.lang-he .product-popup .product-info_bottom .product-bottom_wrapper .product-tastes .flavours{padding-right:0;padding-left:24px}
body .page-content.lang-he .custom-dropdown .custom-dropdown_wrapper .current-item{text-align:right}
body .page-content.lang-he .custom-dropdown .custom-dropdown_wrapper .current-item:after,body .page-content.lang-he .custom-dropdown .custom-dropdown_wrapper .current-item:before{right:auto;left:24px}
body .page-content.lang-he .custom-dropdown .custom-dropdown_wrapper .current-item:before{right:auto;left:17px}
body .page-content.lang-he .flavours .label-flavor_item .title{padding-left:0;padding-right:8px}
body .page-content.lang-he .flavors-values .flavor-value_item .value{margin-right:0;margin-left:5px}
body .page-content.lang-he .buttons .thm-btn.icon img{margin-right:0;margin-left:5px}
body .page-content.lang-he .editor-wrapper .editor-top{padding-right:0}
@media (max-width:1199px){
body .page-content.lang-he .editor-wrapper .editor-top{padding-right:14px;padding-left:14px}
}
@media (max-width:1199px){
body .page-content.lang-he .editor-wrapper .editor-top .percentage{padding-right:0}
}
body .page-content.lang-he .editor-wrapper .editor-top .buttons{-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse}
body .page-content.lang-he .editor-wrapper .editor-top .buttons.multiple .thm-btn{margin-left:10px;margin-right:0}
body .page-content.lang-he .editor-wrapper .editor-top .buttons.multiple .thm-btn:first-child{margin-left:0}
@media (max-width:1199px){
body .page-content.lang-he .editor-wrapper .editor-top .coffee-type_icon{margin-left:0;margin-right:20px}
}
body .page-content.lang-he .editor-blocks .editor-block_item .editor-item_inner .tooltip{right:auto;left:8px}
body .page-content.lang-he .roast-item .roasting-level .roasting-level_label{left:auto;right:130px}
@media (max-width:767px){
body .page-content.lang-he .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .blend-sidebar_title .title{padding-right:0;padding-left:10px}
}
@media (max-width:767px){
body .page-content.lang-he .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .flavors .flavors-wrapper .flavors-chart canvas{left:auto;right:35px}
}
.modal{position:fixed;left:0;top:0;width:100%;height:100%;opacity:0;visibility:hidden;z-index:-1;background:rgba(0,0,0,.5);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);overflow-y:auto}
.modal.open{opacity:1;visibility:visible;z-index:9999}
.modal .modal-dialog{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding-top:54px;padding-bottom:54px}
@media (max-width:1700px){
.modal .modal-dialog{padding-top:20px;padding-bottom:5px}
}
@media (max-width:1199px){
.modal .modal-dialog{padding:40px 12px 20px}
}
.modal .modal-dialog .modal-content{position:relative;width:100%;max-width:1200px}
.modal .modal-dialog .modal-content .close-modal{position:absolute;top:0;right:-60px}
@media (max-width:1400px){
.modal .modal-dialog .modal-content .close-modal{right:0;top:-40px}
}
@media (max-width:1199px){
.modal .modal-dialog .modal-content .close-modal{top:-35px}
}
.modal .modal-dialog .modal-content .close-modal img{width:47px;height:47px;-webkit-filter:invert(1);filter:invert(1)}
@media (max-width:1400px){
.modal .modal-dialog .modal-content .close-modal img{width:32px;height:32px}
}
.modal .modal-dialog .modal-content .modal-content_inner{padding:32px;border-radius:10px;background:-webkit-gradient(linear,left top,left bottom,from(#454545),to(#202020));background:linear-gradient(180deg,#454545 0,#202020 100%);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px)}
@media (max-width:1700px){
.modal .modal-dialog .modal-content .modal-content_inner{padding:10px}
}
@media (max-width:1199px){
.modal .modal-dialog .modal-content .modal-content_inner{padding:16px 12px}
}
.modal .modal-dialog .modal-content .modal-title{margin-top:20px;margin-bottom:20px;color:#fff;text-align:center;font-size:32px;font-weight:800}
.float-flavors_wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;max-width:332px;margin-left:auto;margin-right:auto}
.float-flavors_wrapper .float-flavors{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-left:-8px;margin-right:-8px;-ms-flex-wrap:wrap;flex-wrap:wrap}
.float-flavors_wrapper .float-flavors .label-flavors{max-width:50%;-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;padding-left:8px;padding-right:8px;margin-bottom:16px}
.float-flavors_wrapper .float-flavors .label-flavors .label-flavors_inner{padding:7px 13px;border-radius:8px;background:#232323;-webkit-box-shadow:0 16px 24px 0 rgba(0,0,0,.08);box-shadow:0 16px 24px 0 rgba(0,0,0,.08)}
.float-flavors_wrapper .float-flavors .label-flavors .flavours-title{margin-bottom:10px;color:#e6d0b1;font-size:16px;font-weight:700;text-transform:uppercase}
.float-flavors_wrapper .float-flavors .label-flavors .label-flavor_item{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:5px}
.float-flavors_wrapper .float-flavors .label-flavors .label-flavor_item .icon{margin-left:8px;max-width:18px;-webkit-box-flex:0;-ms-flex:0 0 18px;flex:0 0 18px;display:block}
.float-flavors_wrapper .float-flavors .label-flavors .label-flavor_item .title{display:block;color:#fff;font-size:12px;font-weight:600;line-height:26px}
@media (max-width:1700px){
.product-popup.roast-me-popup .blend-wrapper .flavors{margin-bottom:0}
}
@media (max-width:1700px){
.product-popup.roast-me-popup .blend-wrapper .flavors .flavors-wrapper .flavors-chart{max-width:280px;-webkit-box-flex:0;-ms-flex:0 0 280px;flex:0 0 280px}
}
@media (max-width:480px){
.product-popup.roast-me-popup .blend-wrapper .flavors .flavors-wrapper .flavors-chart{-webkit-box-flex:1;-ms-flex:auto;flex:auto;-ms-flex-positive:1;flex-grow:1}
}
.product-popup.roast-me-popup .product-info_top{margin-bottom:15px}
@media (max-width:1700px){
.product-popup.roast-me-popup .product-info_top{margin-bottom:10px}
}
.product-popup.roast-me-popup .product-info_top .product-info_left .product-image{max-width:162px;-webkit-box-flex:0;-ms-flex:0 0 162px;flex:0 0 162px}
@media (max-width:1700px){
.product-popup.roast-me-popup .product-info_top .product-info_left .product-image{max-width:140px;-webkit-box-flex:0;-ms-flex:0 0 140px;flex:0 0 140px}
}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_top .product-info_left .product-image{max-width:130px;-webkit-box-flex:0;-ms-flex:0 0 130px;flex:0 0 130px}
}
.product-popup.roast-me-popup .product-info_top .product-info_left .product-info{padding-left:10px;padding-right:10px;max-width:600px}
.product-popup.roast-me-popup .product-info_top .product-info_left .product-info .title{width:100%;margin-bottom:10px;background:0 0;padding-top:0;padding-bottom:0;border:none;border-bottom:2px solid #fff}
.product-popup.roast-me-popup .product-info_top .product-info_left .product-info .title:disabled{border-bottom:2px solid transparent}
.product-popup.roast-me-popup .product-info_top .product-info_left .product-info .price{margin-bottom:0}
.product-popup.roast-me-popup .product-info_top .product-info_wrapper{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;margin-bottom:14px;padding-right:30px}
@media (max-width:1700px){
.product-popup.roast-me-popup .product-info_top .product-info_wrapper{margin-bottom:10px}
}
.product-popup.roast-me-popup .product-info_top .product-info_wrapper .edit-icon{position:absolute;right:0;top:6px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.product-popup.roast-me-popup .product-info_top .product-info_wrapper .edit-icon .label{margin-top:2px;display:block;color:#fff;text-align:center;font-size:12px;line-height:20px;font-weight:400;text-transform:capitalize}
.product-popup.roast-me-popup .product-info_top .product-info_wrapper .coffee-type_icon{max-width:74px;-webkit-box-flex:0;-ms-flex:0 0 74px;flex:0 0 74px;padding:8px 5px;border-radius:12px;background:#a26128}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_top .product-info_wrapper .coffee-type_icon{display:none}
}
.product-popup.roast-me-popup .product-info_top .product-info_wrapper .coffee-type_icon .icon{max-width:42px;margin-left:auto;margin-right:auto;margin-bottom:7px}
.product-popup.roast-me-popup .product-info_top .product-info_wrapper .coffee-type_icon .name{color:#fff;text-align:center;font-size:10px;font-weight:700;text-transform:uppercase}
.product-popup.roast-me-popup .product-info_top .product-info_wrapper .product-info_desc{padding-left:20px;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_top .product-info_wrapper .product-info_desc{padding-left:0}
}
.product-popup.roast-me-popup .product-info_top .props{padding-bottom:0;margin-bottom:0;border-bottom:none}
.product-popup.roast-me-popup .product-info_bottom{padding:30px 55px}
@media (max-width:1700px){
.product-popup.roast-me-popup .product-info_bottom{padding:20px 40px}
}
@media (max-width:1199px){
.product-popup.roast-me-popup .product-info_bottom{padding-left:25px;padding-right:25px;padding-bottom:0}
}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;margin-left:-30px;margin-right:-30px}
@media (max-width:1199px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper{padding-top:0;border-top:none;margin-left:-10px;margin-right:-10px}
}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .range-slider{max-width:330px}
@media (max-width:1199px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .range-slider{max-width:300px}
}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .range-slider{max-width:100%}
}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .roasting-level{padding-bottom:0}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .roasting-level{margin-bottom:20px}
}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label{left:338px;bottom:auto;top:4px;-webkit-transform:none;-ms-transform:none;transform:none;padding:0;border-radius:0;background:0 0;color:#e6d0b1;font-size:11px}
@media (max-width:1199px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label{left:308px}
}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label{left:0;top:auto;bottom:0;padding:3px 10px;font-size:10px;border-radius:10px;background:rgba(162,97,40,.45);color:#fff}
}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item{max-width:50%;-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%;padding-left:30px;padding-right:30px}
@media (max-width:1199px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item{padding-right:10px;padding-left:10px}
}
@media (max-width:991px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item{max-width:100%;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%}
}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .product-item_title{position:relative;margin-bottom:10px}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .product-item_title span{position:relative;padding-right:20px;min-width:160px;display:inline-block;color:#fff;font-size:16px;line-height:21px;font-weight:700;text-transform:uppercase;background-color:#1f1f1f}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .product-item_title span{font-size:14px;min-width:10px}
}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .product-item_title:before{position:absolute;left:0;top:10px;display:block;content:"";width:100%;height:1px;background-color:#818181}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .roasting-level_wrapper{position:relative;min-height:80px}
@media (max-width:1700px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .roasting-level_wrapper{min-height:60px}
}
@media (max-width:1199px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .roasting-level_wrapper{min-height:70px}
}
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .notes-wrapper{max-width:420px}
@media (max-width:991px){
.product-popup.roast-me-popup .product-info_bottom .product-bottom_wrapper .product-bottom_item .notes-wrapper{max-width:100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
}
.product-popup.roast-me-popup .product-info_bottom .coffee-items{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;min-height:80px;-ms-flex-wrap:wrap;flex-wrap:wrap}
@media (max-width:1700px){
.product-popup.roast-me-popup .product-info_bottom .coffee-items{min-height:60px}
}
@media (max-width:1199px){
.product-popup.roast-me-popup .product-info_bottom .coffee-items{min-height:10px}
}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .coffee-items{margin-bottom:24px}
}
.product-popup.roast-me-popup .product-info_bottom .coffee-items .coffee-item{margin-right:16px;margin-bottom:16px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#e6d0b1;font-size:18px;font-weight:700;text-transform:capitalize}
@media (max-width:1700px){
.product-popup.roast-me-popup .product-info_bottom .coffee-items .coffee-item{margin-right:10px;margin-bottom:10px}
}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .coffee-items .coffee-item{font-size:12px}
}
.product-popup.roast-me-popup .product-info_bottom .coffee-items .coffee-item .value{margin-left:5px;color:#fff;font-weight:400}
.product-popup.roast-me-popup .product-info_bottom .blend-wrapper{position:relative;display:block;min-height:10px;padding-top:0}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .blend-wrapper .flavors .flavors-wrapper .flavors-values{max-width:85px;-webkit-box-flex:0;-ms-flex:0 0 85px;flex:0 0 85px}
}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .blend-wrapper .flavors .flavors-wrapper .flavor-value_item{font-size:11px}
}
@media (max-width:767px){
.product-popup.roast-me-popup .product-info_bottom .blend-wrapper .flavors .flavors-wrapper .flavor-value_item .value{font-size:12px}
}
.product-popup .product-info_top{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;margin-bottom:10px;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
@media (max-width:1199px){
.product-popup .product-info_top{margin-bottom:24px}
}
@media (max-width:991px){
.product-popup .product-info_top{display:block}
}
.product-popup .product-info_top .product-info_left{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-width:772px;padding-right:32px;display:-webkit-box;display:-ms-flexbox;display:flex}
@media (max-width:991px){
.product-popup .product-info_top .product-info_left{padding-right:0;margin-bottom:24px}
}
.product-popup .product-info_top .product-info_left .product-image{max-width:245px;-webkit-box-flex:0;-ms-flex:0 0 245px;flex:0 0 245px}
@media (max-width:1700px){
.product-popup .product-info_top .product-info_left .product-image{max-width:180px;-webkit-box-flex:0;-ms-flex:0 0 180px;flex:0 0 180px}
}
@media (max-width:767px){
.product-popup .product-info_top .product-info_left .product-image{max-width:130px;-webkit-box-flex:0;-ms-flex:0 0 130px;flex:0 0 130px}
}
.product-popup .product-info_top .product-info_left .product-info{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-left:32px;max-width:495px}
@media (max-width:767px){
.product-popup .product-info_top .product-info_left .product-info{padding-left:16px}
}
.product-popup .product-info_top .product-info_left .product-info .title{margin-bottom:16px;color:#fff;font-size:26px;font-weight:800;text-transform:uppercase}
@media (max-width:1700px){
.product-popup .product-info_top .product-info_left .product-info .title{margin-bottom:5px}
}
@media (max-width:767px){
.product-popup .product-info_top .product-info_left .product-info .title{font-size:16px}
}
.product-popup .product-info_top .product-info_left .product-info .price{margin-bottom:16px;color:#fff;font-size:12px;line-height:22px}
@media (max-width:1700px){
.product-popup .product-info_top .product-info_left .product-info .price{margin-bottom:10px}
}
@media (max-width:767px){
.product-popup .product-info_top .product-info_left .product-info .price{font-size:11px}
}
.product-popup .product-info_top .product-info_left .product-info .price strong{margin-right:5px;font-size:20px}
@media (max-width:767px){
.product-popup .product-info_top .product-info_left .product-info .price strong{font-size:14px}
}
.product-popup .product-info_top .product-info_left .product-info .about p{color:#fff;font-size:14px;line-height:19px}
@media (max-width:767px){
.product-popup .product-info_top .product-info_left .product-info .about p{font-size:12px}
}
.product-popup .product-info_top .product-info_right{max-width:312px;-webkit-box-flex:0;-ms-flex:0 0 312px;flex:0 0 312px}
@media (max-width:767px){
.product-popup .product-info_top .product-info_right{max-width:100%}
}
.product-popup .product-info_top .product-info_right .share-block{margin-top:10px;border-radius:12px;background:#1f1f1f;padding:12px 20px;display:none;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
@media (max-width:767px){
.product-popup .product-info_top .product-info_right .share-block{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
}
.product-popup .product-info_top .product-info_right .share-block .share-title{margin-right:38px;color:#fff;font-size:18px;font-weight:400;white-space:nowrap}
.product-popup .product-info_top .product-info_right .share-block .share-items{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.product-popup .product-info_top .product-info_right .share-block .share-items .share-item{margin-right:15px}
@media (max-width:1700px){
.product-popup .product-info_top .product-info_right .share-block .share-items .share-item{margin-left:10px;max-width:36px}
}
.product-popup .product-info_top .product-info_right .share-block .share-items .share-item:last-child{margin-right:0}
.product-popup .product-info_top .product-info_right .share-block .share-items .share-item .share-item_link{display:block}
.product-popup .product-info_top .product-info_right .buy-block{padding:32px;border-radius:12px;background:#141414}
@media (max-width:1700px){
.product-popup .product-info_top .product-info_right .buy-block{padding:20px;border-radius:10px}
}
.product-popup .product-info_top .product-info_right .buy-block .buy-info{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:16px}
.product-popup .product-info_top .product-info_right .buy-block .buy-info .quantity{max-width:82px;-webkit-box-flex:0;-ms-flex:0 0 82px;flex:0 0 82px}
.product-popup .product-info_top .product-info_right .buy-block .buy-info .delivery{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-left:12px}
.product-popup .product-info_top .product-info_right .buy-block .buy-info .delivery .stock{margin-bottom:8px;color:#ffe175;font-size:10px;font-weight:700;text-transform:uppercase}
.product-popup .product-info_top .product-info_right .buy-block .buy-info .delivery .delivery-info{color:#fff;font-size:14px;text-transform:uppercase;display:none}
.product-popup .product-info_top .product-info_right .buy-block .buttons .thm-btn{width:100%}
.product-popup .product-info_top .props{padding-top:16px;padding-bottom:8px;margin-bottom:16px;border-top:1px solid rgba(216,216,216,.22);border-bottom:1px solid rgba(216,216,216,.22)}
@media (max-width:1700px){
.product-popup .product-info_top .props{padding-top:6px;padding-bottom:4px;margin-bottom:10px}
}
.product-popup .product-info_top .props .props-item{margin-bottom:16px}
@media (max-width:1700px){
.product-popup .product-info_top .props .props-item{margin-bottom:3px}
}
.product-popup .product-info_top .props .props-item:last-child{margin-bottom:0}
.product-popup .product-info_top .props .props-item .props-title{margin-bottom:16px;color:rgba(255,255,255,.8);font-size:14px;text-transform:uppercase}
@media (max-width:1700px){
.product-popup .product-info_top .props .props-item .props-title{margin-bottom:6px}
}
@media (max-width:767px){
.product-popup .product-info_top .props .props-item .props-title{font-size:12px}
}
.product-popup .product-info_bottom{padding:24px;border-radius:12px;background:#1f1f1f}
@media (max-width:1700px){
.product-popup .product-info_bottom{padding:16px}
}
@media (max-width:1199px){
.product-popup .product-info_bottom{padding-bottom:8px}
}
.product-popup .product-info_bottom.open .product-bottom_title .icon img{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}
.product-popup .product-info_bottom.open .product-bottom_wrapper{display:-webkit-box;display:-ms-flexbox;display:flex}
.product-popup .product-info_bottom .product-bottom_title{color:#fff;font-size:18px;font-weight:700;text-transform:uppercase;padding-bottom:16px;margin-bottom:18px;border-bottom:1px solid rgba(255,255,255,.25);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%}
@media (max-width:1700px){
.product-popup .product-info_bottom .product-bottom_title{padding-bottom:10px;margin-bottom:10px}
}
@media (max-width:1199px){
.product-popup .product-info_bottom .product-bottom_title{border-bottom:none;margin-bottom:0}
}
.product-popup .product-info_bottom .product-bottom_title .icon{display:none;width:28px;height:28px;border-radius:50%;background:#404040}
@media (max-width:1199px){
.product-popup .product-info_bottom .product-bottom_title .icon{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}
}
.product-popup .product-info_bottom .product-bottom_title .icon img{-ms-flex-item-align:center;align-self:center;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
.product-popup .product-info_bottom .product-bottom_wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
@media (max-width:1199px){
.product-popup .product-info_bottom .product-bottom_wrapper{display:none;padding-top:18px;border-top:1px solid rgba(255,255,255,.15)}
}
@media (max-width:991px){
.product-popup .product-info_bottom .product-bottom_wrapper{-ms-flex-wrap:wrap;flex-wrap:wrap}
}
.product-popup .product-info_bottom .product-bottom_wrapper .product-props{max-width:240px}
@media (max-width:767px){
.product-popup .product-info_bottom .product-bottom_wrapper .product-props{max-width:100%;width:100%}
}
.product-popup .product-info_bottom .product-bottom_wrapper .product-props .product-props_item{padding-bottom:8px;margin-bottom:8px;border-bottom:1px solid rgba(255,255,255,.15);color:#fff;font-family:"Open Sans",sans-serif;font-size:14px;line-height:2;padding-right:6px}
@media (max-width:1700px){
.product-popup .product-info_bottom .product-bottom_wrapper .product-props .product-props_item{padding-bottom:6px;margin-bottom:6px}
}
.product-popup .product-info_bottom .product-bottom_wrapper .product-props .product-props_item:last-child{margin-bottom:0;padding-bottom:0;border-bottom:none}
.product-popup .product-info_bottom .product-bottom_wrapper .product-tastes_wrapper{padding-top:22px}
@media (max-width:767px){
.product-popup .product-info_bottom .product-bottom_wrapper .product-tastes_wrapper{padding-top:57px;width:100%}
}
.product-popup .product-info_bottom .product-bottom_wrapper .product-tastes{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;margin-bottom:10px;min-width:330px;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
@media (max-width:767px){
.product-popup .product-info_bottom .product-bottom_wrapper .product-tastes{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;min-width:100%}
}
.product-popup .product-info_bottom .product-bottom_wrapper .product-tastes .flavours{padding-right:24px;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1}
.product-popup .product-info_bottom .product-bottom_wrapper .product-tastes .flavours .label-flavor_item .title{text-transform:uppercase;opacity:.6}
.product-popup .product-info_bottom .product-bottom_wrapper .product-tastes .flavors-values .flavor-value_item{font-size:16px;text-transform:uppercase}
@media (max-width:1700px){
.product-popup .product-info_bottom .product-bottom_wrapper .product-tastes .flavors-values .flavor-value_item{font-size:14px}
}
.product-popup .product-info_bottom .product-bottom_wrapper .product-video{max-width:420px;padding-top:22px}
@media (max-width:1199px){
.product-popup .product-info_bottom .product-bottom_wrapper .product-video{max-width:300px}
}
@media (max-width:991px){
.product-popup .product-info_bottom .product-bottom_wrapper .product-video{padding-top:35px}
}
@media (max-width:480px){
.product-popup .product-info_bottom .product-bottom_wrapper .product-video{max-width:100%}
}
.product-popup .product-info_bottom .product-bottom_wrapper .roasting-level{position:relative;padding-bottom:50px}
@media (max-width:1700px){
.product-popup .product-info_bottom .product-bottom_wrapper .roasting-level{padding-bottom:40px}
}
.product-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label{position:absolute;left:50%;bottom:0;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);padding:8px 10px;border-radius:12px;background:rgba(162,97,40,.45);color:#fff;font-size:14px;font-weight:500;line-height:21px}
@media (max-width:1700px){
.product-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label{padding-top:4px;padding-bottom:4px;font-size:13px}
}
.product-popup .product-info_bottom .product-bottom_wrapper .roasting-level .roasting-level_label span{font-size:10px}
.product-popup .product-info_bottom .product-bottom_wrapper .roasting-level_title{margin-bottom:20px;color:#fff;font-size:16px;font-weight:700;text-transform:uppercase}
@media (max-width:1700px){
.product-popup .product-info_bottom .product-bottom_wrapper .roasting-level_title{margin-bottom:10px}
}
.product-popup .product-info_bottom .product-bottom_wrapper .range-slider{position:relative;height:32px;border-radius:8px;background:rgba(255,255,255,.19);-webkit-box-shadow:0 4px 25px 0 rgba(0,0,0,.1);box-shadow:0 4px 25px 0 rgba(0,0,0,.1)}
.product-popup .product-info_bottom .product-bottom_wrapper .range-slider .range-slider__range{height:auto;top:5px;bottom:5px;-webkit-transform:none;-ms-transform:none;transform:none;border-radius:6px 0 0 6px;background:-webkit-gradient(linear,left top,right top,from(#720d0f),color-stop(91.35%,#a36129));background:linear-gradient(90deg,#720d0f 0,#a36129 91.35%)}
.product-popup .product-info_bottom .product-bottom_wrapper .range-slider .range-slider__thumb{width:12px;height:42px;border-radius:100px;background:#121010;-webkit-box-shadow:0 4px 30px 0 rgba(0,0,0,.1);box-shadow:0 4px 30px 0 rgba(0,0,0,.1)}
.product-popup .product-info_bottom .product-bottom_wrapper .range-slider .range-slider__thumb[data-disabled]{display:none}
@media (max-width:1700px){
.product-popup .flavors-values{padding:10px}
}
@media (max-width:1700px){
.product-popup .flavours .label-flavor_item{margin-bottom:10px}
}
.props-choices{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-left:-4px;margin-right:-4px}
.props-choices .props-choice{padding-left:4px;padding-right:4px;margin-bottom:8px}
.props-choices .props-choice .props-choice_label{display:block}
.props-choices .props-choice .props-choice_label input{display:none}
.props-choices .props-choice .props-choice_label input:checked+.name{padding-left:7px;padding-right:7px;background:#a26128;border-color:#a26128;color:#fff;font-weight:700}
.props-choices .props-choice .props-choice_label .name{display:block;padding:5px 8px;border-radius:5px;border:1px solid rgba(255,255,255,.5);-webkit-box-shadow:0 4px 20px 0 rgba(0,0,0,.1);box-shadow:0 4px 20px 0 rgba(0,0,0,.1);color:rgba(255,255,255,.5);text-align:center;font-size:12px;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
@media (max-width:767px){
.props-choices .props-choice .props-choice_label .name{font-size:11px}
}
.custom-dropdown{position:relative;padding-top:9px;width:100%}
.custom-dropdown .custom-dropdown_title{position:absolute;z-index:2;top:0;left:50%;padding-left:2px;padding-right:2px;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);color:#fff;font-size:12px;background:#141414;min-width:40px;text-align:center}
.custom-dropdown .custom-dropdown_wrapper{position:relative}
.custom-dropdown .custom-dropdown_wrapper.open .current-item:before{-webkit-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg)}
.custom-dropdown .custom-dropdown_wrapper.open .current-item:after{-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}
.custom-dropdown .custom-dropdown_wrapper.open .dropdown-list{opacity:1;visibility:visible;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0)}
.custom-dropdown .custom-dropdown_wrapper .current-item{text-align:left;display:block;width:100%;position:relative;border-radius:10px;border:1px solid #939393;padding:17px 20px;color:#fff;font-size:16px;font-weight:700;background-color:#141414}
.custom-dropdown .custom-dropdown_wrapper .current-item:after,.custom-dropdown .custom-dropdown_wrapper .current-item:before{position:absolute;right:17px;top:27px;display:block;content:"";width:10px;height:1px;border-radius:2px;background-color:#8e8e8e;-webkit-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg);-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
.custom-dropdown .custom-dropdown_wrapper .current-item:before{right:24px;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}
.custom-dropdown .custom-dropdown_wrapper .dropdown-list{position:absolute;left:8px;right:8px;top:100%;overflow:hidden;opacity:0;visibility:hidden;-webkit-transform:translateY(20px);-ms-transform:translateY(20px);transform:translateY(20px);border-radius:0 0 10px 10px;background:#fff;-webkit-box-shadow:0 4px 20px 0 rgba(0,0,0,.5);box-shadow:0 4px 20px 0 rgba(0,0,0,.5);-webkit-transition:all .2s ease-out;transition:all .2s ease-out;z-index:2}
.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn{display:block;width:100%;padding:4px 12px;text-align:left;color:#09080d;font-size:14px;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
@media (max-width:1700px){
.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn{font-size:13px}
}
.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn.exclude-btn{padding:0}
.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn.exclude-btn input{display:none}
.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn.exclude-btn input:checked+.name{color:#fff;background:#a26128}
.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn.exclude-btn .name{display:block;padding:4px 12px}
.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn:disabled{pointer-events:none;color:#858585}
.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn.active,.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn.selected,.custom-dropdown .custom-dropdown_wrapper .dropdown-list .dropdown-item_btn:hover{color:#fff;background:#a26128}
body .steps-wrapper .step-item_wrapper.editor-wrapper{display:block;padding-top:0;padding-bottom:79px}
@media (max-width:1700px){
body .steps-wrapper .step-item_wrapper.editor-wrapper{padding-bottom:58px}
}
body .steps-wrapper .step-item_wrapper.editor-wrapper.open-coffee-id .blend-wrapper{z-index:2}
body .steps-wrapper .step-item_wrapper.editor-wrapper.open-coffee-id .blend-wrapper .blend-wrapper_left{opacity:1;visibility:visible}
body .steps-wrapper .step-item_wrapper.editor-wrapper.open-coffee-id .blend-assistant{z-index:1}
@media (max-width:1199px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper{padding-bottom:20px;padding-top:101px}
}
@media (max-width:991px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper{padding-top:84px}
}
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left{padding-bottom:0}
@media (max-width:1199px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left{position:fixed;left:0;top:0;width:100%;min-height:100vh;overflow-y:auto;-webkit-transition:all .2s ease-out;transition:all .2s ease-out;z-index:5;opacity:0;visibility:hidden;background:rgba(0,0,0,.9);padding-top:60px}
}
@media (max-width:767px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .coffee-type{margin-bottom:0}
}
@media (max-width:767px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .blend-sidebar_title{margin-bottom:10px}
}
@media (max-width:767px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .blend-sidebar_title .title{font-size:14px;-webkit-box-flex:1;-ms-flex:auto;flex:auto;max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content;padding-right:10px}
}
@media (max-width:767px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .flavors .flavors-wrapper .flavor-value_item{font-size:10px}
}
@media (max-width:767px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .flavors .flavors-wrapper .flavor-value_item .value{font-size:11px}
}
@media (max-width:767px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .flavors .flavors-wrapper .flavors-chart{padding-left:30px;padding-right:30px;max-width:274px}
}
@media (max-width:767px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .flavors .flavors-wrapper .flavors-chart canvas{left:35px;top:-105px}
}
@media (max-width:767px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .tastes .notes-wrapper.small{padding-top:0;padding-bottom:0}
}
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .close-coffee-id{display:none}
@media (max-width:1199px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .close-coffee-id{position:fixed;left:0;top:0;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:5px;background:#e7d0b1}
}
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .close-coffee-id .close-coffee-id-btn{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#151515;font-size:24px;font-weight:700;line-height:33px}
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .close-coffee-id .close-coffee-id-btn img{margin-left:5px;margin-top:3px}
@media (max-width:1199px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_right{display:block;padding:0 10px;background:0 0}
}
@media (max-width:1199px){
body .steps-wrapper .step-item_wrapper.editor-wrapper .blend-assistant{bottom:90px}
}
body .editor-wrapper{padding-top:20px}
@media (max-width:1700px){
body .editor-wrapper{padding-top:10px}
}
@media (max-width:1199px){
body .editor-wrapper{padding-top:0}
}
body .editor-wrapper .blend-wrapper{min-height:10vh}
body .editor-wrapper .blend-wrapper_right{max-width:910px}
@media (max-width:1199px){
body .editor-wrapper .blend-wrapper_right{max-width:100%}
}
body .editor-wrapper .editor-top{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:relative;margin-bottom:16px;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
@media (max-width:1199px){
body .editor-wrapper .editor-top{margin-left:-10px;margin-right:-10px;background-color:#2e2e2e;padding:8px 14px 11px;margin-bottom:20px;display:block}
}
body .editor-wrapper .editor-top .percentage{color:#fff;font-size:28px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-right:36px}
@media (max-width:1199px){
body .editor-wrapper .editor-top .percentage{padding-left:0}
}
@media (max-width:767px){
body .editor-wrapper .editor-top .percentage{font-size:20px}
}
body .editor-wrapper .editor-top .percentage .title{max-width:170px;-webkit-box-flex:0;-ms-flex:0 0 170px;flex:0 0 170px;font-size:18px}
body .editor-wrapper .editor-top .percentage .percentage-wrapper{position:relative;background-color:#fff;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;height:25px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:0 6px 6px 0}
body .editor-wrapper .editor-top .percentage .percentage-wrapper .percentage-progress{position:absolute;left:0;top:0;height:100%;background-color:#00944d;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .editor-wrapper .editor-top .percentage .percentage-wrapper.medium .percentage-progress{background-color:#a26128}
body .editor-wrapper .editor-top .percentage .percentage-wrapper.low .percentage-progress{background-color:red}
body .editor-wrapper .editor-top .percentage strong{position:relative;color:#fff;text-align:center;font-size:16px;letter-spacing:-1px;z-index:2}
@media (max-width:1199px){
body .editor-wrapper .editor-top .percentage strong{font-size:14px}
}
body .editor-wrapper .editor-top .percentage svg{position:absolute;left:-4px;top:-4px;width:54px;height:54px}
body .editor-wrapper .editor-top .buttons{max-width:216px;-webkit-box-flex:0;-ms-flex:0 0 216px;flex:0 0 216px;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}
@media (max-width:1199px){
body .editor-wrapper .editor-top .buttons{margin-bottom:16px;max-width:100%;-webkit-box-flex:1;-ms-flex:auto;flex:auto}
}
body .editor-wrapper .editor-top .buttons.multiple .thm-btn{margin-right:0;margin-left:10px;min-width:82px;height:46px;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;border-radius:10px;background-color:#09080d;border:1px solid #a26128;color:#fff;font-size:14px}
@media (max-width:1700px){
body .editor-wrapper .editor-top .buttons.multiple .thm-btn{height:36px}
}
@media (max-width:1199px){
body .editor-wrapper .editor-top .buttons.multiple .thm-btn{margin-right:8px;margin-left:0}
}
body .editor-wrapper .editor-top .buttons.multiple .thm-btn.profile,body .editor-wrapper .editor-top .buttons.multiple .thm-btn.surprise{min-width:124px}
body .editor-wrapper .editor-top .buttons.multiple .thm-btn.profile{display:none}
@media (max-width:1199px){
body .editor-wrapper .editor-top .buttons.multiple .thm-btn.profile{display:-webkit-box;display:-ms-flexbox;display:flex}
}
body .editor-wrapper .editor-top .buttons.multiple .thm-btn img{margin-left:8px;max-width:20px}
body .editor-wrapper .editor-top .coffee-type_icon{display:none}
@media (max-width:1199px){
body .editor-wrapper .editor-top .coffee-type_icon{margin-left:20px;display:-webkit-box;display:-ms-flexbox;display:flex;width:50px;height:50px;border-radius:5px;background:#a26128;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
}
body .editor-wrapper .editor-top .coffee-type_icon img{max-width:24px}
body .editor-wrapper .editor-top .coffee-type_icon .name{display:block;color:#fff;text-align:center;font-size:10px;line-height:16px;text-transform:capitalize}
@media (max-width:1199px){
body .editor-wrapper .editor-blocks_wrapper{padding:25px 16px 0;border-radius:12px;background:rgba(36,35,35,.8)}
}
@media (max-width:767px){
body .editor-wrapper .editor-blocks_wrapper{padding-top:14px;padding-left:14px;padding-right:14px}
}
body .editor-blocks{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-left:-10px;margin-right:-10px}
body .editor-blocks .editor-block_item{padding-left:10px;padding-right:10px;margin-top:20px;max-width:50%;-webkit-box-flex:0;-ms-flex:0 0 50%;flex:0 0 50%}
@media (max-width:1700px){
body .editor-blocks .editor-block_item{margin-top:16px}
}
@media (max-width:1199px){
body .editor-blocks .editor-block_item{margin-top:0}
}
@media (max-width:767px){
body .editor-blocks .editor-block_item{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1;margin-bottom:14px;max-width:100%;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%}
}
body .editor-blocks .editor-block_item:nth-child(1),body .editor-blocks .editor-block_item:nth-child(2){margin-top:0}
body .editor-blocks .editor-block_item.show-tooltip-mobile .tooltip-cover.mobile{opacity:1;visibility:visible}
@media (max-width:991px){
body .editor-blocks .editor-block_item .notes-wrapper{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-bottom:0}
}
body .editor-blocks .editor-block_item.select-item{position:relative;z-index:2}
@media (max-width:767px){
body .editor-blocks .editor-block_item.select-item{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
}
@media (max-width:767px){
body .editor-blocks .editor-block_item.roast-item{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
}
body .editor-blocks .editor-block_item .editor-item_inner{position:relative;border-radius:10px;background:#000;-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);border:1px solid #09080d;padding:15px;height:100%}
@media (max-width:1700px){
body .editor-blocks .editor-block_item .editor-item_inner{padding-top:10px;padding-bottom:10px}
}
body .editor-blocks .editor-block_item .editor-item_inner.editable{border:1px solid #e6d0b1}
body .editor-blocks .editor-block_item .tooltip{position:absolute;right:8px;top:8px;z-index:5;cursor:pointer}
body .editor-blocks .editor-block_item .tooltip:hover+.tooltip-cover{opacity:1;visibility:visible}
body .editor-blocks .editor-block_item .tooltip-cover{position:absolute;left:0;top:0;width:100%;height:100%;z-index:4;border-radius:10px;background:rgba(0,0,0,.9);-webkit-backdrop-filter:blur(2.5px);backdrop-filter:blur(2.5px);-webkit-transition:all .2s ease-out;transition:all .2s ease-out;padding:15px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;opacity:0;visibility:hidden}
body .editor-blocks .editor-block_item .tooltip-cover.mobile{display:none}
@media (max-width:1199px){
body .editor-blocks .editor-block_item .tooltip-cover.mobile{display:-webkit-box;display:-ms-flexbox;display:flex;height:auto;position:fixed;top:0;border-radius:0;min-height:100vh;padding-top:100px}
}
@media (max-width:1199px){
body .editor-blocks .editor-block_item .tooltip-cover{display:none;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;z-index:9}
}
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .tooltip-icon{margin-bottom:11px;display:none}
@media (max-width:1199px){
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .tooltip-icon{display:block}
}
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .tooltip-title{margin-bottom:11px;color:#fff;text-align:center;font-size:28px;font-weight:600}
@media (max-width:1700px){
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .tooltip-title{font-size:24px}
}
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .tooltip-text p{color:#fff;text-align:center;font-size:14px}
@media (max-width:1700px){
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .tooltip-text p{font-size:13px}
}
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .close-tooltip{margin-top:30px;display:none}
@media (max-width:1199px){
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .close-tooltip{display:block}
}
body .editor-blocks .editor-block_item .tooltip-cover .tooltip-cover_wrapper .close-tooltip img{width:47px;height:47px;-webkit-filter:invert(1);filter:invert(1)}
body .editor-blocks .editor-block_item .editor-title{margin-bottom:16px;color:#fff;font-size:16px;font-weight:700;text-transform:capitalize}
@media (max-width:1700px){
body .editor-blocks .editor-block_item .editor-title{margin-bottom:10px}
}
body .editor-blocks .taste-profile{margin-left:auto;margin-right:auto;max-width:260px}
@media (max-width:767px){
body .editor-blocks .taste-profile{max-width:100%}
}
@media (max-width:1700px){
body .editor-blocks .taste-profile canvas{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}
}
@media (max-width:1199px){
body .editor-blocks .taste-profile canvas{position:relative;left:auto;top:auto;-webkit-transform:none;-ms-transform:none;transform:none}
}
body .blend-dropdowns{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}
@media (max-width:767px){
body .blend-dropdowns{margin-left:-10px;margin-right:-10px}
}
body .blend-dropdowns .blend-dropdown_item{margin-bottom:27px;max-width:33.33%;-webkit-box-flex:0;-ms-flex:0 0 33.33%;flex:0 0 33.33%;padding-left:6px;padding-right:6px}
@media (max-width:1700px){
body .blend-dropdowns .blend-dropdown_item{margin-bottom:10px}
}
@media (max-width:767px){
body .blend-dropdowns .blend-dropdown_item{padding-left:10px;padding-right:10px}
}
body .blend-dropdowns .blend-dropdown_item:last-child{margin-bottom:0}
body .blend-dropdowns .blend-dropdown_item .default-dropdowns{display:none}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .label{margin-bottom:8px;color:#e6d0b1;font-size:16px;font-weight:700;text-transform:capitalize}
@media (max-width:1700px){
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .label{margin-bottom:5px;font-size:14px}
}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown{padding-top:0;max-width:100%}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper.removed .current-item{color:transparent}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper.removed .current-item img{opacity:1;visibility:visible}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper .current-item{position:relative;padding-top:16px;padding-bottom:16px;padding-left:28px;border-radius:10px;background:rgba(255,255,255,.15);border:none;text-align:center}
@media (max-width:767px){
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper .current-item{padding-top:10px;padding-bottom:10px;text-align:right;font-size:14px;line-height:20px}
}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper .current-item img{position:absolute;left:56%;top:50%;max-height:34px;opacity:0;visibility:visible;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper .current-item:after,body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper .current-item:before{left:12px;top:27px}
@media (max-width:767px){
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper .current-item:after,body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper .current-item:before{top:21px}
}
body .blend-dropdowns .blend-dropdown_item .custom-dropdowns .custom-dropdown .custom-dropdown_wrapper .current-item:after{left:19px}
body .roast-item .roasting-level{position:relative;margin-top:55px}
@media (max-width:767px){
body .roast-item .roasting-level{margin-top:25px;padding-bottom:30px}
}
body .roast-item .roasting-level select{display:none}
body .roast-item .roasting-level .slider-wrapper .labels{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:12px}
@media (max-width:767px){
body .roast-item .roasting-level .slider-wrapper .labels{margin-bottom:22px}
}
body .roast-item .roasting-level .slider-wrapper .labels .label-item{color:#fff;font-size:16px;font-weight:700}
@media (max-width:767px){
body .roast-item .roasting-level .slider-wrapper .labels .label-item{font-size:14px;font-weight:500}
}
body .roast-item .roasting-level .slider-wrapper .range-slider{position:relative;border-radius:8px;height:50px;background:#3b3b3b}
@media (max-width:767px){
body .roast-item .roasting-level .slider-wrapper .range-slider{height:34px}
}
body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__range{left:0!important;border-radius:8px 0 0 8px;background:-webkit-gradient(linear,left top,right top,from(#720d0f),color-stop(91.35%,#a36129));background:linear-gradient(90deg,#720d0f 0,#a36129 91.35%)}
body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb{width:24px;height:60px;background:#9f9f9f;border-radius:6px}
@media (max-width:767px){
body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb{width:18px;height:44px}
}
body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:after,body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:before{position:absolute;left:6px;top:15px;display:block;content:"";width:12px;height:30px;border-top:2px solid #fff;border-bottom:2px solid #fff;-webkit-box-sizing:border-box;box-sizing:border-box}
@media (max-width:767px){
body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:after,body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:before{width:8px;height:24px;left:5px;top:10px}
}
body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:after{top:30px;border-bottom:none}
@media (max-width:767px){
body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:after{top:22px}
}
body .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb[data-disabled]{display:none}
body .roast-item .roasting-level .roasting-level_label{position:absolute;left:130px;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);color:#e6d0b1;font-size:14px;font-weight:700;text-transform:uppercase;text-align:center;display:none}
body .roast-item .roasting-level .roasting-level_label span{display:block;font-size:10px}
body .percentage-left{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;color:#fff;font-size:12px;letter-spacing:1px;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .percentage-left.op-0{opacity:0}
body .percentage-left span{color:#a26128;font-weight:700}
@media (max-width:767px){
.add-to-cart-page .step-item_wrapper{padding:110px 23px 17px!important}
}
.add-to-cart-page .product-id-block{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-bottom:50px;margin-top:20px;border-radius:10px;border:1px solid #afafaf;background:-webkit-gradient(linear,left bottom,left top,from(#fff),to(#fff)),-webkit-gradient(linear,left top,left bottom,from(#3a3939),to(#202020));background:linear-gradient(0deg,#fff 0,#fff 100%),linear-gradient(180deg,#3a3939 0,#202020 100%);-webkit-backdrop-filter:blur(12px);backdrop-filter:blur(12px);padding:13px 17px}
@media (max-width:767px){
.add-to-cart-page .product-id-block{width:100%;margin-top:50px;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}
}
.add-to-cart-page .product-id-block .pib-value{color:#383838;font-size:24px;font-weight:500;line-height:1}
@media (max-width:767px){
.add-to-cart-page .product-id-block .pib-value{font-size:20px;text-overflow:ellipsis;overflow:hidden;display:block;white-space:nowrap;max-width:100%;direction:ltr;margin-bottom:16px}
}
.add-to-cart-page .product-id-block .pib-copy{margin-right:20px}
.add-to-cart-page .product-id-block .pib-copy .copy-btn{min-width:145px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:8px 10px 7px;border-radius:10px;border:1px solid #afafaf;background:-webkit-gradient(linear,left bottom,left top,from(#e7d0b1),to(#e7d0b1)),-webkit-gradient(linear,left top,left bottom,from(#3a3939),to(#202020));background:linear-gradient(0deg,#e7d0b1 0,#e7d0b1 100%),linear-gradient(180deg,#3a3939 0,#202020 100%);color:#09080d;font-size:18px}
@media (max-width:767px){
.add-to-cart-page .product-id-block .pib-copy .copy-btn{font-size:16px;min-width:110px;padding-top:6px;padding-bottom:5px}
}
.add-to-cart-page .product-id-block .pib-copy .copy-btn img{margin-left:6px}
@media (max-width:767px){
.add-to-cart-page .product-id-block .pib-copy .copy-btn img{max-width:20px}
}
.add-to-cart-page .share-block{margin-bottom:60px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.add-to-cart-page .share-block .share-title{margin-left:38px;color:#fff;font-size:18px}
.add-to-cart-page .share-block .share-items{display:-webkit-box;display:-ms-flexbox;display:flex}
.add-to-cart-page .share-block .share-items .share-item{margin-left:15px}
.add-to-cart-page .share-block .share-items .share-item .share-item_link{display:block}
.add-to-cart-page .share-block .share-items .share-item:last-child{margin-left:0}
body .print-btn{position:fixed;right:40px;top:50%;z-index:9;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:12px;background:#a26128;padding:8px 24px;color:#fff;text-align:center;font-size:20px;font-weight:600}
@media (max-width:480px){
body .print-btn{display:none}
}
body .print-btn img{display:block;margin-right:12px}
body .sticker-page{padding:40px;background:#121010}
@media (max-width:1700px){
body .sticker-page{padding:0}
}
body .sticker-wrapper{width:100%;max-width:1295px;margin-left:auto;margin-right:auto;background-color:#000}
body .sticker-wrapper .page-content .page-header{position:relative;left:auto;top:auto;background:0 0;-webkit-box-shadow:none;box-shadow:none;-webkit-backdrop-filter:none;backdrop-filter:none;padding-bottom:15px}
body .sticker-wrapper .steps-wrapper .step-item_wrapper.editor-wrapper{padding-bottom:0;min-height:10vh}
@media (max-width:1199px){
body .sticker-wrapper .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper{display:block;padding-top:0;padding-left:24px;padding-right:24px}
}
@media (max-width:1199px){
body .sticker-wrapper .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left{position:relative;top:auto;left:auto;opacity:1;visibility:visible;background:rgba(36,35,35,.8);margin-bottom:56px;padding-top:18px}
}
@media (max-width:767px){
body .sticker-wrapper .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left{min-height:10vh;padding-bottom:30px}
}
body .sticker-wrapper .blend-wrapper{padding:0 60px 30px}
body .sticker-wrapper .blend-wrapper .blend-wrapper_left{border-radius:12px}
body .sticker-wrapper .sticker-title{margin-bottom:20px;color:#fff;font-size:42px;font-weight:700;text-transform:capitalize}
@media (max-width:1700px){
body .sticker-wrapper .sticker-title{margin-bottom:10px;font-size:28px}
}
body .sticker-wrapper .editor-wrapper .editor-top{margin-bottom:25px;padding:14px 16px 14px 66px!important;border-radius:10px;border:1px solid #e6d0b1}
@media (max-width:1700px){
body .sticker-wrapper .editor-wrapper .editor-top{margin-bottom:16px;padding-top:10px!important;padding-bottom:10px!important}
}
@media (max-width:1199px){
body .sticker-wrapper .editor-wrapper .editor-top{margin-left:0;margin-right:0;background:0 0}
}
@media (max-width:767px){
body .sticker-wrapper .editor-wrapper .editor-top{margin-bottom:24px}
}
body .sticker-wrapper .editor-wrapper .editor-top .percentage{padding-right:0}
@media (max-width:767px){
body .sticker-wrapper .editor-wrapper .editor-top .percentage{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}
}
body .sticker-wrapper .editor-wrapper .editor-top .percentage .percentage-wrapper{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}
@media (max-width:767px){
body .sticker-wrapper .editor-wrapper .editor-top .percentage .percentage-wrapper{width:100%}
}
@media (max-width:767px){
body .sticker-wrapper .editor-wrapper .editor-top .percentage .title{-webkit-box-flex:1;-ms-flex:auto;flex:auto;margin-bottom:10px}
}
body .sticker-wrapper .editor-wrapper .editor-top .percentage strong{margin-left:-50px}
body .sticker-wrapper .editor-wrapper .editor-blocks_wrapper{background:0 0;padding:0}
body .sticker-wrapper .editor-blocks{margin-bottom:20px}
@media (max-width:1700px){
body .sticker-wrapper .editor-blocks{margin-bottom:16px;margin-left:-8px;margin-right:-8px}
}
@media (max-width:767px){
body .sticker-wrapper .editor-blocks{margin-bottom:24px}
}
body .sticker-wrapper .editor-blocks:last-child{margin-bottom:0}
body .sticker-wrapper .editor-blocks .taste-profile{position:absolute;left:50%;top:54%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);max-width:280px;width:100%}
@media (max-width:1700px){
body .sticker-wrapper .editor-blocks .taste-profile{max-width:260px;top:50%}
}
@media (max-width:767px){
body .sticker-wrapper .editor-blocks .taste-profile{position:relative;top:auto;left:auto;-webkit-transform:none;-ms-transform:none;transform:none;max-width:100%}
}
body .sticker-wrapper .editor-blocks .editor-block_item{max-width:220px;-webkit-box-flex:0;-ms-flex:0 0 220px;flex:0 0 220px;min-height:270px}
@media (max-width:1700px){
body .sticker-wrapper .editor-blocks .editor-block_item{padding-left:8px;padding-right:8px;min-height:230px}
}
@media (max-width:767px){
body .sticker-wrapper .editor-blocks .editor-block_item{max-width:100%;-webkit-box-flex:0;-ms-flex:0 0 100%;flex:0 0 100%;min-height:10px;margin-bottom:24px}
}
@media (max-width:767px){
body .sticker-wrapper .editor-blocks .editor-block_item:last-child{margin-bottom:0}
}
body .sticker-wrapper .editor-blocks .editor-block_item:nth-child(2n){max-width:initial;-webkit-box-flex:1;-ms-flex:auto;flex:auto;-ms-flex-positive:1;flex-grow:1}
@media (max-width:991px){
body .sticker-wrapper .editor-blocks .editor-block_item:nth-child(2n){-webkit-box-flex:1;-ms-flex:1 0;flex:1 0}
}
body .sticker-wrapper .editor-blocks .editor-block_item .editor-item_inner .editor-title{font-size:16px}
@media (max-width:1700px){
body .sticker-wrapper .editor-blocks .notes-wrapper.small{padding-top:0;padding-bottom:0}
}
body .sticker-wrapper .editor-blocks .notes-wrapper.small .float-flavors.dynamic .label-flavors{top:0}
body .sticker-wrapper .editor-blocks .notes-wrapper.small .float-flavors.dynamic .label-flavors.chocolaty_and_nuts,body .sticker-wrapper .editor-blocks .notes-wrapper.small .float-flavors.dynamic .label-flavors.fruity_and_citrus{top:90px}
body .sticker-wrapper .coffee-items .coffee-item{margin-bottom:10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#e7d0b1;font-size:18px;font-weight:700}
@media (max-width:1700px){
body .sticker-wrapper .coffee-items .coffee-item{margin-bottom:8px;font-size:17px}
}
body .sticker-wrapper .coffee-items .coffee-item .value{margin-right:5px;color:#fff;font-weight:400}
body .sticker-wrapper .coffee-items .coffee-item:last-child{margin-bottom:0}
body .sticker-wrapper .roast-item .roasting-level{margin-top:0}
@media (max-width:767px){
body .sticker-wrapper .roast-item .roasting-level{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}
}
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider{width:62px;height:200px;-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}
@media (max-width:1700px){
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider{height:170px}
}
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider.level-1 .range-slider__thumb:after{content:"בהירה"}
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider.level-3 .range-slider__thumb:after{content:"כהה"}
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb{width:74px;height:6px}
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:before{display:none}
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:after{content:"בינונית";top:-10px;width:auto;height:auto;border-top:none;color:#fff;font-size:20px;font-weight:700;left:86px;-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}
@media (max-width:1700px){
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:after{font-size:18px}
}
body .sticker-wrapper .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__range{-webkit-transform:none;-ms-transform:none;transform:none;border-radius:8px 8px 0 0;top:0!important}
body .print-wrapper{display:none}
body .sticker-wrapper .blend-wrapper .flavors .flavors-wrapper .flavor-value_item{font-weight:700}
@media (min-width:480px) and (max-width:767px){
body .sticker-wrapper.sticker-print{max-width:566px;width:100%}
body .sticker-wrapper.sticker-print .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;padding-bottom:10px}
body .sticker-wrapper.sticker-print .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper .blend-wrapper_left .tastes .notes-wrapper.small{padding-top:10px;padding-bottom:10px}
body .sticker-wrapper.sticker-print .page-content .page-header{padding-top:5px;padding-bottom:0}
body .sticker-wrapper.sticker-print .page-content .page-header img{max-height:32px}
body .sticker-wrapper.sticker-print .blend-wrapper{padding-right:10px!important;padding-left:10px!important;padding-bottom:10px;min-height:10px;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}
body .sticker-wrapper.sticker-print .blend-wrapper .blend-sidebar_title{margin-bottom:7px!important}
body .sticker-wrapper.sticker-print .blend-wrapper .blend-sidebar_title .title{font-size:8px!important;max-width:60px!important;-webkit-box-flex:0!important;-ms-flex:0 0 60px!important;flex:0 0 60px!important;white-space:nowrap}
body .sticker-wrapper.sticker-print .blend-wrapper .blend-wrapper_left{width:100%;max-width:210px;-webkit-box-flex:0;-ms-flex:0 0 210px;flex:0 0 210px;padding:8px 14px 10px!important;border-radius:5px;margin-bottom:0!important}
body .sticker-wrapper.sticker-print .blend-wrapper .coffee-type{margin-bottom:10px!important}
body .sticker-wrapper.sticker-print .blend-wrapper .coffee-type .coffee-type_icon{max-width:45px;-webkit-box-flex:0;-ms-flex:0 0 45px;flex:0 0 45px;padding-left:5px;padding-right:5px;border-radius:5px}
body .sticker-wrapper.sticker-print .blend-wrapper .coffee-type .coffee-type_icon .icon{margin-bottom:3px;max-width:24px}
body .sticker-wrapper.sticker-print .blend-wrapper .coffee-type .coffee-type_icon .name{font-size:7px;line-height:1}
body .sticker-wrapper.sticker-print .blend-wrapper .coffee-type .coffee-type_info .title{font-size:12px}
body .sticker-wrapper.sticker-print .blend-wrapper .coffee-type .coffee-type_info .subtitle{font-size:8px}
body .sticker-wrapper.sticker-print .blend-wrapper .flavors .flavors-wrapper .flavors-values{max-width:45px;-webkit-box-flex:0;-ms-flex:0 0 45px;flex:0 0 45px;padding:10px 8px}
body .sticker-wrapper.sticker-print .blend-wrapper .flavors .flavors-wrapper .flavor-value_item{font-size:6px!important}
body .sticker-wrapper.sticker-print .blend-wrapper .flavors .flavors-wrapper .flavor-value_item .value{font-size:7px!important}
body .sticker-wrapper.sticker-print .blend-wrapper .flavors .flavors-wrapper .flavors-chart{max-width:144px!important;-webkit-box-flex:0!important;-ms-flex:0 0 144px!important;flex:0 0 144px!important;padding-left:0!important;padding-right:0!important}
body .sticker-wrapper.sticker-print .blend-wrapper .flavors .flavors-wrapper .flavors-chart canvas{top:50%!important;right:0!important;width:144px!important;height:144px!important}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner{width:68px;height:68px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item{padding:2px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value{width:30px;height:30px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-left .label-title{padding-left:12px;padding-top:8px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-icon{top:4px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.top-right .label-title{padding-left:15px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value.bottom-right .label-title{padding-left:0;padding-top:9px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon{width:6px;height:6px;left:4px;bottom:4px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-icon img{max-width:4px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .notes-wrapper_inner .notes-item .notes-item_label .label-value .label-title{padding-top:5px;font-size:4px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors.dynamic{position:absolute;left:0;top:0;width:100%;margin-top:0}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors.dynamic .label-flavors{min-height:45px;-webkit-box-flex:0;-ms-flex:0 0 53px;flex:0 0 53px;max-width:53px;position:absolute!important;left:0!important;top:0!important;margin:0}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors.dynamic .label-flavors.chocolaty_and_nuts,body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors.dynamic .label-flavors.sweet_and_floral{left:auto!important;right:0!important}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors.dynamic .label-flavors.chocolaty_and_nuts,body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors.dynamic .label-flavors.fruity_and_citrus{top:50px!important}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors.dynamic .label-flavors .label-flavor_item .icon{max-width:8px;-webkit-box-flex:0;-ms-flex:0 0 8px;flex:0 0 8px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors.dynamic .label-flavors .label-flavor_item .icon img{max-width:8px;max-height:8px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors .label-flavors{border-radius:4px;min-width:53px;padding-bottom:3px;display:block}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors .label-flavors.empty{display:-webkit-box;display:-ms-flexbox;display:flex}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors .label-flavors.empty img{max-width:20px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors .label-flavors .flavours-title{font-size:6px;line-height:12px;margin-bottom:0}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item{padding-left:4px;padding-right:4px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item .icon{max-width:8px;-webkit-box-flex:0;-ms-flex:0 0 8px;flex:0 0 8px}
body .sticker-wrapper.sticker-print .notes-wrapper.small .float-flavors .label-flavors .label-flavor_item .title{padding-right:4px;font-size:6px;line-height:12px}
body .sticker-wrapper.sticker-print .sticker-title{margin-bottom:5px;font-size:18px}
body .sticker-wrapper.sticker-print .editor-wrapper .blend-wrapper_right{padding-right:10px;padding-left:0!important;width:100%;max-width:336px;-webkit-box-flex:0;-ms-flex:0 0 336px;flex:0 0 336px;-webkit-box-flex:unset;-ms-flex-positive:unset;flex-grow:unset}
body .sticker-wrapper.sticker-print .editor-wrapper .editor-top{margin-bottom:7px;padding:5px 10px 5px 25px!important;border-radius:4px}
body .sticker-wrapper.sticker-print .editor-wrapper .editor-top .percentage{-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
body .sticker-wrapper.sticker-print .editor-wrapper .editor-top .percentage .title{margin-bottom:0;font-size:8px;max-width:80px;-webkit-box-flex:0;-ms-flex:0 0 80px;flex:0 0 80px}
body .sticker-wrapper.sticker-print .editor-wrapper .editor-top .percentage strong{margin-left:-20px;font-size:6px;letter-spacing:0}
body .sticker-wrapper.sticker-print .editor-wrapper .editor-top .percentage .percentage-wrapper{height:10px;border-radius:0 3px 3px 0}
body .sticker-wrapper.sticker-print .editor-blocks{margin-left:-5px;margin-right:-5px;margin-bottom:7px}
body .sticker-wrapper.sticker-print .editor-blocks:last-child{margin-bottom:0}
body .sticker-wrapper.sticker-print .editor-blocks .editor-block_item{padding-left:5px;padding-right:5px;max-width:94px;-webkit-box-flex:0;-ms-flex:0 0 94px;flex:0 0 94px;min-height:125px;margin-bottom:0}
body .sticker-wrapper.sticker-print .editor-blocks .editor-block_item:nth-child(2n){max-width:242px;-webkit-box-flex:0;-ms-flex:0 0 242px;flex:0 0 242px;-webkit-box-flex:unset;-ms-flex-positive:unset;flex-grow:unset}
body .sticker-wrapper.sticker-print .editor-blocks .editor-block_item .editor-item_inner{padding:5px 10px;border-radius:4px}
body .sticker-wrapper.sticker-print .editor-blocks .editor-block_item .editor-item_inner .editor-title{margin-bottom:4px;font-size:10px;white-space:nowrap}
body .sticker-wrapper.sticker-print .editor-blocks .taste-profile{max-width:144px;position:absolute;top:-10px;left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%)}
body .sticker-wrapper.sticker-print .editor-blocks .taste-profile canvas{width:144px!important;height:144px!important}
body .sticker-wrapper.sticker-print .editor-blocks .notes-wrapper.small{padding-top:15px}
body .sticker-wrapper.sticker-print .coffee-items .coffee-item{margin-bottom:6px;font-size:8px;white-space:nowrap}
body .sticker-wrapper.sticker-print .coffee-items .coffee-item .value{margin-right:2px}
body .sticker-wrapper.sticker-print .roast-item .roasting-level{padding-bottom:0;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}
body .sticker-wrapper.sticker-print .roast-item .roasting-level .slider-wrapper .range-slider{height:80px;width:26px;border-radius:4px}
body .sticker-wrapper.sticker-print .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__range{border-radius:4px 4px 0 0}
body .sticker-wrapper.sticker-print .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb{width:32px;height:3px}
body .sticker-wrapper.sticker-print .roast-item .roasting-level .slider-wrapper .range-slider .range-slider__thumb:after{font-size:8px;left:38px;top:-5px}
}
@media (max-width:480px){
body .sticker-wrapper.sticker-print .steps-wrapper .step-item_wrapper.editor-wrapper .blend-wrapper{display:block}
body .sticker-wrapper.sticker-print .blend-wrapper .blend-wrapper_left{margin-bottom:10px!important;max-width:100%}
body .sticker-wrapper.sticker-print .blend-wrapper .blend-wrapper_left .coffee-type{margin-bottom:10px!important}
body .sticker-wrapper.sticker-print .blend-wrapper .flavors .flavors-wrapper .flavors-chart{padding-left:0!important;padding-right:0!important;max-width:230px!important}
body .sticker-wrapper.sticker-print .blend-wrapper .flavors .flavors-wrapper .flavors-chart canvas{-webkit-transform:translateY(-3%);-ms-transform:translateY(-3%);transform:translateY(-3%);right:0!important}
body .sticker-wrapper.sticker-print .editor-wrapper .blend-wrapper_right{max-width:100%;padding-right:0!important;padding-left:0!important}
body .sticker-wrapper.sticker-print .editor-blocks .editor-block_item{min-height:130px}
body .sticker-wrapper.sticker-print .editor-blocks .editor-block_item:nth-child(2n){max-width:100%}
}
body .moveUp{-webkit-animation:moveUp 1s infinite ease-out;animation:moveUp 1s infinite ease-out}
body .delay-1{-webkit-animation-delay:.2s;animation-delay:.2s}
body .delay-2{-webkit-animation-delay:.4s;animation-delay:.4s}
body .delay-3{-webkit-animation-delay:.6s;animation-delay:.6s}
body .delay-4{-webkit-animation-delay:.8s;animation-delay:.8s}
@-webkit-keyframes moveUp{
0%{-webkit-transform:translateY(0);transform:translateY(0)}
20%{-webkit-transform:translateY(-5px);transform:translateY(-5px)}
40%{-webkit-transform:translateY(0);transform:translateY(0)}
100%{-webkit-transform:translateY(0);transform:translateY(0)}
}
@keyframes moveUp{
0%{-webkit-transform:translateY(0);transform:translateY(0)}
20%{-webkit-transform:translateY(-5px);transform:translateY(-5px)}
40%{-webkit-transform:translateY(0);transform:translateY(0)}
100%{-webkit-transform:translateY(0);transform:translateY(0)}
}
body.preloader .ajax-preloader{opacity:1;visibility:visible}
body .ajax-preloader{position:fixed;left:0;top:0;width:100%;height:100%;z-index:99999999;background-color:rgba(0,0,0,.9);display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;opacity:0;visibility:hidden;-webkit-transition:all .2s ease-out;transition:all .2s ease-out}
body .ajax-preloader .loader{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
body .ajax-preloader .loader .loader__ball{width:16px;height:16px;border-radius:50%;margin-left:5px;margin-right:5px;background-color:#fff}
