.modal
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  //display: block
  opacity: 0
  visibility: hidden
  //display: none
  z-index: -1
  background: rgba(0, 0, 0, 0.50)
  backdrop-filter: blur(12px)
  overflow-y: auto
  &.open
    opacity: 1
    visibility: visible
    z-index: 9999
    //display: block
  .modal-dialog
    display: flex
    justify-content: center
    padding-top: 54px
    padding-bottom: 54px
    @media (max-width: 1700px)
      padding-top: 20px
      padding-bottom: 5px
    @media (max-width: 1199px)
      padding: 40px 12px 20px
    .modal-content
      position: relative
      width: 100%
      max-width: 1200px
      .close-modal
        position: absolute
        top: 0
        right: -60px
        @media (max-width: 1400px)
          right: 0
          top: -40px
        @media (max-width: 1199px)
          top: -35px
        img
          width: 47px
          height: 47px
          filter: invert(1)
          @media (max-width: 1400px)
            width: 32px
            height: 32px
      .modal-content_inner
        padding: 32px
        border-radius: 10px
        background: linear-gradient(180deg, #454545 0%, #202020 100%)
        backdrop-filter: blur(12px)
        @media (max-width: 1700px)
          padding: 10px
        @media (max-width: 1199px)
          padding: 16px 12px
      .modal-title
        margin-top: 20px
        margin-bottom: 20px
        color: $white
        text-align: center
        font-size: 32px
        font-weight: 800
.float-flavors_wrapper
  display: flex
  justify-content: center
  max-width: 332px
  margin-left: auto
  margin-right: auto
  .float-flavors
    display: flex
    justify-content: center
    margin-left: -8px
    margin-right: -8px
    flex-wrap: wrap
    .label-flavors
      max-width: 50%
      flex: 0 0 50%
      padding-left: 8px
      padding-right: 8px
      margin-bottom: 16px
      .label-flavors_inner
        padding: 7px 13px
        border-radius: 8px
        background: #232323
        box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08)
      .flavours-title
        margin-bottom: 10px
        color: $milk
        font-size: 16px
        font-weight: 700
        text-transform: uppercase
      .label-flavor_item
        display: flex
        align-items: center
        margin-bottom: 5px
        .icon
          margin-left: 8px
          max-width: 18px
          flex: 0 0 18px
          display: block
        .title
          display: block
          color: $white
          font-size: 12px
          font-weight: 600
          line-height: 26px
.product-popup
  &.roast-me-popup
    .blend-wrapper
      .flavors
        @media (max-width: 1700px)
          margin-bottom: 0
        .flavors-wrapper
          .flavors-chart
            @media (max-width: 1700px)
              max-width: 280px
              flex: 0 0 280px
            @media (max-width: 480px)
              flex: auto
              flex-grow: 1
    .product-info_top
      margin-bottom: 15px
      @media (max-width: 1700px)
        margin-bottom: 10px
      .product-info_left
        .product-image
          max-width: 162px
          flex: 0 0 162px
          @media (max-width: 1700px)
            max-width: 140px
            flex: 0 0 140px
          @media (max-width: 767px)
            max-width: 130px
            flex: 0 0 130px
        .product-info
          padding-left: 10px
          padding-right: 10px
          max-width: 600px
          .title
            width: 100%
            margin-bottom: 10px
            background: none
            padding-top: 0
            padding-bottom: 0
            border: none
            border-bottom: 2px solid $white
            &:disabled
              border-bottom: 2px solid transparent
          .price
            margin-bottom: 0
      .product-info_wrapper
        position: relative
        display: flex
        align-items: flex-start
        margin-bottom: 14px
        padding-right: 30px
        @media (max-width: 1700px)
          margin-bottom: 10px
        .edit-icon
          position: absolute
          right: 0
          top: 6px
          display: flex
          flex-direction: column
          align-items: center
          .label
            margin-top: 2px
            display: block
            color: $white
            text-align: center
            font-size: 12px
            line-height: 20px
            font-weight: 400
            text-transform: capitalize
        .coffee-type_icon
          max-width: 74px
          flex: 0 0 74px
          padding: 8px 5px
          border-radius: 12px
          background: $orange
          @media (max-width: 767px)
            display: none
          .icon
            max-width: 42px
            margin-left: auto
            margin-right: auto
            margin-bottom: 7px
          .name
            color: $white
            text-align: center
            font-size: 10px
            font-weight: 700
            text-transform: uppercase
        .product-info_desc
          padding-left: 20px
          flex-grow: 1
          @media (max-width: 767px)
            padding-left: 0
      .props
        padding-bottom: 0
        margin-bottom: 0
        border-bottom: none
    .product-info_bottom
      padding: 30px 55px
      @media (max-width: 1700px)
        padding: 20px 40px
      @media (max-width: 1199px)
        padding-left: 25px
        padding-right: 25px
        padding-bottom: 0
      .product-bottom_wrapper
        display: flex
        margin-left: -30px
        margin-right: -30px
        @media (max-width: 1199px)
          padding-top: 0
          border-top: none
          margin-left: -10px
          margin-right: -10px
        .range-slider
          max-width: 330px
          @media (max-width: 1199px)
            max-width: 300px
          @media (max-width: 767px)
            max-width: 100%
        .roasting-level
          padding-bottom: 0
          @media (max-width: 767px)
            margin-bottom: 20px
          .roasting-level_label
            left: 338px
            bottom: auto
            top: 4px
            transform: none
            padding: 0
            border-radius: 0
            background: none
            color: #E6D0B1
            font-size: 11px
            @media (max-width: 1199px)
              left: 308px
            @media (max-width: 767px)
              left: 0
              top: auto
              //bottom: 5px
              bottom: 0
              //padding: 7px 12px
              padding: 3px 10px
              //border-radius: 12px
              font-size: 10px
              border-radius: 10px
              background: rgba(162, 97, 40, 0.45)
              color: $white
        .product-bottom_item
          max-width: 50%
          flex: 0 0 50%
          padding-left: 30px
          padding-right: 30px
          @media (max-width: 1199px)
            padding-right: 10px
            padding-left: 10px
          @media (max-width: 991px)
            max-width: 100%
            flex: 0 0 100%
          .product-item_title
            position: relative
            margin-bottom: 10px
            span
              position: relative
              padding-right: 20px
              min-width: 160px
              display: inline-block
              color: $white
              font-size: 16px
              line-height: 21px
              font-weight: 700
              text-transform: uppercase
              background-color: #1F1F1F
              @media (max-width: 767px)
                font-size: 14px
                min-width: 10px
            &:before
              position: absolute
              left: 0
              top: 10px
              display: block
              content: ''
              width: 100%
              height: 1px
              background-color: #818181
          .roasting-level_wrapper
            position: relative
            min-height: 80px
            @media (max-width: 1700px)
              min-height: 60px
            @media (max-width: 1199px)
              min-height: 70px
          .notes-wrapper
            max-width: 420px
            @media (max-width: 991px)
              max-width: 100%
              flex-direction: column
              align-items: center
      .coffee-items
        display: flex
        align-items: flex-start
        min-height: 80px
        flex-wrap: wrap
        @media (max-width: 1700px)
          min-height: 60px
        @media (max-width: 1199px)
          min-height: 10px
        @media (max-width: 767px)
          margin-bottom: 24px
        .coffee-item
          margin-right: 16px
          margin-bottom: 16px
          @media (max-width: 1700px)
            margin-right: 10px
            margin-bottom: 10px
          display: flex
          align-items: center
          color: $milk
          font-size: 18px
          font-weight: 700
          text-transform: capitalize
          @media (max-width: 767px)
            font-size: 12px
          .value
            margin-left: 5px
            color: $white
            font-weight: 400
      .blend-wrapper
        position: relative
        display: block
        min-height: 10px
        padding-top: 0
        .flavors
          .flavors-wrapper
            .flavors-values
              @media (max-width: 767px)
                max-width: 85px
                flex: 0 0 85px
            .flavor-value_item
              @media (max-width: 767px)
                font-size: 11px
              .value
                @media (max-width: 767px)
                  font-size: 12px
  .product-info_top
    display: flex
    align-items: flex-start
    margin-bottom: 10px
    justify-content: space-between
    @media (max-width: 1199px)
      margin-bottom: 24px
    @media (max-width: 991px)
      display: block
    .product-info_left
      flex-grow: 1
      max-width: 772px
      padding-right: 32px
      display: flex
      @media (max-width: 991px)
        padding-right: 0
        margin-bottom: 24px
      .product-image
        max-width: 245px
        flex: 0 0 245px
        @media (max-width: 1700px)
          max-width: 180px
          flex: 0 0 180px
        @media (max-width: 767px)
          max-width: 130px
          flex: 0 0 130px
      .product-info
        flex-grow: 1
        padding-left: 32px
        max-width: 495px
        @media (max-width: 767px)
          padding-left: 16px
        .title
          margin-bottom: 16px
          color: $white
          font-size: 26px
          font-weight: 800
          text-transform: uppercase
          @media (max-width: 1700px)
            margin-bottom: 5px
          @media (max-width: 767px)
            font-size: 16px
        .price
          margin-bottom: 16px
          color: $white
          font-size: 12px
          line-height: 22px
          @media (max-width: 1700px)
            margin-bottom: 10px
          @media (max-width: 767px)
            font-size: 11px
          strong
            margin-right: 5px
            font-size: 20px
            @media (max-width: 767px)
              font-size: 14px
        .about
          p
            color: $white
            font-size: 14px
            line-height: 19px
            @media (max-width: 767px)
              font-size: 12px
    .product-info_right
      max-width: 312px
      flex: 0 0 312px
      @media (max-width: 767px)
        max-width: 100%
      .share-block
        margin-top: 10px
        border-radius: 12px
        background: #1F1F1F
        padding: 12px 20px
        //display: flex
        display: none
        justify-content: flex-end
        align-items: center
        @media (max-width: 767px)
          justify-content: space-between
        .share-title
          margin-right: 38px
          color: $white
          font-size: 18px
          font-weight: 400
          white-space: nowrap
        .share-items
          display: flex
          align-items: center
          .share-item
            margin-right: 15px
            @media (max-width: 1700px)
              margin-left: 10px
              max-width: 36px
            &:last-child
              margin-right: 0
            .share-item_link
              display: block
      .buy-block
        padding: 32px
        border-radius: 12px
        background: #141414
        @media (max-width: 1700px)
          padding: 20px
          border-radius: 10px
        .buy-info
          display: flex
          align-items: center
          margin-bottom: 16px
          .quantity
            max-width: 82px
            flex: 0 0 82px
          .delivery
            flex-grow: 1
            padding-left: 12px
            .stock
              margin-bottom: 8px
              color: #FFE175
              font-size: 10px
              font-weight: 700
              text-transform: uppercase
            .delivery-info
              color: $white
              font-size: 14px
              text-transform: uppercase
              display: none
        .buttons
          .thm-btn
            width: 100%
    .props
      padding-top: 16px
      padding-bottom: 8px
      margin-bottom: 16px
      border-top: 1px solid rgba(216, 216, 216, 0.22)
      border-bottom: 1px solid rgba(216, 216, 216, 0.22)
      @media (max-width: 1700px)
        padding-top: 6px
        padding-bottom: 4px
        margin-bottom: 10px
      .props-item
        margin-bottom: 16px
        @media (max-width: 1700px)
          margin-bottom: 3px
        &:last-child
          margin-bottom: 0
        .props-title
          margin-bottom: 16px
          color: rgba(255, 255, 255, 0.80)
          font-size: 14px
          text-transform: uppercase
          @media (max-width: 1700px)
            margin-bottom: 6px
          @media (max-width: 767px)
            font-size: 12px
  .product-info_bottom
    padding: 24px
    border-radius: 12px
    background: #1F1F1F
    @media (max-width: 1700px)
      padding: 16px
    @media (max-width: 1199px)
      padding-bottom: 8px
    &.open
      .product-bottom_title
        .icon
          img
            transform: rotate(180deg)
      .product-bottom_wrapper
        display: flex
    .product-bottom_title
      color: $white
      font-size: 18px
      font-weight: 700
      text-transform: uppercase
      padding-bottom: 16px
      margin-bottom: 18px
      border-bottom: 1px solid rgba(255, 255, 255, 0.25)
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      @media (max-width: 1700px)
        padding-bottom: 10px
        margin-bottom: 10px
      @media (max-width: 1199px)
        border-bottom: none
        margin-bottom: 0
      .icon
        display: none
        width: 28px
        height: 28px
        border-radius: 50%
        background: #404040
        @media (max-width: 1199px)
          display: flex
          align-items: center
          justify-content: center
        img
          align-self: center
          transition: all .2s ease-out
    .product-bottom_wrapper
      display: flex
      justify-content: space-between
      @media (max-width: 1199px)
        display: none
        padding-top: 18px
        border-top: 1px solid rgba(255, 255, 255, 0.15)
      @media (max-width: 991px)
        flex-wrap: wrap
      .product-props
        max-width: 240px
        @media (max-width: 767px)
          max-width: 100%
          width: 100%
        .product-props_item
          padding-bottom: 8px
          margin-bottom: 8px
          border-bottom: 1px solid rgba(255, 255, 255, 0.15)
          color: $white
          font-family: $alt-font
          font-size: 14px
          line-height: 2
          padding-right: 6px
          @media (max-width: 1700px)
            padding-bottom: 6px
            margin-bottom: 6px
          &:last-child
            margin-bottom: 0
            padding-bottom: 0
            border-bottom: none
      .product-tastes_wrapper
        padding-top: 22px
        @media (max-width: 767px)
          padding-top: 57px
          width: 100%
      .product-tastes
        display: flex
        align-items: flex-start
        margin-bottom: 10px
        min-width: 330px
        justify-content: space-between
        @media (max-width: 767px)
          justify-content: space-between
          min-width: 100%
        .flavours
          padding-right: 24px
          flex-grow: 1
          .label-flavor_item
            .title
              text-transform: uppercase
              opacity: .6
        .flavors-values
          .flavor-value_item
            font-size: 16px
            text-transform: uppercase
            @media (max-width: 1700px)
              font-size: 14px
      .product-video
        max-width: 420px
        padding-top: 22px
        @media (max-width: 1199px)
          max-width: 300px
        @media (max-width: 991px)
          padding-top: 35px
        @media (max-width: 480px)
          max-width: 100%
      .roasting-level
        position: relative
        padding-bottom: 50px
        @media (max-width: 1700px)
          padding-bottom: 40px
        .roasting-level_label
          position: absolute
          left: 50%
          bottom: 0
          transform: translateX(-50%)
          padding: 8px 10px
          border-radius: 12px
          background: rgba(162, 97, 40, 0.45)
          color: $white
          font-size: 14px
          font-weight: 500
          line-height: 21px
          @media (max-width: 1700px)
            padding-top: 4px
            padding-bottom: 4px
            font-size: 13px
          span
            font-size: 10px
      .roasting-level_title
        margin-bottom: 20px
        color: $white
        font-size: 16px
        font-weight: 700
        text-transform: uppercase
        @media (max-width: 1700px)
          margin-bottom: 10px
      .range-slider
        position: relative
        height: 32px
        border-radius: 8px
        background: rgba(255, 255, 255, 0.19)
        box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.10)
        //&.level-1
          .range-slider__range
            width: 1% !important
        //&.level-2
          .range-slider__range
            width: 49% !important
        //&.level-3
          .range-slider__range
            width: 98% !important
        .range-slider__range
          height: auto
          top: 5px
          bottom: 5px
          transform: none
          border-radius: 6px 0 0 6px
          background: linear-gradient(90deg, #720D0F 0%, #A36129 91.35%)
        .range-slider__thumb
          width: 12px
          height: 42px
          border-radius: 100px
          background: $dark-bg
          box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.10)
          &[data-disabled]
            display: none
  .flavors-values
    @media (max-width: 1700px)
      padding: 10px
  .flavours
    .label-flavor_item
      @media (max-width: 1700px)
        margin-bottom: 10px
.props-choices
  display: flex
  flex-wrap: wrap
  margin-left: -4px
  margin-right: -4px
  .props-choice
    padding-left: 4px
    padding-right: 4px
    margin-bottom: 8px
    .props-choice_label
      display: block
      input
        display: none
        &:checked + .name
          padding-left: 7px
          padding-right: 7px
          background: $orange
          border-color: $orange
          color: $white
          font-weight: 700
      .name
        display: block
        padding: 5px 8px
        border-radius: 5px
        border: 1px solid rgba(255, 255, 255, 0.50)
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.10)
        color: rgba(255, 255, 255, 0.50)
        text-align: center
        font-size: 12px
        transition: all .2s ease-out
        @media (max-width: 767px)
          font-size: 11px
.custom-dropdown
  position: relative
  padding-top: 9px
  width: 100%
  .custom-dropdown_title
    position: absolute
    z-index: 2
    top: 0
    left: 50%
    padding-left: 2px
    padding-right: 2px
    transform: translateX(-50%)
    color: $white
    font-size: 12px
    background: #141414
    min-width: 40px
    text-align: center
  .custom-dropdown_wrapper
    position: relative
    &.open
      .current-item
        &:before
          transform: rotate(-45deg)
        &:after
          transform: rotate(45deg)
      .dropdown-list
        opacity: 1
        visibility: visible
        transform: translateY(0)
    .current-item
      text-align: left
      display: block
      width: 100%
      position: relative
      border-radius: 10px
      border: 1px solid #939393
      padding: 17px 20px
      color: $white
      font-size: 16px
      font-weight: 700
      background-color: #141414
      &:before,
      &:after
        position: absolute
        right: 17px
        top: 27px
        display: block
        content: ''
        width: 10px
        height: 1px
        border-radius: 2px
        background-color: #8E8E8E
        transform: rotate(-45deg)
        transition: all .2s ease-out
      &:before
        right: 24px
        transform: rotate(45deg)
    .dropdown-list
      position: absolute
      left: 8px
      right: 8px
      top: 100%
      overflow: hidden
      opacity: 0
      visibility: hidden
      transform: translateY(20px)
      border-radius: 0 0 10px 10px
      background: $white
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.50)
      transition: all .2s ease-out
      z-index: 2
      .dropdown-item_btn
        display: block
        width: 100%
        padding: 4px 12px
        text-align: left
        color: $black
        font-size: 14px
        transition: all .2s ease-out
        @media (max-width: 1700px)
          font-size: 13px
        &.exclude-btn
          padding: 0
          input
            display: none
            &:checked + .name
              color: $white
              background: $orange
          .name
            display: block
            padding: 4px 12px
        &:disabled
          pointer-events: none
          color: #858585
        &.selected,
        &.active,
        &:hover
          color: $white
          background: $orange



