.step-item
  &.assistant-open
    .blend-assistant
      .assistant-quote
        transform: scale(1) translateY(-100%)
  &.assistant-muted
    .blend-assistant
      .assistant-quote
        .mute-assistant
          &:before
            opacity: .7
          img
            opacity: .7
          .name
            color: #00954D
  &.assistant-has-suggestions
    .blend-assistant
      .assistant-photo
        .image
          &:after
            opacity: 1
.blend-assistant
  position: fixed
  left: 7%
  bottom: 27px
  z-index: 6
  &.b-80
    @media (max-width: 480px)
      bottom: 80px
  @media (max-width: 1700px)
    left: 27px
  @media (max-width: 1199px)
    bottom: 27px
  .assistant-photo
    .image
      position: relative
      display: block
      max-width: 90px
      height: 90px
      overflow: hidden
      border-radius: 50%
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.50)
      &:after
        position: absolute
        left: 0
        top: 0
        display: block
        content: ''
        width: 100%
        height: 100%
        border-radius: 50%
        opacity: 0
        border: 5px solid #00954D
        transition: all .2s ease-out
        box-sizing: border-box
      img
        width: 100%
    .name
      display: block
      color: $white
      font-size: 14px
      font-weight: 700
      text-align: center
      text-transform: uppercase
  .assistant-quote
    width: 334px
    position: absolute
    left: 0
    top: -4px
    transform: scale(0) translateY(-100%)
    transform-origin: 0 0
    padding-bottom: 17px
    transition: all .2s ease-out
    //&.open
      transform: scale(1) translateY(-100%)
    .icon
      position: absolute
      bottom: 1px
      left: 30px
    .quote-text
      position: relative
      padding: 10px 22px 22px 30px
      border-radius: 20px
      background: $white
      p
        color: rgba(11, 11, 11, 0.70)
        font-size: 16px
        font-style: normal
        font-weight: 500
        line-height: 25px
        margin-bottom: 20px
        span
          strong
            display: inline
            margin-bottom: 0
            font-size: 16px
            //color: $green
            //&:first-child
              color: $orange
            //&:last-child
              color: $green
        strong
          display: block
          margin-bottom: 8px
          font-size: 22px
          &.green
            display: inline
            margin-bottom: 0
            font-size: 16px
            color: $green
          &.brown
            display: inline
            margin-bottom: 0
            font-size: 16px
            color: $orange
      .buttons
        justify-content: flex-start
        &.hidden
          display: none !important
        .thm-btn
          margin-right: 0
          margin-left: 16px
          min-width: 10px
          font-size: 12px
          font-weight: 400
          padding-left: 18px
          padding-right: 18px
          &.simple
            padding-left: 0
            padding-right: 0
      .close-quote
        position: absolute
        top: 10px
        right: 13px
        z-index: 1
    .mute-assistant
      position: absolute
      left: 12px
      bottom: 25px
      width: 32px
      display: flex
      flex-direction: column
      align-items: center
      &:before
        position: absolute
        left: 0
        top: 10px
        display: block
        content: ''
        width: 31px
        height: 3px
        transform: rotate(45deg)
        background-color: #3F3F3F
        opacity: 0
        transition: all .2s ease-out
      img
        transition: all .2s ease-out
      .name
        color: #720D0F
        font-size: 12px
        line-height: 1
        text-transform: uppercase
        transition: all .2s ease-out



